import React from 'react'
import UlEl from '../../elements/ul/UlEl'
// import desc from './description.module.scss'



function Description(props) {
   return (
      <>
         <div style={{ width: `100%`, maxWidth: `1170px`, margin: `auto` }}>
            <div className='p2'>
               <h2>
                  Аренда оборудования, инструмента и техники
               </h2>
               <p>
                  Компания «КрайПрокат-93» предлагает частным лицам и организациям, находящимся в Анапе, взять в аренду строительное оборудование, инструмент и оснастку по доступным ценам. Закажите аренду с доставкой от 300 рублей по городу Анапа!
               </p>
               <p>
                  В каталоге представлено оборудования для выполнения широкого спектра мелких и масштабных работ: отбойные молотки и бетоноломы, виброплиты и вибротрамбовки, сварочное оборудование, подъемное, окрасочное, режущий инструмент и многое другое.
               </p>
               <p>
                  Взять в аренду строительный инструмент можно на разный срок: от трёх часов до нескольких месяцев.
               </p>
            </div>


            <div className='p2'>
               <h3>
                  Плюсы аренды строительного оборудования
               </h3>
               <p>
                  Обратиться за профессиональной техникой в компанию «КрайПрокат-93» - оптимальный и бюджетный выход в тех ситуациях, когда инструмент требуется не на постоянной основе. Вам не нужно тратить средства на его приобретение, время на обслуживание и поиски места для хранения.
               </p>
               <p>
                  Аренда строительного оборудования - прекрасный способ сэкономить ресурсы и избежать лишних инвестиций.
               </p>
            </div>
            <div className="content__about_contS p05">
               <h3>
                  Наши преимущества
               </h3>
               <UlEl>
                  <li>
                     Для постоянных клиентов — выгодные индивидуальные предложения!
                  </li>
                  <li>
                     Гарантии. Большая часть техники и оборудования новая, в отличном техническом состоянии. Произведена брендами, которым доверяют профессионалы: Stihl, Husqvarna, Bosch, Makita и т.д.
                  </li>
                  <li>
                     Техобслуживание. При возникновении нештатных ситуаций простой в работе исключен. Наши мастера помогут устранить неполадки.
                  </li>
                  <li>
                     Широчайший ассортимент. К Вашим услугам более 300 видов различных инструментов. В каталоге оборудования каждая единица снабжена описанием с указанием цен.
                  </li>
               </UlEl>
            </div>
         </div>
      </>
   )

}

export default Description