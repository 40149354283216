
import up from "../../../assets/images/svg/up.svg";


function ScrollTop(props) {
    return (
        <a href="#" >
            <div id="toTop">
                <div className={`borderCont`} role='textbox' aria-multiline='true'>
                    <img style={{ width: `100%` }} src={up} alt='наверх' />
                    <p>
                    </p>
                </div>
            </div>

        </a>
    )

}
export default ScrollTop