
import JackhammersPage from './GrindingConcretePage'
import { connect } from "react-redux"
import { grindingconAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        grindingconItem: state.subItemsPage.grindingconItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        grindingconHot(grindingconItem) {
            dispatch(grindingconAC(grindingconItem))
        }
    }
}

export const GCPContainer = connect(mapStateToProps, mapDispatchToProps)(JackhammersPage);
export default GCPContainer;