import React from 'react'
import CContainer from './contact/CContainer'
import { seo } from '../../hook/seo'
import c from './contact.module.scss'
import fon from '../../assets/images/jpegPng/contact.jpeg'
import BreadСrumbsHome from '../elements/breadСrumbsHome/BreadСrumbsHome'

class Contacts extends React.Component {

   componentDidMount() {
      seo({
         title: 'КрайПрокат-93 | Контактная информация | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе',
         metaDescription: 'КрайПрокат-93 | Контактная информация | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе'
      });
      if (!this.props.sStyle) {
         return <div>Загрузка стилей...</div>
      }
   }

   render() {

      return (
         <div>
            <div className={`${c.imgContPrew}`} style={{ backgroundImage: `url(${fon})` }}>
               <div style={{ maxWidth: `1400px`, width: `100%`, margin: `0 auto` }}>
                  <h1 className='p2 tACenter' >
                     Контактная информация
                  </h1>
               </div>
            </div>
            <div className="main-cont">
               <div className={`pt1`}>
                  <BreadСrumbsHome>
                     Контакты
                  </BreadСrumbsHome>
               </div>
               <h2>Контакты</h2>
               <CContainer />
            </div>
            {/* <HowTGet
               sStyle={this.props.sStyle}
            />
            <HotelServices
               sStyle={this.props.sStyle}
            /> */}
         </div>
      )
   }
}

export default Contacts