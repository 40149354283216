import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getStairstour } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class StairstoursFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getStairstour(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sStairstour={this.props.sStairstour}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sStairstour: state.subItemsPage.sStairstour
})

let WithUrlDataContainerComponent = withRouter(StairstoursFPContainer)

export default connect(mapStateToProps, { getStairstour })(WithUrlDataContainerComponent)
