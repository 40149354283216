import React from 'react'
import { connect } from 'react-redux'
import Full from './fullPage/Full'
import { getVibroLegs } from '../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class VibLegContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getVibroLegs(id)

   }

   render() {
      debugger
      return (
         <Full 
         {...this.props} 
         sVibroLegs={this.props.sVibroLegs}
          />
      )
   }
}
let mapStateToProps = (state) => ({
   sVibroLegs: state.subItemsPage.sVibroLegs
})

let WithUrlDataContainerComponent = withRouter(VibLegContainer)

export default connect(mapStateToProps, { getVibroLegs })(WithUrlDataContainerComponent)
