
import SeamCuttersPage from './SeamCuttersPage'
import { connect } from "react-redux"
import { seamcuttersAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        seamcuttersItem: state.subItemsPage.seamcuttersItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        seamcuttersHot(seamcuttersItem) {
            dispatch(seamcuttersAC(seamcuttersItem))
        }
    }
}

export const SMPContainer = connect(mapStateToProps, mapDispatchToProps)(SeamCuttersPage);
export default SMPContainer;