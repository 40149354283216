let initionState = {
    contactItems: [{
            id: 1,
            contact: {
                path: [{
                        id: "1",
                        d: "m213.454,91.714c-2.775,0-5.018,2.248-5.018,5.018v74.897l-50.564,42.906v-72.052c0-2.77-2.243-5.018-5.018-5.018s-5.018,2.248-5.018,5.018v77.878h-65.268v-158.692c0-0.07-0.04-0.125-0.04-0.196-0.01-0.361-0.115-0.698-0.206-1.049-0.08-0.291-0.115-0.587-0.246-0.853-0.115-0.261-0.311-0.482-0.492-0.723-0.206-0.301-0.401-0.602-0.667-0.848-0.05-0.045-0.07-0.11-0.125-0.156l-60.6-51.422c-1.49-1.265-3.588-1.546-5.349-0.728-1.776,0.823-2.911,2.599-2.911,4.557v163.705c0,1.475 0.647,2.87 1.766,3.829l60.61,51.422c0.236,0.206 0.522,0.306 0.783,0.457 0.216,0.125 0.401,0.276 0.637,0.366 0.587,0.231 1.204,0.371 1.822,0.371h75.304c0.627,0 1.245-0.135 1.832-0.371 0.226-0.09 0.411-0.241 0.627-0.361 0.266-0.156 0.547-0.261 0.793-0.462l60.6-51.427c1.119-0.953 1.766-2.354 1.766-3.829v-77.219c2.84217e-14-2.775-2.243-5.018-5.018-5.018zm-191.487-70.627l50.564,42.906v150.542l-50.564-42.906v-150.542z"
                    },
                    {
                        id: "2",
                        d: "m154.685,70.13c11.602,0 21.052-9.439 21.052-21.047 0-11.602-9.449-21.042-21.052-21.042-11.602,0-21.042,9.439-21.042,21.042 0,11.608 9.44,21.047 21.042,21.047zm0-32.052c6.077,0 11.015,4.938 11.015,11.005 0,6.072-4.938,11.01-11.015,11.01-6.067,0-11.005-4.938-11.005-11.01 0-6.067 4.938-11.005 11.005-11.005z"
                    },
                    {
                        id: "3",
                        d: "m151.137,114.968c0.979,0.979 2.263,1.47 3.548,1.47 1.285,0 2.569-0.492 3.548-1.47l31.168-31.158c19.14-19.145 19.14-50.293 0-69.427-9.273-9.274-21.603-14.383-34.716-14.383-13.113,0-25.443,5.109-34.706,14.377-9.284,9.274-14.387,21.599-14.387,34.711s5.109,25.443 14.387,34.716l31.158,31.164zm-24.062-93.5c7.372-7.377 17.172-11.437 27.61-11.437 10.428,0 20.239,4.065 27.62,11.442 15.23,15.225 15.23,40.005 0,55.236l-27.62,27.61-27.61-27.61c-7.392-7.382-11.447-17.193-11.447-27.62s4.055-20.239 11.447-27.621z"
                    }
                ],
                width: '32',
                height: '32',
                x: '0',
                y: '0',
                viewBox: '0 0 230.403 230.403',
                link: 'https://yandex.ru/maps/1107/anapa/?from=mapframe&ll=37.365265%2C44.899618&mode=usermaps&source=mapframe&um=constructor%3ACj3iZKziW3YjmEAJopweK_IVKvEzID5I&utm_source=mapframe&z=17',
                text: 'г. Анапа, ул. Анапское ш., д.99а, корп. 1',
                about: 'Адрес',
            },
        },
        {
            id: 2,
            contact: {
                path: [{
                    id: "1",
                    d: "M256,0C114.844,0,0,114.844,0,256s114.844,256,256,256c23.188,0,46.156-3.094,70.469-10   c49.25-21.781,76.125-75.531,64.219-127.969l0.5-0.5c7.5-7.469,10.875-21.875,7.531-31.938l-1.438-4.375   c-3.375-10.062-14.5-20.562-24.75-23.375L334.688,303.5c-10.25-2.781-24.875,0.969-32.405,8.5l-13.688,13.688   c-49.75-13.469-88.781-52.5-102.219-102.25l13.688-13.688c7.5-7.5,11.25-22.125,8.469-32.406L198.219,139.5   c-2.781-10.25-13.344-21.375-23.406-24.75l-4.313-1.438c-10.094-3.375-24.5,0.031-32,7.563l-20.5,20.5   c-3.656,3.625-6,14.031-6,14.063c-0.688,65.063,24.813,127.719,70.813,173.75c45.875,45.875,108.313,71.345,173.156,70.781   c0.156,0,2.531-0.531,5.344-1.375c0.188,31.469-17.655,60.875-45.562,73.344C296.406,477.281,276.312,480,256,480   C132.5,480,32,379.5,32,256S132.5,32,256,32s224,100.5,224,224c0,36.812-8.75,72.031-26.031,104.625   c-4.125,7.812-1.125,17.5,6.656,21.625c7.844,4.188,17.5,1.156,21.625-6.625C501.719,338.875,512,297.5,512,256   C512,114.844,397.156,0,256,0z"
                }],
                width: '32',
                height: '32',
                x: '0',
                y: '0',
                viewBox: '0 0 500 500',
                link: 'tel:+79892429393',
                text: '+7 (989) 242-93-93',
                about: 'Звоните',
            }
        },
        {
            id: 3,
            contact: {
                path: [{
                        id: "1",
                        d: "M63.5,24.8c-0.4,0-0.8,0.3-0.8,0.8v8c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-8C64.3,25.1,63.9,24.8,63.5,24.8   z"
                    },
                    {
                        id: "2",
                        d: "M63.5,19.8c-0.4,0-0.8,0.3-0.8,0.8v1c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1C64.3,20.1,63.9,19.8,63.5,19.8   z"
                    },
                    {
                        id: "3",
                        d: "M1.5,48.8c-0.4,0-0.8,0.3-0.8,0.8v9c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-9C2.3,49.1,1.9,48.8,1.5,48.8z"
                    },
                    {
                        id: "4",
                        d: "M1.5,42.8c-0.4,0-0.8,0.3-0.8,0.8v2c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-2C2.3,43.1,1.9,42.8,1.5,42.8z"
                    },
                    {
                        id: "5",
                        d: "M44.5,4.3h1.3v1.3c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V4.3h1.3c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8   h-1.3V1.5c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v1.3h-1.3c-0.4,0-0.8,0.3-0.8,0.8S44.1,4.3,44.5,4.3z"
                    },
                    {
                        id: "6",
                        d: "M6.3,25.5v-1.3h1.3c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H6.3v-1.3c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8   v1.3H3.5c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h1.3v1.3c0,0.4,0.3,0.8,0.8,0.8S6.3,25.9,6.3,25.5z"
                    },
                    {
                        id: "7",
                        d: "M59.2,31.2c0-0.1-0.1-0.2-0.2-0.3l-7.8-7.8v-9.7c0-0.4-0.3-0.8-0.8-0.8h-9.7L33,5c-0.3-0.3-0.8-0.3-1.1,0   l-7.8,7.8h-9.7c-0.4,0-0.8,0.3-0.8,0.8v9.7L6,31l0,0l0,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.2c0,0,0,0.1,0,0.1v0   c0,0,0,0,0,0c0,0,0,0,0,0v31.9c0,0.4,0.3,0.8,0.8,0.8h10c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H7.3V33L32,52.1   c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2L57.8,33v29.7H22.5c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h36   c0.4,0,0.8-0.3,0.8-0.8v-32C59.3,31.4,59.2,31.3,59.2,31.2C59.2,31.3,59.2,31.2,59.2,31.2z M32.5,6.6l6.2,6.2H26.3L32.5,6.6z    M32.5,50.6L7.6,31.4l6.1-6.1v7.4c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8V14.2h34.5v18.4c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8   v-7.3l6.1,6.1L32.5,50.6z"
                    },
                    {
                        id: "8",
                        d: "M44.5,18.8h-24c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h24c0.4,0,0.8-0.3,0.8-0.8S44.9,18.8,44.5,18.8z"
                    },
                    {
                        id: "9",
                        d: "M44.5,22.8h-24c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h24c0.4,0,0.8-0.3,0.8-0.8S44.9,22.8,44.5,22.8z"
                    },
                    {
                        id: "10",
                        d: "M44.5,26.8h-24c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h24c0.4,0,0.8-0.3,0.8-0.8S44.9,26.8,44.5,26.8z"
                    },
                    {
                        id: "11",
                        d: "M41.5,30.8h-18c-0.4,0-0.8,0.3-0.8,0.8s0.3,0.8,0.8,0.8h18c0.4,0,0.8-0.3,0.8-0.8S41.9,30.8,41.5,30.8z"
                    },
                    {
                        id: "12",
                        d: "M20,49l-8,8c-0.3,0.3-0.3,0.8,0,1.1c0.1,0.1,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2l8-8c0.3-0.3,0.3-0.8,0-1.1   S20.3,48.7,20,49z"
                    },
                    {
                        id: "13",
                        d: "M52.5,58.2c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1l-8-8c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1   l8,8C52.1,58.2,52.3,58.2,52.5,58.2z"
                    },
                    {
                        id: "14",
                        d: "M52.5,58.2c0.2,0,0.4-0.1,0.5-0.2c0.3-0.3,0.3-0.8,0-1.1l-8-8c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1   l8,8C52.1,58.2,52.3,58.2,52.5,58.2z"
                    },
                ],
                width: '32',
                height: '32',
                x: '0',
                y: '0',
                viewBox: ' 0 0 65 65',
                link: 'mailto:info@krayprokat93.ru',
                text: 'info@krayprokat93.ru',
                about: 'Пишите',
            }
        },

    ],
    mapsItem: [{
        id: 1,
        mapLink: 'https://yandex.ru/map-widget/v1/-/CCUuBXahpC',
        mapTitle: 'Адрес Демо ОТЕЛЬ «Космос»',
    }]
}

const contactsReduscer = (state = initionState, action) => {
    return state;
}

export default contactsReduscer;