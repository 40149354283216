import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from 'redux-thunk'

import headerReduscer from "./headerReduser";
import categoriesReduser from "./categoriesReduser";
import subItemsReduser from "./subItemsReduser";

import roomsReduscer from "./roomsReduser";
import contactsReduscer from "./contactsReduser";
import servicesReducer from "./servicesReduser";
import aboutUsReducer from './aboutUsReducer'
import bookingReduscer from "./bookingReducer";
import homeReducer from "./homeReducer";
import styleReduser from "./styleReducer"
import footerReduser from './footerReduser'

import offerReduser from './offerReducer'
import bookingReduser from './bookingReducer'



let reducers = combineReducers({
    headerPage: headerReduscer,
    categoriesPage: categoriesReduser,
    subItemsPage: subItemsReduser,
    
    roomsPage: roomsReduscer,
    contactsPage: contactsReduscer,
    servicesPage: servicesReducer,
    aboutUsPage: aboutUsReducer,
    bookingPage: bookingReduscer,
    homePage: homeReducer,
    styleModule: styleReduser,
    footerModule: footerReduser,
    bookingModule: bookingReduser,
    documentModule: offerReduser,
    
})

let enhancers = [ 
    applyMiddleware(thunkMiddleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

]

let store = createStore(
    reducers,
    
    compose(...enhancers)    
    )
    
window.store = store
export default store;

