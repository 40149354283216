
import CompressorsPage from './CompressorsPage'
import { connect } from "react-redux"
import { compressorsAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        compressorsItem: state.subItemsPage.compressorsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        compressorsHot(compressorsItem) {
            dispatch(compressorsAC(compressorsItem))
        }
    }
}

export const CompressorsPContainer = connect(mapStateToProps, mapDispatchToProps)(CompressorsPage);
export default CompressorsPContainer;