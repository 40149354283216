
import ScaffoldingsPage from './ScaffoldingsPage'
import { connect } from "react-redux"
import { scaffoldingAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        scaffoldingItem: state.subItemsPage.scaffoldingItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        scaffoldingHot(scaffoldingItem) {
            dispatch(scaffoldingAC(scaffoldingItem))
        }
    }
}

export const ScaffoldingsPContainer = connect(mapStateToProps, mapDispatchToProps)(ScaffoldingsPage);
export default ScaffoldingsPContainer;