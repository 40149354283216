import {
    hotelApi
} from "../api/api"

//1 Вибротрамбовки (Виброноги)
const VIBRATINGPLATES_RED = 'VIBRATINGPLATES-RED'
const FULL_VIBRATINGPLATES = 'FULL-VIBRATINGPLATES'
const SET_VIBRATINGPLATES = 'SET-VIBRATINGPLATES'
//2 Вибротрамбовки (Виброплиты)
const VIBROLEGS_RED = 'VIBROLEGS-RED'
const FULL_VIBROLEGS = 'FULL-VIBROLEGS'
const SET_VIBROLEGS = 'SET-VIBROLEGS'
//3 Перфораторы
const PERFORATORS_RED = 'PERFORATORS-RED'
const FULL_PERFORATORS = 'FULL-PERFORATORS'
const SET_PERFORATORS = 'SET-PERFORATORS'
//4 Отбойные молотки
const JACKHAMMER_RED = 'JACKHAMMER-RED'
const FULL_JACKHAMMER = 'FULL-JACKHAMMER'
const SET_JACKHAMMER = 'SET-JACKHAMMER'
//5 Штроборезы ручные, Швонарезчики
const SEAMCUTTERS_RED = 'SEAMCUTTERS-RED'
const FULL_SEAMCUTTERS = 'FULL-SEAMCUTTERS'
const SET_SEAMCUTTERS = 'SET-SEAMCUTTERS'
//6 Шлифовальные машины по бетону.
const GRINDINGCONCRETE_RED = 'GRINDINGCONCRETE-RED'
const FULL_GRINDINGCONCRETE = 'FULL-GRINDINGCONCRETE'
const SET_GRINDINGCONCRETE = 'SET-GRINDINGCONCRETE'
//7 Генераторы
const GENERATORS_RED = 'GENERATORS-RED'
const FULL_GENERATORS = 'FULL-GENERATORS'
const SET_GENERATORS = 'SET-GENERATORS'
//8 Тепловое оборудование
const THERMAL_RED = 'THERMAL-RED'
const FULL_THERMAL = 'FULL-THERMAL'
const SET_THERMAL = 'SET-THERMAL'
//9 Компрессоры, краскопульты, пескоструй
const COMPRESSORS_RED = 'COMPRESSORS-RED'
const FULL_COMPRESSORS = 'FULL-COMPRESSORS'
const SET_COMPRESSORS = 'SET-COMPRESSORS'
//10 Садовое оборудование, Ямобуры
const YAMOBURY_RED = 'YAMOBURY-RED'
const FULL_YAMOBURY = 'FULL-YAMOBURY'
const SET_YAMOBURY = 'SET-YAMOBURY'
//11 Мотопомпы, Электро-насосы
const PUMPS_RED = 'PUMPS-RED'
const FULL_PUMPS = 'FULL-PUMPS'
const SET_PUMPS = 'SET-PUMPS'
//12 Бетономешалки
const CONCRETEMIXERS_RED = 'CONCRETEMIXERS-RED'
const FULL_CONCRETEMIXERS = 'FULL-CONCRETEMIXERS'
const SET_CONCRETEMIXERS = 'SET-CONCRETEMIXERS'
//13 Плиткорезы
const TILECUTTER_RED = 'TILECUTTER-RED'
const FULL_TILECUTTER = 'FULL-TILECUTTER'
const SET_TILECUTTER = 'SET-TILECUTTER'
//14 Вибраторы для бетона. Виброрейки
const VIBROSLATS_RED = 'VIBROSLATS-RED'
const FULL_VIBROSLATS = 'FULL-VIBROSLATS'
const SET_VIBROSLATS = 'SET-VIBROSLATS'
//15 Шуруповерты
const SCREWDRIVERS_RED = 'SCREWDRIVERS-RED'
const FULL_SCREWDRIVERS = 'FULL-SCREWDRIVERS'
const SET_SCREWDRIVERS = 'SET-SCREWDRIVERS'
//16 Рохли, Тельфер, Таль, Домкрат
const ROHLI_RED = 'ROHLI-RED'
const FULL_ROHLI = 'FULL-ROHLI'
const SET_ROHLI = 'SET-ROHLI'
//17 Оборудование для монтажа кондиционеров
const AIRCONDITIONING_RED = 'AIRCONDITIONING-RED'
const FULL_AIRCONDITIONING = 'FULL-AIRCONDITIONING'
const SET_AIRCONDITIONING = 'SET-AIRCONDITIONING'
//18 Сварочное оборудование
const WELDING_RED = 'WELDING-RED'
const FULL_WELDING = 'FULL-WELDING'
const SET_WELDING = 'SET-WELDING'
//19 Рубанки, фрейзеры
const FRASERS_RED = 'FRASERS-RED'
const FULL_FRASERS = 'FULL-FRASERS'
const SET_FRASERS = 'SET-FRASERS'
//20 Шлифовальные машины по дереву
const WOODSANDERS_RED = 'WOODSANDERS-RED'
const FULL_WOODSANDERS = 'FULL-WOODSANDERS'
const SET_WOODSANDERS = 'SET-WOODSANDERS'
//21 Металлорежущие инструменты
const METALCUTTING_RED = 'METALCUTTING-RED'
const FULL_METALCUTTING = 'FULL-METALCUTTING'
const SET_METALCUTTING = 'SET-METALCUTTING'
//22 Уровни, нивелиры, измерительные приборы
const MEASURING_RED = 'MEASURING-RED'
const FULL_MEASURING = 'FULL-MEASURING'
const SET_MEASURING = 'SET-MEASURING'
//23 Бензопилы. Лобзики. Торцевые пилы 
const CHAINSAWS_RED = 'CHAINSAWS-RED'
const FULL_CHAINSAWS = 'FULL-CHAINSAWS'
const SET_CHAINSAWS = 'SET-CHAINSAWS'
//24 Пылесосы
const VACUUMS_RED = 'VACUUMS-RED'
const FULL_VACUUMS = 'FULL-VACUUMS'
const SET_VACUUMS = 'SET-VACUUMS'
//25 Болгарки
const BULGARIAN_RED = 'BULGARIAN-RED'
const FULL_BULGARIAN = 'FULL-BULGARIAN'
const SET_BULGARIAN = 'SET-BULGARIAN'
//26 Моющее оборудование 
const WASHINGEQUIPMENT_RED = 'WASHINGEQUIPMENT-RED'
const FULL_WASHINGEQUIPMENT = 'FULL-WASHINGEQUIPMENT'
const SET_WASHINGEQUIPMENT = 'SET-WASHINGEQUIPMENT'
//27 Сантехническое оборудование
const PLUMBINGEQUIPMENT_RED = 'PLUMBINGEQUIPMENT-RED'
const FULL_PLUMBINGEQUIPMENT = 'FULL-PLUMBINGEQUIPMENT'
const SET_PLUMBINGEQUIPMENT = 'SET-PLUMBINGEQUIPMENT'
//28 Строительные леса
const SCAFFOLDING_RED = 'SCAFFOLDING-RED'
const FULL_SCAFFOLDING = 'FULL-SCAFFOLDING'
const SET_SCAFFOLDING = 'SET-SCAFFOLDING'
//29 Опалубочное оборудование
const FORMWORKEQUIPMENT_RED = 'FORMWORKEQUIPMENT-RED'
const FULL_FORMWORKEQUIPMENT = 'FULL-FORMWORKEQUIPMENT'
const SET_FORMWORKEQUIPMENT = 'SET-FORMWORKEQUIPMENT'
//30 Лестницы и вышки тура
const STAIRSTOUR_RED = 'STAIRSTOUR-RED'
const FULL_STAIRSTOUR = 'FULL-STAIRSTOUR'
const SET_STAIRSTOUR = 'SET-STAIRSTOUR'
//31 Электромонтажное оборудование
const WIRINGEQUIPMENT_RED = 'WIRINGEQUIPMENT-RED'
const FULL_WIRINGEQUIPMENT = 'FULL-WIRINGEQUIPMENT'
const SET_WIRINGEQUIPMENT = 'SET-WIRINGEQUIPMENT'
//32 Дрели
const DRILLS_RED = 'DRILLS-RED'
const FULL_DRILLS = 'FULL-DRILLS'
const SET_DRILLS = 'SET-DRILLS'
//33 Спортивное снаряжение
const SPORTEQUIPMENT_RED = 'SPORTEQUIPMENT-RED'
const FULL_SPORTEQUIPMENT = 'FULL-SPORTEQUIPMENT'
const SET_SPORTEQUIPMENT = 'SET-SPORTEQUIPMENT'
//34 Буры, коронки для перфораторов
const BOERS_RED = 'BOERS-RED'
const FULL_BOERS = 'FULL-BOERS'
const SET_BOERS = 'SET-BOERS'
//35 Разное
const MISCELLANEOUS_RED = 'MISCELLANEOUS-RED'
const FULL_MISCELLANEOUS = 'FULL-MISCELLANEOUS'
const SET_MISCELLANEOUS = 'SET-MISCELLANEOUS'
//36 Расходные Материалы
const CONSUMABLES_RED = 'CONSUMABLES-RED'
const FULL_CONSUMABLES = 'FULL-CONSUMABLES'
const SET_CONSUMABLES = 'SET-CONSUMABLES'






let initionState = {
    //1 виброплиты
    vibratingPlatesItem: [],
    sVibratingPlates: null,
    //2 виброноги
    vibroLegsItem: [],
    sVibroLegs: null,
    //3 перфораторы
    perforatorsItem: [],
    sPerforators: null,
    //4 отбойные молотки
    jackhammersItem: [],
    sJackhammers: null,
    //5 Штроборезы ручные, Швонарезчики
    seamcuttersItem: [],
    sSeamcutters: null,
    //6 Шлифовальные машины по бетону. (Вертолёты)GRINDINGCONCRETE
    grindingconItem: [],
    sGrindingcon: null,
    //7 Генераторы
    generatorsItem: [],
    sGenerators: null,
    //8 Тепловое оборудование
    thermalItem: [],
    sThermal: null,
    //9 Компрессоры, краскопульты, пескоструй
    compressorsItem: [],
    sCompressors: null,
    //10 Садовое оборудование, Ямобуры
    yamoburyItem: [],
    sYamobury: null,
    //11 Мотопомпы, Электро-насосы
    pumpsItem: [],
    sPamps: null,
    //12 Бетономешалки
    concretmixItem: [],
    sConcretmix: null,
    //13 Плиткорезы
    tilecutterItem: [],
    sTilecutter: null,
    //14 Вибраторы для бетона. Виброрейки
    vibroslatsItem: [],
    sVibroslats: null,
    //15 Шуруповерты
    screwdriversItem: [],
    sScrewdrivers: null,
    //16 Рохли, Тельфер, Таль, Домкрат
    rohliItem: [],
    sRohli: null,
    //17 Оборудование для монтажа кондиционеров
    airconditionItem: [],
    sAircondition: null,
    //18 Сварочное оборудование 
    weldingItem: [],
    sWelding: null,
    //19 Рубанки, фрейзеры
    frasersItem: [],
    sFrasers: null,
    //20 Шлифовальные машины по дереву
    woodsandersItem: [],
    sWoodsanders: null,
    //21 Металлорежущие инструменты
    metalcuttingItem: [],
    sMetalcutting: null,
    //22 Уровни, нивелиры, измерительные приборы 
    measuringItem: [],
    sMeasuring: null,
    //23 Бензопилы. Лобзики. Торцевые пилы 
    chainsawsItem: [],
    sChainsaws: null,
    //24 Пылесосы
    vacuumsItem: [],
    sVacuums: null,
    //25 Болгарки 
    bulgarianItem: [],
    sBulgarian: null,
    //26 Моющее оборудование 
    washingequipmentItem: [],
    sWashingequipment: null,
    //27 Сантехническое оборудование
    plumbingequipmentItem: [],
    sPlumbingequipment: null,
    //28 Строительные леса 
    scaffoldingItem: [],
    sScaffolding: null,
    //29 Опалубочное оборудованиеFormwork
    formworkequipmentItem: [],
    sFormworkequipment: null,
    //30 Лестницы и вышки тура Stairstour
    stairstourItem: [],
    sStairstour: null,
    //31 Электромонтажное оборудование
    wiringequipmentItem: [],
    sWiringequipment: null,
    //32 Дрели 
    drillsItem: [],
    sDrills: null,
    //33 Спортивное снаряжение
    sportequipmentItem: [],
    sSportequipment: null,
    //34 Буры, коронки для перфораторов
    boersItem: [],
    sBoers: null,
    //35 Разное
    miscellaneousItem: [],
    sMiscellaneous: null,
    //36 Расходные Материалы
    consumablesItem: [],
    sConsumables: null,
}

const subItemsDorojReduser = (state = initionState, action) => {
    
    
    switch (action.type) {
        //1 виброплиты
        
        case VIBRATINGPLATES_RED: {
            
            return {
                ...state,
                vibratingPlatesItem: [...action.vibratingPlatesItem]
            }
        }
        case FULL_VIBRATINGPLATES: {
            return {
                ...state,
                vibratingPlatesItem: [...action.vibratingPlatesItem]
            }
        }

        case SET_VIBRATINGPLATES: {
            return {
                ...state,
                sVibratingPlates: action.sVibratingPlates
            }
        }
        //2 виброноги
        
        case VIBROLEGS_RED: {
            return {
                ...state,
                vibroLegsItem: [...action.vibroLegsItem]
            }
        }
        case FULL_VIBROLEGS: {
            return {
                ...state,
                vibroLegsItem: [...action.vibroLegsItem]
            }
        }

        case SET_VIBROLEGS: {
            return {
                ...state,
                sVibroLegs: action.sVibroLegs
            }
        }
        
        //3 перфораторы
        case PERFORATORS_RED: {
            return {
                ...state,
                perforatorsItem: [...action.perforatorsItem]
            }
        }
        case FULL_PERFORATORS: {
            return {
                ...state,
                perforatorsItem: [...action.perforatorsItem]
            }
        }

        case SET_PERFORATORS: {
            return {
                ...state,
                sPerforators: action.sPerforators
            }
        }
        //4 отбойные молотки
        case JACKHAMMER_RED: {
            return {
                ...state,
                jackhammersItem: [...action.jackhammersItem]
            }
        }
        case FULL_JACKHAMMER: {
            return {
                ...state,
                jackhammersItem: [...action.jackhammersItem]
            }
        }

        case SET_JACKHAMMER: {
            return {
                ...state,
                sJackhammers: action.sJackhammers
            }
        }
        //5 Штроборезы ручные, Швонарезчики 
        case SEAMCUTTERS_RED: {
            return {
                ...state,
                seamcuttersItem: [...action.seamcuttersItem]
            }
        }
        case FULL_SEAMCUTTERS: {
            return {
                ...state,
                seamcuttersItem: [...action.seamcuttersItem]
            }
        }

        case SET_SEAMCUTTERS: {
            return {
                ...state,
                sSeamcutters: action.sSeamcutters
            }
        }
        //6 Шлифовальные машины по бетону
        case GRINDINGCONCRETE_RED: {
            return {
                ...state,
                grindingconItem: [...action.grindingconItem]
            }
        }
        case FULL_GRINDINGCONCRETE: {
            return {
                ...state,
                grindingconItem: [...action.grindingconItem]
            }
        }

        case SET_GRINDINGCONCRETE: {
            return {
                ...state,
                sGrindingcon: action.sGrindingcon
            }
        }
        //7 Генераторы
        case GENERATORS_RED: {
            return {
                ...state,
                generatorsItem: [...action.generatorsItem]
            }
        }
        case FULL_GENERATORS: {
            return {
                ...state,
                generatorsItem: [...action.generatorsItem]
            }
        }

        case SET_GENERATORS: {
            return {
                ...state,
                sGenerators: action.sGenerators
            }
        }
        //8 Тепловое оборудование
        case THERMAL_RED: {
            return {
                ...state,
                thermalItem: [...action.thermalItem]
            }
        }
        case FULL_THERMAL: {
            return {
                ...state,
                thermalItem: [...action.thermalItem]
            }
        }

        case SET_THERMAL: {
            return {
                ...state,
                sThermal: action.sThermal
            }
        }
        //9 Компрессоры, краскопульты, пескоструй
        case COMPRESSORS_RED: {
            return {
                ...state,
                compressorsItem: [...action.compressorsItem]
            }
        }
        case FULL_COMPRESSORS: {
            return {
                ...state,
                compressorsItem: [...action.compressorsItem]
            }
        }

        case SET_COMPRESSORS: {
            return {
                ...state,
                sCompressors: action.sCompressors
            }
        }
        //10 Садовое оборудование, Ямобуры
        case YAMOBURY_RED: {
            return {
                ...state,
                yamoburyItem: [...action.yamoburyItem]
            }
        }
        case FULL_YAMOBURY: {
            return {
                ...state,
                yamoburyItem: [...action.yamoburyItem]
            }
        }

        case SET_YAMOBURY: {
            return {
                ...state,
                sYamobury: action.sYamobury
            }
        }
        //11 Мотопомпы, Электро-насосы
        case PUMPS_RED: {
            return {
                ...state,
                pumpsItem: [...action.pumpsItem]
            }
        }
        case FULL_PUMPS: {
            return {
                ...state,
                pumpsItem: [...action.pumpsItem]
            }
        }

        case SET_PUMPS: {
            return {
                ...state,
                sPamps: action.sPamps
            }
        }
        //12 Бетономешалки
        case CONCRETEMIXERS_RED: {
            return {
                ...state,
                concretmixItem: [...action.concretmixItem]
            }
        }
        case FULL_CONCRETEMIXERS: {
            return {
                ...state,
                concretmixItem: [...action.concretmixItem]
            }
        }

        case SET_CONCRETEMIXERS: {
            return {
                ...state,
                sConcretmix: action.sConcretmix
            }
        }
        //13 Плиткорезы
        case TILECUTTER_RED: {
            return {
                ...state,
                tilecutterItem: [...action.tilecutterItem]
            }
        }
        case FULL_TILECUTTER: {
            return {
                ...state,
                tilecutterItem: [...action.tilecutterItem]
            }
        }

        case SET_TILECUTTER: {
            return {
                ...state,
                sTilecutter: action.sTilecutter
            }
        }
        //14 Вибраторы для бетона. Виброрейки
        case VIBROSLATS_RED: {
            return {
                ...state,
                vibroslatsItem: [...action.vibroslatsItem]
            }
        }
        case FULL_VIBROSLATS: {
            return {
                ...state,
                vibroslatsItem: [...action.vibroslatsItem]
            }
        }

        case SET_VIBROSLATS: {
            return {
                ...state,
                sVibroslats: action.sVibroslats
            }
        }
        //15 Шуруповерты
        case SCREWDRIVERS_RED: {
            return {
                ...state,
                screwdriversItem: [...action.screwdriversItem]
            }
        }
        case FULL_SCREWDRIVERS: {
            return {
                ...state,
                screwdriversItem: [...action.screwdriversItem]
            }
        }

        case SET_SCREWDRIVERS: {
            return {
                ...state,
                sScrewdrivers: action.sScrewdrivers
            }
        }
        //16 Рохли, Тельфер, Таль, Домкрат
        case ROHLI_RED: {
            return {
                ...state,
                rohliItem: [...action.rohliItem]
            }
        }
        case FULL_ROHLI: {
            return {
                ...state,
                rohliItem: [...action.rohliItem]
            }
        }

        case SET_ROHLI: {
            return {
                ...state,
                sRohli: action.sRohli
            }
        }
        //17 Оборудование для монтажа кондиционеров
        case AIRCONDITIONING_RED: {
            return {
                ...state,
                airconditionItem: [...action.airconditionItem]
            }
        }
        case FULL_AIRCONDITIONING: {
            return {
                ...state,
                airconditionItem: [...action.airconditionItem]
            }
        }

        case SET_AIRCONDITIONING: {
            return {
                ...state,
                sAircondition: action.sAircondition
            }
        }
        //18 Сварочное оборудование
        case WELDING_RED: {
            return {
                ...state,
                weldingItem: [...action.weldingItem]
            }
        }
        case FULL_WELDING: {
            return {
                ...state,
                weldingItem: [...action.weldingItem]
            }
        }

        case SET_WELDING: {
            return {
                ...state,
                sWelding: action.sWelding
            }
        }
        //19 Рубанки, фрейзеры
        case FRASERS_RED: {
            return {
                ...state,
                frasersItem: [...action.frasersItem]
            }
        }
        case FULL_FRASERS: {
            return {
                ...state,
                frasersItem: [...action.frasersItem]
            }
        }

        case SET_FRASERS: {
            return {
                ...state,
                sFrasers: action.sFrasers
            }
        }
        //20 Шлифовальные машины по дереву
        case WOODSANDERS_RED: {
            return {
                ...state,
                woodsandersItem: [...action.woodsandersItem]
            }
        }
        case FULL_WOODSANDERS: {
            return {
                ...state,
                woodsandersItem: [...action.woodsandersItem]
            }
        }

        case SET_WOODSANDERS: {
            return {
                ...state,
                sWoodsanders: action.sWoodsanders
            }
        }
        //21 Металлорежущие инструменты
        case METALCUTTING_RED: {
            return {
                ...state,
                metalcuttingItem: [...action.metalcuttingItem]
            }
        }
        case FULL_METALCUTTING: {
            return {
                ...state,
                metalcuttingItem: [...action.metalcuttingItem]
            }
        }

        case SET_METALCUTTING: {
            return {
                ...state,
                sMetalcutting: action.sMetalcutting
            }
        }
        //22 Уровни, нивелиры, измерительные приборы
        case MEASURING_RED: {
            return {
                ...state,
                measuringItem: [...action.measuringItem]
            }
        }
        case FULL_MEASURING: {
            return {
                ...state,
                measuringItem: [...action.measuringItem]
            }
        }

        case SET_MEASURING: {
            return {
                ...state,
                sMeasuring: action.sMeasuring
            }
        }
        //23 Бензопилы. Лобзики. Торцевые пилы 
        case CHAINSAWS_RED: {
            return {
                ...state,
                chainsawsItem: [...action.chainsawsItem]
            }
        }
        case FULL_CHAINSAWS: {
            return {
                ...state,
                chainsawsItem: [...action.chainsawsItem]
            }
        }

        case SET_CHAINSAWS: {
            return {
                ...state,
                sChainsaws: action.sChainsaws
            }
        }
        //24 Пылесосы
        case VACUUMS_RED: {
            return {
                ...state,
                vacuumsItem: [...action.vacuumsItem]
            }
        }
        case FULL_VACUUMS: {
            return {
                ...state,
                vacuumsItem: [...action.vacuumsItem]
            }
        }

        case SET_VACUUMS: {
            return {
                ...state,
                sVacuums: action.sVacuums
            }
        }
        //25 Болгарки
        case BULGARIAN_RED: {
            return {
                ...state,
                bulgarianItem: [...action.bulgarianItem]
            }
        }
        case FULL_BULGARIAN: {
            return {
                ...state,
                bulgarianItem: [...action.bulgarianItem]
            }
        }

        case SET_BULGARIAN: {
            return {
                ...state,
                sBulgarian: action.sBulgarian
            }
        }
        //26 Моющее оборудование 
        case WASHINGEQUIPMENT_RED: {
            return {
                ...state,
                washingequipmentItem: [...action.washingequipmentItem]
            }
        }
        case FULL_WASHINGEQUIPMENT: {
            return {
                ...state,
                washingequipmentItem: [...action.washingequipmentItem]
            }
        }

        case SET_WASHINGEQUIPMENT: {
            return {
                ...state,
                sWashingequipment: action.sWashingequipment
            }
        }
        //27 Сантехническое оборудование
        case PLUMBINGEQUIPMENT_RED: {
            return {
                ...state,
                plumbingequipmentItem: [...action.plumbingequipmentItem]
            }
        }
        case FULL_PLUMBINGEQUIPMENT: {
            return {
                ...state,
                plumbingequipmentItem: [...action.plumbingequipmentItem]
            }
        }

        case SET_PLUMBINGEQUIPMENT: {
            return {
                ...state,
                sPlumbingequipment: action.sPlumbingequipment
            }
        }
        //28 Строительные леса
        case SCAFFOLDING_RED: {
            return {
                ...state,
                scaffoldingItem: [...action.scaffoldingItem]
            }
        }
        case FULL_SCAFFOLDING: {
            return {
                ...state,
                scaffoldingItem: [...action.scaffoldingItem]
            }
        }

        case SET_SCAFFOLDING: {
            return {
                ...state,
                sScaffolding: action.sScaffolding
            }
        }
        //29 Опалубочное оборудование
        case FORMWORKEQUIPMENT_RED: {
            return {
                ...state,
                formworkequipmentItem: [...action.formworkequipmentItem]
            }
        }
        case FULL_FORMWORKEQUIPMENT: {
            return {
                ...state,
                formworkequipmentItem: [...action.formworkequipmentItem]
            }
        }

        case SET_FORMWORKEQUIPMENT: {
            return {
                ...state,
                sFormworkequipment: action.sFormworkequipment
            }
        }
        //30 Лестницы и вышки тура
        case STAIRSTOUR_RED: {
            return {
                ...state,
                stairstourItem: [...action.stairstourItem]
            }
        }
        case FULL_STAIRSTOUR: {
            return {
                ...state,
                stairstourItem: [...action.stairstourItem]
            }
        }

        case SET_STAIRSTOUR: {
            return {
                ...state,
                sStairstour: action.sStairstour
            }
        }
        //31 Электромонтажное оборудование
        case WIRINGEQUIPMENT_RED: {
            return {
                ...state,
                wiringequipmentItem: [...action.wiringequipmentItem]
            }
        }
        case FULL_WIRINGEQUIPMENT: {
            return {
                ...state,
                wiringequipmentItem: [...action.wiringequipmentItem]
            }
        }

        case SET_WIRINGEQUIPMENT: {
            return {
                ...state,
                sWiringequipment: action.sWiringequipment
            }
        }
        //32 Дрели
        case DRILLS_RED: {
            return {
                ...state,
                drillsItem: [...action.drillsItem]
            }
        }
        case FULL_DRILLS: {
            return {
                ...state,
                drillsItem: [...action.drillsItem]
            }
        }

        case SET_DRILLS: {
            return {
                ...state,
                sDrills: action.sDrills
            }
        }
        //33 Спортивное снаряжение
        case SPORTEQUIPMENT_RED: {
            return {
                ...state,
                sportequipmentItem: [...action.sportequipmentItem]
            }
        }
        case FULL_SPORTEQUIPMENT: {
            return {
                ...state,
                sportequipmentItem: [...action.sportequipmentItem]
            }
        }

        case SET_SPORTEQUIPMENT: {
            return {
                ...state,
                sSportequipment: action.sSportequipment
            }
        }
        //34 Буры, коронки для перфораторов
        case BOERS_RED: {
            return {
                ...state,
                boersItem: [...action.boersItem]
            }
        }
        case FULL_BOERS: {
            return {
                ...state,
                boersItem: [...action.boersItem]
            }
        }

        case SET_BOERS: {
            return {
                ...state,
                sBoers: action.sBoers
            }
        }
        //35 Разное
        case MISCELLANEOUS_RED: {
            return {
                ...state,
                miscellaneousItem: [...action.miscellaneousItem]
            }
        }
        case FULL_MISCELLANEOUS: {
            return {
                ...state,
                miscellaneousItem: [...action.miscellaneousItem]
            }
        }

        case SET_MISCELLANEOUS: {
            return {
                ...state,
                sMiscellaneous: action.sMiscellaneous
            }
        }
        //36 Расходные Материалы
        case CONSUMABLES_RED: {
            return {
                ...state,
                consumablesItem: [...action.consumablesItem]
            }
        }
        case FULL_CONSUMABLES: {
            return {
                ...state,
                consumablesItem: [...action.consumablesItem]
            }
        }

        case SET_CONSUMABLES: {
            return {
                ...state,
                sConsumables: action.sConsumables
            }
        }
        default:
            return state;
    }
}


//1 Вибротрамбовки (Виброноги)
export const vibratingPlatesAC = (vibratingPlatesItem) => ({
    type: VIBRATINGPLATES_RED,
    vibratingPlatesItem
})

const setVibratingPlates = (sVibratingPlates) => ({
    type: SET_VIBRATINGPLATES,
    sVibratingPlates
})

export const getVibratingPlates = (id) => (dispatch) => {
    hotelApi.getVibratingPlates(id).then(response => {
        dispatch(setVibratingPlates(response.data))
    })
}
//2 Вибротрамбовки (Виброплиты)
export const vibroLegsAC = (vibroLegsItem) => ({
    type: VIBROLEGS_RED,
    vibroLegsItem
})

const setVibroLegs = (sVibroLegs) => ({
    type: SET_VIBROLEGS,
    sVibroLegs
})

export const getVibroLegs = (id) => (dispatch) => {
    hotelApi.getVibroLegs(id).then(response => {
        dispatch(setVibroLegs(response.data))
    })
}
//3 Перфораторы
export const perforatorsAC = (perforatorsItem) => ({
    type: PERFORATORS_RED,
    perforatorsItem
})

const setPerforators = (sPerforators) => ({
    type: SET_PERFORATORS,
    sPerforators
})

export const getPerforators = (id) => (dispatch) => {
    hotelApi.getPerforators(id).then(response => {
        dispatch(setPerforators(response.data))
    })
}
//4 Отбойные молотки
export const jackhammersAC = (jackhammersItem) => ({
    type: JACKHAMMER_RED,
    jackhammersItem
})

const setJackhammers = (sJackhammers) => ({
    type: SET_JACKHAMMER,
    sJackhammers
})

export const getJackhammers = (id) => (dispatch) => {
    hotelApi.getJackhammers(id).then(response => {
        dispatch(setJackhammers(response.data))
    })
}
//5 Штроборезы ручные, Швонарезчики 
export const seamcuttersAC = (seamcuttersItem) => ({
    type: SEAMCUTTERS_RED,
    seamcuttersItem
})

const setSeamcutters = (sSeamcutters) => ({
    type: SET_SEAMCUTTERS,
    sSeamcutters
})

export const getSeamcutters = (id) => (dispatch) => {
    hotelApi.getSeamcutters(id).then(response => {
        dispatch(setSeamcutters(response.data))
    })
}
//6 Шлифовальные машины по бетону.
export const grindingconAC = (grindingconItem) => ({
    type: GRINDINGCONCRETE_RED,
    grindingconItem
})

const setGrindingcons = (sGrindingcon) => ({
    type: SET_GRINDINGCONCRETE,
    sGrindingcon
})

export const getGrindingcon = (id) => (dispatch) => {
    hotelApi.getGrindingcon(id).then(response => {
        dispatch(setGrindingcons(response.data))
    })
}
//7 Генераторы
export const generatorsAC = (generatorsItem) => ({
    type: GENERATORS_RED,
    generatorsItem
})

const setGenerators = (sGenerators) => ({
    type: SET_GENERATORS,
    sGenerators
})

export const getGenerators = (id) => (dispatch) => {
    hotelApi.getGenerators(id).then(response => {
        dispatch(setGenerators(response.data))
    })
}
//8 Тепловое оборудование
export const thermalAC = (thermalItem) => ({
    type: THERMAL_RED,
    thermalItem
})

const setThermal = (sThermal) => ({
    type: SET_THERMAL,
    sThermal
})

export const getThermal = (id) => (dispatch) => {
    hotelApi.getThermal(id).then(response => {
        dispatch(setThermal(response.data))
    })
}
//9 Компрессоры, краскопульты, пескоструй
export const compressorsAC = (compressorsItem) => ({
    type: COMPRESSORS_RED,
    compressorsItem
})

const setCompressors = (sCompressors) => ({
    type: SET_COMPRESSORS,
    sCompressors
})

export const getCompressors = (id) => (dispatch) => {
    hotelApi.getCompressors(id).then(response => {
        dispatch(setCompressors(response.data))
    })
}
//10 Садовое оборудование, Ямобуры
export const yamoburyAC = (yamoburyItem) => ({
    type: YAMOBURY_RED,
    yamoburyItem
})

const setYamobury = (sYamobury) => ({
    type: SET_YAMOBURY,
    sYamobury
})

export const getYamobury = (id) => (dispatch) => {
    hotelApi.getYamobury(id).then(response => {
        dispatch(setYamobury(response.data))
    })
}
//11 Мотопомпы, Электро-насосы
export const pumpsAC = (pumpsItem) => ({
    type: PUMPS_RED,
    pumpsItem
})

const setPamps = (sPamps) => ({
    type: SET_PUMPS,
    sPamps
})

export const getPamps = (id) => (dispatch) => {
    hotelApi.getPamps(id).then(response => {
        dispatch(setPamps(response.data))
    })
}
//12 Бетономешалки
export const concretmixAC = (concretmixItem) => ({
    type: CONCRETEMIXERS_RED,
    concretmixItem
})

const setConcretmix = (sConcretmix) => ({
    type: SET_CONCRETEMIXERS,
    sConcretmix
})

export const getConcretmix = (id) => (dispatch) => {
    hotelApi.getConcretmix(id).then(response => {
        dispatch(setConcretmix(response.data))
    })
}
//13 Плиткорезы
export const tilecutterAC = (tilecutterItem) => ({
    type: TILECUTTER_RED,
    tilecutterItem
})

const setTilecutter = (sTilecutter) => ({
    type: SET_TILECUTTER,
    sTilecutter
})

export const getTilecutter = (id) => (dispatch) => {
    hotelApi.getTilecutter(id).then(response => {
        dispatch(setTilecutter(response.data))
    })
}
//14 Вибраторы для бетона. Виброрейки
export const vibroslatsAC = (vibroslatsItem) => ({
    type: VIBROSLATS_RED,
    vibroslatsItem
})

const setVibroslats = (sVibroslats) => ({
    type: SET_VIBROSLATS,
    sVibroslats
})

export const getVibroslats = (id) => (dispatch) => {
    hotelApi.getVibroslats(id).then(response => {
        dispatch(setVibroslats(response.data))
    })
}
//15 Шуруповерты screwdrivers
export const screwdriversAC = (screwdriversItem) => ({
    type: SCREWDRIVERS_RED,
    screwdriversItem
})

const setScrewdrivers = (sScrewdrivers) => ({
    type: SET_SCREWDRIVERS,
    sScrewdrivers
})

export const getScrewdrivers = (id) => (dispatch) => {
    hotelApi.getScrewdrivers(id).then(response => {
        dispatch(setScrewdrivers(response.data))
    })
}
//16 Рохли, Тельфер, Таль, Домкрат
export const rohliAC = (rohliItem) => ({
    type: ROHLI_RED,
    rohliItem
})

const setRohli = (sRohli) => ({
    type: SET_ROHLI,
    sRohli
})

export const getRohli = (id) => (dispatch) => {
    hotelApi.getRohli(id).then(response => {
        dispatch(setRohli(response.data))
    })
}
//17 Оборудование для монтажа кондиционеров
export const airconditionAC = (airconditionItem) => ({
    type: AIRCONDITIONING_RED,
    airconditionItem
})

const setAircondition = (sAircondition) => ({
    type: SET_AIRCONDITIONING,
    sAircondition
})

export const getAircondition = (id) => (dispatch) => {
    hotelApi.getAircondition(id).then(response => {
        dispatch(setAircondition(response.data))
    })
}
//18 Сварочное оборудование
export const weldingAC = (weldingItem) => ({
    type: WELDING_RED,
    weldingItem
})

const setWelding = (sWelding) => ({
    type: SET_WELDING,
    sWelding
})

export const getWelding = (id) => (dispatch) => {
    hotelApi.getWelding(id).then(response => {
        dispatch(setWelding(response.data))
    })
}
//19 Рубанки, фрейзеры
export const frasersAC = (frasersItem) => ({
    type: FRASERS_RED,
    frasersItem
})

const setFrasers = (sFrasers) => ({
    type: SET_FRASERS,
    sFrasers
})

export const getFrasers = (id) => (dispatch) => {
    hotelApi.getFrasers(id).then(response => {
        dispatch(setFrasers(response.data))
    })
}
//20 Шлифовальные машины по дереву
export const woodsandersAC = (woodsandersItem) => ({
    type: WOODSANDERS_RED,
    woodsandersItem
})

const setWoodsanders = (sWoodsanders) => ({
    type: SET_WOODSANDERS,
    sWoodsanders
})

export const getWoodsanders = (id) => (dispatch) => {
    hotelApi.getWoodsanders(id).then(response => {
        dispatch(setWoodsanders(response.data))
    })
}
//21 Металлорежущие инструменты
export const metalcuttingAC = (metalcuttingItem) => ({
    type: METALCUTTING_RED,
    metalcuttingItem
})

const setMetalcutting = (sMetalcutting) => ({
    type: SET_METALCUTTING,
    sMetalcutting
})

export const getMetalcutting = (id) => (dispatch) => {
    hotelApi.getMetalcutting(id).then(response => {
        dispatch(setMetalcutting(response.data))
    })
}
//22 Уровни, нивелиры, измерительные приборы
export const measuringAC = (measuringItem) => ({
    type: MEASURING_RED,
    measuringItem
})

const setMeasuring = (sMeasuring) => ({
    type: SET_MEASURING,
    sMeasuring
})

export const getMeasuring = (id) => (dispatch) => {
    hotelApi.getMeasuring(id).then(response => {
        dispatch(setMeasuring(response.data))
    })
}
//23 Бензопилы. Лобзики. Торцевые пилы 
export const chainsawsAC = (chainsawsItem) => ({
    type: CHAINSAWS_RED,
    chainsawsItem
})

const setChainsaws = (sChainsaws) => ({
    type: SET_CHAINSAWS,
    sChainsaws
})

export const getChainsaws = (id) => (dispatch) => {
    hotelApi.getChainsaws(id).then(response => {
        dispatch(setChainsaws(response.data))
    })
}
//24 Пылесосы
export const vacuumsAC = (vacuumsItem) => ({
    type: VACUUMS_RED,
    vacuumsItem
})

const setVacuums = (sVacuums) => ({
    type: SET_VACUUMS,
    sVacuums
})

export const getVacuums = (id) => (dispatch) => {
    hotelApi.getVacuums(id).then(response => {
        dispatch(setVacuums(response.data))
    })
}
//25 Болгарки
export const bulgarianAC = (bulgarianItem) => ({
    type: BULGARIAN_RED,
    bulgarianItem
})

const setBulgarian = (sBulgarian) => ({
    type: SET_BULGARIAN,
    sBulgarian
})

export const getBulgarian = (id) => (dispatch) => {
    hotelApi.getBulgarian(id).then(response => {
        dispatch(setBulgarian(response.data))
    })
}
//26 Моющее оборудование 
export const washingequipmentAC = (washingequipmentItem) => ({
    type: WASHINGEQUIPMENT_RED,
    washingequipmentItem
})

const setWashingequipment = (sWashingequipment) => ({
    type: SET_WASHINGEQUIPMENT,
    sWashingequipment
})

export const getWashingequipment = (id) => (dispatch) => {
    hotelApi.getWashingequipment(id).then(response => {
        dispatch(setWashingequipment(response.data))
    })
}
//27 Сантехническое оборудование
export const plumbingequipmentAC = (plumbingequipmentItem) => ({
    type: PLUMBINGEQUIPMENT_RED,
    plumbingequipmentItem
})

const setPlumbingequipment = (sPlumbingequipment) => ({
    type: SET_PLUMBINGEQUIPMENT,
    sPlumbingequipment
})

export const getPlumbingequipment = (id) => (dispatch) => {
    hotelApi.getPlumbingequipment(id).then(response => {
        dispatch(setPlumbingequipment(response.data))
    })
}
//28 Строительные леса
export const scaffoldingAC = (scaffoldingItem) => ({
    type: SCAFFOLDING_RED,
    scaffoldingItem
})

const setScaffolding = (sScaffolding) => ({
    type: SET_SCAFFOLDING,
    sScaffolding
})

export const getScaffolding = (id) => (dispatch) => {
    hotelApi.getScaffolding(id).then(response => {
        dispatch(setScaffolding(response.data))
    })
}
//29 Опалубочное оборудование
export const formworkequipmentAC = (formworkequipmentItem) => ({
    type: FORMWORKEQUIPMENT_RED,
    formworkequipmentItem
})

const setFormworkequipment = (sFormworkequipment) => ({
    type: SET_FORMWORKEQUIPMENT,
    sFormworkequipment
})

export const getFormworkequipmentg = (id) => (dispatch) => {
    hotelApi.getFormworkequipmentg(id).then(response => {
        dispatch(setFormworkequipment(response.data))
    })
}
//30 Лестницы и вышки тура
export const stairstourAC = (stairstourItem) => ({
    type: STAIRSTOUR_RED,
    stairstourItem
})

const setStairstour = (sStairstour) => ({
    type: SET_STAIRSTOUR,
    sStairstour
})

export const getStairstour = (id) => (dispatch) => {
    hotelApi.getStairstour(id).then(response => {
        dispatch(setStairstour(response.data))
    })
}
//31 Электромонтажное оборудование
export const wiringequipmentAC = (wiringequipmentItem) => ({
    type: WIRINGEQUIPMENT_RED,
    wiringequipmentItem
})

const setWiringequipment = (sWiringequipment) => ({
    type: SET_WIRINGEQUIPMENT,
    sWiringequipment
})

export const getWiringequipment = (id) => (dispatch) => {
    hotelApi.getWiringequipment(id).then(response => {
        dispatch(setWiringequipment(response.data))
    })
}
//32 Дрели
export const drillsAC = (drillsItem) => ({
    type: DRILLS_RED,
    drillsItem
})

const setDrills = (sDrills) => ({
    type: SET_DRILLS,
    sDrills
})

export const getDrills = (id) => (dispatch) => {
    hotelApi.getDrills(id).then(response => {
        dispatch(setDrills(response.data))
    })
}
//33 Спортивное снаряжение
export const sportequipmentAC = (sportequipmentItem) => ({
    type: SPORTEQUIPMENT_RED,
    sportequipmentItem
})

const setSportequipment = (sSportequipment) => ({
    type: SET_SPORTEQUIPMENT,
    sSportequipment
})

export const getSportequipment = (id) => (dispatch) => {
    hotelApi.getSportequipment(id).then(response => {
        dispatch(setSportequipment(response.data))
    })
}
//34 Буры, коронки для перфораторов
export const boersAC = (boersItem) => ({
    type: BOERS_RED,
    boersItem
})

const setBoers = (sBoers) => ({
    type: SET_BOERS,
    sBoers
})

export const getBoers = (id) => (dispatch) => {
    hotelApi.getBoers(id).then(response => {
        dispatch(setBoers(response.data))
    })
}
//35 Разное
export const miscellaneousAC = (miscellaneousItem) => ({
    type: MISCELLANEOUS_RED,
    miscellaneousItem
})

const setMiscellaneous = (sMiscellaneous) => ({
    type: SET_MISCELLANEOUS,
    sMiscellaneous
})

export const getMiscellaneous = (id) => (dispatch) => {
    hotelApi.getMiscellaneous(id).then(response => {
        dispatch(setMiscellaneous(response.data))
    })
}
//36 Расходные Материалы
export const consumablesAC = (consumablesItem) => ({
    type: CONSUMABLES_RED,
    consumablesItem
})

const setConsumables = (sConsumables) => ({
    type: SET_CONSUMABLES,
    sConsumables
})

export const getConsumables = (id) => (dispatch) => {
    hotelApi.getConsumables(id).then(response => {
        dispatch(setConsumables(response.data))
    })
}

export default subItemsDorojReduser;