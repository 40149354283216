import './css/styleColor.scss';
// import reportWebVitals from './reportWebVitals';
import './index.scss'
import store from './redux/rootReduser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import SimpleReactLightbox from 'simple-react-lightbox'
import AOS from './hook/AOS';


let _callSubscriber = (state) => {
    ReactDOM.render(
      <React.StrictMode>
        <SimpleReactLightbox>
          <BrowserRouter>
            <Provider store={store}>
              <AOS>
                <App />
              </AOS>
            </Provider>
          </BrowserRouter>
        </SimpleReactLightbox>
      </React.StrictMode>,
    document.getElementById('root'));
 
}
_callSubscriber(store.getState());
store.subscribe(_callSubscriber);
