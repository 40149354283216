import React from "react"
// import { NavLink } from "react-router-dom"
import cont from '../../../assets/images/jpegPng/contacts.png'
import style from './contact.module.scss'

function ContactHome(props) {
    // if (!props.sСategory) {
    //     return <div>Загрузка меню Категорий...</div>
    // }


    return (
        <>
            <div className={`pl1 pr1 pt1 ${style.container}`}>
                <div className={`dFlex alignItemsCenter justifyContentCenter`}>

                    <div className={`p05 ${style.contactCardContainer}`}>
                        <div className={`p1 ${style.cardContainer}`}>
                            <h3>
                                Контакты
                            </h3>
                            <div>
                                <div className={`dFlex justifyContentSpaceBetween pt1 pb1`} style={{ width: `100%` }}>
                                    <div className={`${style.title}`}>
                                        Адрес:
                                    </div>
                                    <div className={`${style.link}`}>
                                        <a rel="noreferrer" href="https://yandex.ru/maps/1107/anapa/?from=mapframe&amp;ll=37.365265%2C44.899618&amp;mode=usermaps&amp;source=mapframe&amp;um=constructor%3ACj3iZKziW3YjmEAJopweK_IVKvEzID5I&amp;utm_source=mapframe&amp;z=17" target="_blank">Адрес: Россия, г. Анапа, Анапское ш., д.99а, корп. 1</a>
                                    </div>
                                </div>
                                <div className={`dFlex justifyContentSpaceBetween pt1 pb1`} style={{ width: `100%` }}>
                                    <div className={`${style.title}`}>
                                        Телефон:
                                    </div>
                                    <div className={`${style.link}`}>
                                        <a href="tel:+79892429393">
                                        +7 (989) 242-93-93
                                        </a>
                                    </div>
                                </div>
                                <div className={`dFlex justifyContentSpaceBetween pt1 pb1`} style={{ width: `100%` }}>
                                    <div className={`${style.title}`}>
                                        Почта:
                                    </div>
                                    <div className={`${style.link}`}>
                                        <a href='mailto:info@krayprokat93.ru'>
                                        info@krayprokat93.ru
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`ml2 ${style.contactImg}`}>
                        <img style={{ width: `100%`, maxWidth: `460px` }} src={`${cont}`} alt="Контакты" />
                    </div>
                </div>
            </div>
        </>

    )
}
export default ContactHome