import { Route, Redirect } from 'react-router';
import Home from './home/Home';                                                                             // Главная страница
import AboutUs from './aboutUs/AboutUs';                                                                    // Об Магазине

import Services from './services/Services';                                                                 // Услуги

import CContainers from './contacts/CContainer';                                                            // Контакты

import PersonalData from './footer/personalData/PersonalData';                                              // Персон. данные
import Delivery from './delivery/Delivery';                                                                 // Доставка

import CategContainer from './pages/CategContainer';                                                        // Категории

import Vibrolegs from './pages/vibrolegs/Vibrolegs';                                                        // Виброноги
import VibLegContainer from './pages/vibrolegs/VibLegContainer';                                            // Виброноги подробнее

import Vibroplit from './pages/vibroplit/Vibropil';                                                         // Виброплиты
import VibContainer from './pages/vibroplit/VibContainer';                                                  // Виброплиты подробнее

import Perforators from './pages/perforators/Perforators';                                                  // Перфораторы
import PerContainer from './pages/perforators/PerContainer';                                                // Перфораторы подробнее

import Jackhammers from './pages/jackhammers/Jackhammers';                                                  // Отбойные молотки
import JackFPContainer from './pages/jackhammers/fullPage/JackFPContainer';                                 // Отбойные молотки подробнее

import SeamCutters from './pages/seamcutters/SeamCutters';                                                  // Штроборезы ручные, Швонарезчики
import SeamCFPContainer from './pages/seamcutters/fullPage/SeamCFPContainer';                               // Штроборезы ручные, Швонарезчики подробнее

import GrindingConcrete from './pages/grindingConcrete/GrindingConcrete';
import GCFPContainer from './pages/grindingConcrete/fullPage/GCFPContainer';

import Generators from './pages/generators/Generators';
import GFPContainer from './pages/generators/fullPage/GFPContainer';

import Thermals from './pages/thermals/Thermals';
import TFPContainer from './pages/thermals/fullPage/TFPContainer';

import Compressors from './pages/compressors/Compressors';
import CompressorsFPContainer from './pages/compressors/fullPage/CompressorsFPContainer';

import Yamoburys from './pages/yamoburys/Yamoburys';
import YFPContainer from './pages/yamoburys/fullPage/YFPContainer';

import Pamps from './pages/pamps/Pamps';
import PampsFPContainer from './pages/pamps/fullPage/PampsFPContainer';

import Concretmixs from './pages/concretmixs/Concretmixs';
import ConcretmixsFPContainer from './pages/concretmixs/fullPage/ConcretmixsFPContainer';

import Tilecutters from './pages/tilecutters/Tilecutters';
import TilecuttersFPContainer from './pages/tilecutters/fullPage/TilecuttersFPContainer';

import Vibroslats from './pages/vibroslats/Vibroslats';
import VibroslatsFPContainer from './pages/vibroslats/fullPage/VibroslatsFPContainer';

import Screwdrivers from './pages/screwdrivers/Screwdrivers';
import ScrewdriversFPContainer from './pages/screwdrivers/fullPage/ScrewdriversFPContainer';

import Rohli from './pages/rohli/Rohli';
import RohliFPContainer from './pages/rohli/fullPage/RohliFPContainer';

import Equipairconds from './pages/equipairconds/Equipairconds';
import EquipaircondsFPContainer from './pages/equipairconds/fullPage/EquipaircondsFPContainer';

import Weldings from './pages/weldings/Weldings';
import WeldingsFPContainer from './pages/weldings/fullPage/WeldingsFPContainer';

import Frasers from './pages/frasers/Frasers';
import FrasersFPContainer from './pages/frasers/fullPage/FrasersFPContainer';

import Woodsanders from './pages/woodsanders/Woodsanders';
import WoodsandersFPContainer from './pages/woodsanders/fullPage/WoodsandersFPContainer';

import Metalcuttings from './pages/metalcuttings/Metalcuttings';
import MetalcuttingsFPContainer from './pages/metalcuttings/fullPage/MetalcuttingsFPContainer';

import Levels from './pages/levels/Levels';
import LevelsFPContainer from './pages/levels/fullPage/LevelsFPContainer';

import Jigsaws from './pages/jigsaws/Jigsaws';
import JigsawsFPContainer from './pages/jigsaws/fullPage/JigsawsFPContainer';

import Vacuums from './pages/vacuums/Vacuums';
import VacuumsFPContainer from './pages/vacuums/fullPage/VacuumsFPContainer';

import Bulgarians from './pages/bulgarians/Bulgarians';
import BulgariansFPContainer from './pages/bulgarians/fullPage/BulgariansFPContainer';

import Washingequs from './pages/washingequs/Washingequs';
import WashingequsFPContainer from './pages/washingequs/fullPage/WashingequsFPContainer';

import Plumbingequs from './pages/plumbingequs/Plumbingequs';
import PlumbingequsFPContainer from './pages/plumbingequs/fullPage/PlumbingequsFPContainer';

import Scaffoldings from './pages/scaffoldings/Scaffoldings';
import ScaffoldingsFPContainer from './pages/scaffoldings/fullPage/ScaffoldingsFPContainer';

import Formworkequs from './pages/formworkequs/Formworkequs';
import FormworkequsFPContainer from './pages/formworkequs/fullPage/FormworkequsFPContainer';

import Stairstours from './pages/stairstours/Stairstours';
import StairstoursFPContainer from './pages/stairstours/fullPage/StairstoursFPContainer';

import ElectricalinstallsFPContainer from './pages/electricalinstalls/fullPage/ElectricalinstallsFPContainer';
import Electricalinstalls from './pages/electricalinstalls/Electricalinstalls';

import Drills from './pages/drills/Drills';
import DrillsFPContainer from './pages/drills/fullPage/DrillsFPContainer';

import Sportequs from './pages/sportequs/Sportequs';
import SportequsFPContainer from './pages/sportequs/fullPage/SportequsFPContainer';

import Drillscrowns from './pages/drillscrowns/Drillscrowns';
import DrillscrownsFPContainer from './pages/drillscrowns/fullPage/DrillscrownsFPContainer';

import Others from './pages/others/Others';
import OthersFPContainer from './pages/others/fullPage/OthersFPContainer';

import Expendables from './pages/expendables/Expendables';
import ExpendablesFPContainer from './pages/expendables/fullPage/ExpendablesFPContainer';
import ServicesFPContainer from './services/service/fullServices/ServicesFPContainer';
import PLContainer from './pages/priceList/PLContainer';
import DeliveryContainer from './pages/delivery/DeliveryContainer';

import NoServFService from './services/service/noServerService/NoServFService';


function Routs(props) {

    return (
        <>
            <Route exact path="/"
                render={() => <Home />} />
            <Route exact path="/categories"
                render={() => <CategContainer />} />
            <Route path="/about"
                render={() => <AboutUs />} />
            <Route path="/contacts"
                render={() => <CContainers />} />
            <Route path="/personal-data"
                render={() => <PersonalData />} />
            <Route path="/delivery"
                render={() => <Delivery />} />
            <Route exact path="/service"
                render={() => <Services />} />
            <Route exact path="/service/:_id"
                render={() => <ServicesFPContainer />} />
            <Route exact path="/serv/6gght233s"
                render={() => <NoServFService />} />
            {/* subPage start */}
            <div style={{ maxWidth: `1400px`, margin: `auto` }}>
                <Route exact path="/categories/vibrorammers-vibrolegs"
                    render={() => <Vibrolegs />} />                                             {/* Виброноги */}
                <Route exact path="/categories/vibrorammers-vibrolegs/:_id"
                    render={() => <VibLegContainer />} />                                       {/* Виброноги подробно */}
                <Route exact path="/categories/vibrorammers-vibrating-plates"
                    render={() => <Vibroplit />} />                                             {/* Виброплиты */}
                <Route exact path="/categories/vibrorammers-vibrating-plates/:_id"
                    render={() => <VibContainer />} />                                          {/* Виброплиты подробно */}
                <Route exact path="/categories/perforators"
                    render={() => <Perforators />} />                                           {/* Перфораторы */}
                <Route exact path="/categories/perforators/:_id"
                    render={() => <PerContainer />} />                                          {/* Перфораторы подробно */}
                <Route exact path="/categories/jackhammers"
                    render={() => <Jackhammers />} />                                           {/* Отбойные молотки */}
                <Route exact path="/categories/jackhammers/:_id"
                    render={() => <JackFPContainer />} />                                       {/* Отбойные молотки подробно */}
                <Route exact path="/categories/hand-held-wall-chasers-joint-cutters"
                    render={() => <SeamCutters />} />                                           {/* Штроборезы ручные, Швонарезчики */}
                <Route exact path="/categories/hand-held-wall-chasers-joint-cutters/:_id"
                    render={() => <SeamCFPContainer />} />                                      {/* Штроборезы ручные, Швонарезчики подробно */}
                <Route exact path="/categories/grinding-concrete"
                    render={() => <GrindingConcrete />} />                                      {/* Шлифовальные машины по бетону. (Вертолёты) */}
                <Route exact path="/categories/grinding-concrete/:_id"
                    render={() => <GCFPContainer />} />                                         {/* Шлифовальные машины по бетону. (Вертолёты) подробно */}
                <Route exact path="/categories/generators"
                    render={() => <Generators />} />                                            {/* Генераторы */}
                <Route exact path="/categories/generators/:_id"
                    render={() => <GFPContainer />} />                                          {/* Генераторы подробно */}
                <Route exact path="/categories/thermal-equipment"
                    render={() => <Thermals />} />                                              {/* Тепловое оборудование */}
                <Route exact path="/categories/thermal-equipment/:_id"
                    render={() => <TFPContainer />} />                                          {/* Тепловое оборудование подробно */}
                <Route exact path="/categories/compressors"
                    render={() => <Compressors />} />                                           {/* Компрессоры, краскопульты, пескоструй */}
                <Route exact path="/categories/сompressors/:_id"
                    render={() => <CompressorsFPContainer />} />                                {/* Компрессоры, краскопульты, пескоструй подробно */}
                <Route exact path="/categories/garden-equipment"
                    render={() => <Yamoburys />} />                                             {/* Садовое оборудование, Ямобуры */}
                <Route exact path="/categories/garden-equipment/:_id"
                    render={() => <YFPContainer />} />                                          {/* Садовое оборудование, Ямобуры подробно */}
                <Route exact path="/categories/motor-electric-pumps"
                    render={() => <Pamps />} />                                                 {/* Мотопомпы, Электро-насосы */}
                <Route exact path="/categories/motor-electric-pumps/:_id"
                    render={() => <PampsFPContainer />} />                                      {/* Мотопомпы, Электро-насосы подробно */}
                <Route exact path="/categories/concrete-mixers"
                    render={() => <Concretmixs />} />                                           {/* Бетономешалки */}
                <Route exact path="/categories/concrete-mixers/:_id"
                    render={() => <ConcretmixsFPContainer />} />                                {/* Бетономешалки подробно */}
                <Route exact path="/categories/tile-cutter"
                    render={() => <Tilecutters />} />                                           {/* Плиткорезы */}
                <Route exact path="/categories/tile-cutter/:_id"
                    render={() => <TilecuttersFPContainer />} />                                {/* Плиткорезы подробно */}
                <Route exact path="/categories/vibroslats"
                    render={() => <Vibroslats />} />                                            {/* Вибраторы для бетона. Виброрейки */}
                <Route exact path="/categories/vibroslats/:_id"
                    render={() => <VibroslatsFPContainer />} />                                 {/* Вибраторы для бетона. Виброрейки подробно */}
                <Route exact path="/categories/screwdrivers"
                    render={() => <Screwdrivers />} />                                          {/* Шуруповерты */}
                <Route exact path="/categories/screwdrivers/:_id"
                    render={() => <ScrewdriversFPContainer />} />                               {/* Шуруповерты подробно */}
                <Route exact path="/categories/rohlis"
                    render={() => <Rohli />} />                                                 {/* Рохли, Тельфер, Таль, Домкрат */}
                <Route exact path="/categories/rohlis/:_id"
                    render={() => <RohliFPContainer />} />                                      {/* Рохли, Тельфер, Таль, Домкрат подробно */}
                <Route exact path="/categories/installation-equipment"
                    render={() => <Equipairconds />} />                                         {/* Оборудование для монтажа кондиционеров */}
                <Route exact path="/categories/installation-equipment/:_id"
                    render={() => <EquipaircondsFPContainer />} />                              {/* Оборудование для монтажа кондиционеров подробно */}
                <Route exact path="/categories/weldings"
                    render={() => <Weldings />} />                                              {/* Сварочное оборудование */}
                <Route exact path="/categories/welding/:_id"
                    render={() => <WeldingsFPContainer />} />                                   {/* Сварочное оборудование подробно */}
                <Route exact path="/categories/planers-frasers"
                    render={() => <Frasers />} />                                               {/* Рубанки, фрейзеры */}
                <Route exact path="/categories/planers-frasers/:_id"
                    render={() => <FrasersFPContainer />} />                                    {/* Рубанки, фрейзеры подробно */}
                <Route exact path="/categories/wood-sanders"
                    render={() => <Woodsanders />} />                                           {/* Шлифовальные машины по дереву */}
                <Route exact path="/categories/wood-sanders/:_id"
                    render={() => <WoodsandersFPContainer />} />                                {/* Шлифовальные машины по дереву подробно */}
                <Route exact path="/categories/metal-cutting"
                    render={() => <Metalcuttings />} />                                         {/* Металлорежущие инструменты */}
                <Route exact path="/categories/metal-cutting/:_id"
                    render={() => <MetalcuttingsFPContainer />} />                              {/* Металлорежущие инструменты подробно */}
                <Route exact path="/categories/levels-meters"
                    render={() => <Levels />} />                                                {/* Уровни, нивелиры, измерительные приборы */}
                <Route exact path="/categories/levels-meters/:_id"
                    render={() => <LevelsFPContainer />} />                                     {/* Уровни, нивелиры, измерительные приборы подробно */}
                <Route exact path="/categories/chainsaws"
                    render={() => <Jigsaws />} />                                               {/* Бензопилы. Лобзики. Торцевые пилы. */}
                <Route exact path="/categories/chainsaws/:_id"
                    render={() => <JigsawsFPContainer />} />                                    {/* Бензопилы. Лобзики. Торцевые пилы. подробно */}
                <Route exact path="/categories/vacuums"
                    render={() => <Vacuums />} />                                               {/* Пылесосы */}
                <Route exact path="/categories/vacuums/:_id"
                    render={() => <VacuumsFPContainer />} />                                    {/* Пылесосы подробно */}
                <Route exact path="/categories/bulgarian"
                    render={() => <Bulgarians />} />                                            {/* Болгарки */}
                <Route exact path="/categories/bulgarian/:_id"
                    render={() => <BulgariansFPContainer />} />                                 {/* Болгарки подробно */}
                <Route exact path="/categories/washing-equipment"
                    render={() => <Washingequs />} />                                           {/* Моющее оборудование */}
                <Route exact path="/categories/washing-equipment/:_id"
                    render={() => <WashingequsFPContainer />} />                                {/* Моющее оборудование подробно */}
                <Route exact path="/categories/sanitary-ware"
                    render={() => <Plumbingequs />} />                                          {/* Сантехническое оборудование */}
                <Route exact path="/categories/sanitary-ware/:_id"
                    render={() => <PlumbingequsFPContainer />} />                               {/* Сантехническое оборудование подробно */}
                <Route exact path="/categories/scaffolding"
                    render={() => <Scaffoldings />} />                                          {/* Строительные леса */}
                <Route exact path="/categories/scaffolding/:_id"
                    render={() => <ScaffoldingsFPContainer />} />                               {/* Строительные леса подробно */}
                <Route exact path="/categories/formwork-equipment"
                    render={() => <Formworkequs />} />                                          {/* Опалубочное оборудование */}
                <Route exact path="/categories/formwork-equipment/:_id"
                    render={() => <FormworkequsFPContainer />} />                               {/* Опалубочное оборудование подробно */}
                <Route exact path="/categories/stairs"
                    render={() => <Stairstours />} />                                           {/* Лестницы и вышки тура */}
                <Route exact path="/categories/stairs/:_id"
                    render={() => <StairstoursFPContainer />} />                                {/* Лестницы и вышки тура подробно */}
                <Route exact path="/categories/wiring-equipment"
                    render={() => <Electricalinstalls />} />                                    {/* Электромонтажное оборудование */}
                <Route exact path="/categories/wiring-equipment/:_id"
                    render={() => <ElectricalinstallsFPContainer />} />                         {/* Электромонтажное оборудование подробно */}
                <Route exact path="/categories/drills"
                    render={() => <Drills />} />                                                {/* Дрели */}
                <Route exact path="/categories/drills/:_id"
                    render={() => <DrillsFPContainer />} />                                     {/* Дрели подробно */}
                <Route exact path="/categories/sports-equipment"
                    render={() => <Sportequs />} />                                             {/* Спортивное снаряжение */}
                <Route exact path="/categories/sports-equipment/:_id"
                    render={() => <SportequsFPContainer />} />                                  {/* Спортивное снаряжение подробно */}
                <Route exact path="/categories/drills-bits"
                    render={() => <Drillscrowns />} />                                          {/* Буры, коронки для перфораторов */}
                <Route exact path="/categories/drills-bits/:_id"
                    render={() => <DrillscrownsFPContainer />} />                               {/* Буры, коронки для перфораторов подробно */}
                <Route exact path="/categories/miscellanea"
                    render={() => <Others />} />                                                {/* Разное */}
                <Route exact path="/categories/miscellanea/:_id"
                    render={() => <OthersFPContainer />} />                                     {/* Разное подробно */}
                <Route exact path="/categories/consumables"
                    render={() => <Expendables />} />                                           {/* Расходные Материалы */}
                <Route exact path="/categories/consumables/:_id"
                    render={() => <ExpendablesFPContainer />} />                                {/* Расходные Материалы подробно */}

                {/* subPage end */}
                <Route exact path="/priceList"
                    render={() => <PLContainer />} />
                <Route exact path="/deliveryList"
                    render={() => <DeliveryContainer />} />
            </div>
            {/* <Redirect from='*' to='/' /> */}

        </>
    )

}
export default Routs