
import WashingequsPage from './WashingequsPage'
import { connect } from "react-redux"
import { washingequipmentAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        washingequipmentItem: state.subItemsPage.washingequipmentItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        washingequipmentHot(washingequipmentItem) {
            dispatch(washingequipmentAC(washingequipmentItem))
        }
    }
}

export const WashingequsPContainer = connect(mapStateToProps, mapDispatchToProps)(WashingequsPage);
export default WashingequsPContainer;