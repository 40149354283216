import React from 'react'
import { connect } from 'react-redux'
import { getThermal } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class infoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getThermal(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sThermal={this.props.sThermal}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sThermal: state.subItemsPage.sThermal
})

let WithUrlDataContainerComponent = withRouter(infoContainer)

export default connect(mapStateToProps, { getThermal })(WithUrlDataContainerComponent)
