import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import YPContainer from './page/YPContainer'

class Yamoburys extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Садовое оборудование, Ямобуры
                </h1>
                <BreadCrumbs>Садовое оборудование, Ямобуры</BreadCrumbs>
                <YPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Yamoburys
