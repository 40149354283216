import React from 'react'
import footer from './footer.module.scss'
// import * as axios from 'axios';
// import TMContainer from "../header/rootMenu/tMContainer";
// import SocNetwork from './socNetwork/SocNetwork';
import Email from './email/Email';
// import Logotype from './logotype/Logotype';
import Phone from './phone/Phone';
import { NavLink } from 'react-router-dom';
// import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/jpegPng/logoRev.png'
import MapFooter from './map/MapFooter';
// import MenuMini from './menu/MenuMini';
import SNFContainer from './socNetwork/SNFContainer';

function Footer(props) {

    if (!props.sСategory) {
        return <div>Загрузка меню Категорий...</div>
    }


    let menuFooter = props.sСategory.map(itemM =>
        <NavLink key={itemM._id} to={`/categories/${itemM.link}`} className='p05 m05 footer__menu_item '>
            <p style={{ whiteSpace: `nowrap`, textOverflow: `ellipsis`, maxWidth: `200px`, width: `100%`, overflow: `hidden`, fontSize: `0.9rem` }}>
                {itemM.title}
            </p>
        </NavLink>
    )
    return (
        <>
            <footer className={`${footer.footerCont}`}>
                <div className={`${footer.footerContacts} justifyContentSpaceAround dFlex wrap`}>
                    <div>
                        <NavLink to='/'>
                            <img src={logo} alt="Логотип" className={`${footer.logotype}`} />
                        </NavLink>
                    </div>
                    <div className='dFlex alignItemsCenter'>
                        <Email />
                        <SNFContainer />
                        {/* <SocNetwork /> */}
                        <Phone />
                    </div>
                </div>
                <hr className='mt1 mb1' style={{ width: `80%`, margin: `auto` }} />

                <div className='dFlex wrap footerLinkMap justifyContentSpaceEvenly' >
                    <div className="footer__map">
                        <div className="dFlex wrap">
                            <div>
                                <h3 style={{ fontSize: `1rem`, color: `#fff` }}>КрайПрокат-93<br />
                                Прокат инструментов в Анапе</h3>
                                <p className='mb1' style={{ fontSize: '0.7rem' }}>
                                    Обращаем Ваше внимание на то, что данный Интернет-сайт носит исключительно информационный характер и ни при каких условиях результаты расчетов не являются публичной офертой, определяемой положениями Статьи 437 Гражданского кодекса Российской Федерации. За окончательным расчетом обращайтесь к нашим менеджерам.
                                </p>
                            </div>
                        </div>
                        <MapFooter />
                    </div>
                    <div className="footer__menu dFlex wrap pl1 pr1" style={{ justifyContent: `center`, alignContent: `start` }}>
                        <h2 className='pl1' style={{ width: `100%`, fontSize: `1.5rem` }}>Категории</h2>
                        {menuFooter}
                    </div>

                </div>
                <br />
                <div style={{ maxWidth: `1170px`, margin: `auto`, fontSize: `0.7rem` }}>
                    Продолжая работу с сайтом, вы даете согласие на использование сайтом cookies и <NavLink to="/personal-data">обработку персональных данных</NavLink> в целях функционирования сайта, проведения ретаргетинга, статистических исследований, улучшения сервиса и предоставления релевантной рекламной информации на основе ваших предпочтений и интересов.
                </div>
                <hr />

                <div style={{ fontSize: `0.9rem`, textAlign: `center`, width: `100%` }}>
                    © КрайПрокат-93 2024
                </div>
            </footer>
        </>


    )
}



export default Footer
