import React from 'react'
import AHContainer from './AboutHotel/AHContainer'
import { seo } from '../../hook/seo';
import c from './ab.module.scss'
import fon from '../../assets/images/jpegPng/about.png'
import BreadСrumbsHome from '../elements/breadСrumbsHome/BreadСrumbsHome';


class AboutUs extends React.Component {

   componentDidMount() {

      seo({
         title: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе',
         metaDescription: 'КрайПрокат-93 | Информация о нас | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе'
      });
   }

   render() {
      return (
         <div>
            <div className={`${c.imgContPrew}`} style={{ backgroundImage: `url(${fon})` }}>
               <div style={{ maxWidth: `1400px`, width: `100%`, margin: `0 auto` }}>
                  <h1 className='p2 tACenter' >
                     Информация о нас
                  </h1>
               </div>
            </div>
            <div className="main-cont">
               <div className={`pt1`}>
                  <BreadСrumbsHome>
                     Информация о нас
                  </BreadСrumbsHome>
               </div>
               <h2>О нас</h2>


               <AHContainer />
            </div>
            {/* <CContainer /> */}
         </div>
      )
   }
}



export default AboutUs
