import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import ConcretmixsPContainer from './page/ConcretmixsPContainer'

// import card from './card.module.scss'

class Concretmixs extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Бетономешалки
                </h1>
                <BreadCrumbs>Бетономешалки</BreadCrumbs>
                <ConcretmixsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Concretmixs
