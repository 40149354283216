import React from 'react'
import Requisites from '../../contacts/contact/requisites/Requisites'
import UlEl from '../../elements/ul/UlEl'
import Galerys from '../../../hook/Galerys';
import i from './aboutHotel.module.scss'
import foto1 from '../../../assets/images/jpegPng/galery1.jpg'
import foto2 from '../../../assets/images/jpegPng/galery2.jpg'
import foto3 from '../../../assets/images/jpegPng/galery3.jpg'
import foto4 from '../../../assets/images/jpegPng/galery4.jpg'
import foto5 from '../../../assets/images/jpegPng/galery5.jpg'
import foto6 from '../../../assets/images/jpegPng/galery6.jpg'
import foto7 from '../../../assets/images/jpegPng/galery7.jpg'
import foto8 from '../../../assets/images/jpegPng/galery8.jpg'
import foto9 from '../../../assets/images/jpegPng/galery9.jpg'
import foto10 from '../../../assets/images/jpegPng/galery10.jpg'
import foto11 from '../../../assets/images/jpegPng/galery11.jpg'
import foto12 from '../../../assets/images/jpegPng/galery12.jpg'
import foto13 from '../../../assets/images/jpegPng/galery13.jpg'
import foto14 from '../../../assets/images/jpegPng/galery14.jpg'
import foto15 from '../../../assets/images/jpegPng/galery15.jpg'
import foto16 from '../../../assets/images/jpegPng/galery16.jpg'
import foto17 from '../../../assets/images/jpegPng/galery17.jpg'
import foto18 from '../../../assets/images/jpegPng/galery18.jpg'
import foto19 from '../../../assets/images/jpegPng/galery19.jpg'


function AboutShop(props) {

    let galery = [
        { id: "1", img: `${foto1}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "2", img: `${foto2}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "3", img: `${foto3}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "4", img: `${foto4}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "5", img: `${foto5}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "6", img: `${foto6}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "7", img: `${foto7}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "8", img: `${foto8}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "9", img: `${foto9}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "10", img: `${foto10}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "11", img: `${foto11}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "12", img: `${foto12}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "13", img: `${foto13}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "14", img: `${foto14}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "15", img: `${foto15}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "16", img: `${foto16}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "17", img: `${foto17}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "18", img: `${foto18}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' },
        { id: "19", img: `${foto19}`, alt: 'КрайПрокат-93 | Информация о нас | Аренда строительного инструмента В Анапе | город Анапа | Прокат инструментов В Анапе' }
    ]

    let foto = galery.map(g =>
        <img className={`${i.image}`} src={g.img} alt={`Фото №${g.id} ${g.alt}`} key={g.id} />
    )


    return (
        <div>
            <p>
                Наша компания существует с 2016 года и оказывает услуги на рынке аренды спецтехники.
            </p>
            <p>
                Мы предлагаем :
            </p>
            <UlEl>
                <li>
                    аренду спецтехники самого разного назначения.
                </li>
            </UlEl>
            <p>
                За это время мы изучили нужды и предпочтения наших клиентов и можем предложить им только качественные услуги и товар, позволяющие решать поставленные задачи в короткий срок с наименьшими возможными затратами. Наша компания постоянно развивается и совершенствуется. В нашем автопарке современные машины различного направления. Мы предоставляем спектр наших услуг как частным лицам, так и крупным строительным организациям. За этот период у нас не возникло ни с одним заказчиком споров и разногласий.
            </p>
            <br />
            <p>
            При производстве строительных, отделочных или ремонтных работ очень часто возникает необходимость в использовании определенного электроинструмента и оборудования. А выполнение некоторых видов работ в принципе невозможно себе представить без использования электроинструмента.
            <br />
            Покупка строительного инструмента требует немалых вложений, а что делать, если он нужен для непродолжительного или вовсе однократного использования?
            <br />
            Компания «КрайПрокат-93» в г-к Анапа предлагает прокат и аренду строительного электроинструмента и оборудования
            </p>
            <br />
            <p>
                Наша компания - Ваш надежный партнер по снабжению инструментом и техникой. Мы сдаем в аренду строительное оборудование, снабжаем строительные площадки, осуществляем доставку на выгодных условиях. Свяжитесь с нами и мы обязательно найдем способ взаимовыгодно сотрудничать
            </p>
            {/* {aboutElements} */}
            <h3>Галерея магазина</h3>
            <Galerys>
                <div className={`dFlex justifyContentSpaceEvenly`}>
                    {foto}
                </div>
            </Galerys>
            <h3>Реквизиты</h3>
            <Requisites />
        </div>
    )

}



export default AboutShop
