import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import ExpendablesPContainer from './page/ExpendablesPContainer'

class Expendables extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Расходные Материалы
                </h1>
                <BreadCrumbs>Расходные Материалы</BreadCrumbs>
                <ExpendablesPContainer/>
                <BtnPrev />
            </>
        )
    }
}



export default Expendables
