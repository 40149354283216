import React from 'react'
import { connect } from 'react-redux'
import { getWoodsanders } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class InfoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getWoodsanders(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sWoodsanders={this.props.sWoodsanders}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sWoodsanders: state.subItemsPage.sWoodsanders
})

let WithUrlDataContainerComponent = withRouter(InfoContainer)

export default connect(mapStateToProps, { getWoodsanders })(WithUrlDataContainerComponent)
