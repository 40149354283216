import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import FormworkequsPContainer from './page/FormworkequsPContainer'

class Formworkequs extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Опалубочное оборудование
                </h1>
                <BreadCrumbs>Опалубочное оборудование</BreadCrumbs>
                <FormworkequsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Formworkequs
