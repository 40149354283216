import { NavLink } from "react-router-dom"




function BtnPrev(props) {
    return (
        <NavLink to='/categories'>
            <div className={`btns btn3`}>
                Назад в Каталог
            </div>
        </NavLink>
    )
}

export default BtnPrev