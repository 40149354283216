import Requisites from "../../contacts/contact/requisites/Requisites"
import UlEl from "../../elements/ul/UlEl"
import p from './elem.module.scss'
import money from '../../../assets/images/jpegPng/money.png'
import cart from '../../../assets/images/jpegPng/cart.png'
import translation from '../../../assets/images/jpegPng/perevod.png'




function PayEl(props) {
    return (
        <>
            <h2 className={`p1`}>
                Оплата
            </h2>
            <div>
                <div className="dFlex justifyContentSpaceBetween p2 mb2 boxDelivery" style={{ borderRadius: `10px` }}>
                    <div>
                        <h3 style={{fontSize:`1.1rem`}}>
                            Оплату можно осуществить<br />следующими способами:
                        </h3>
                        <UlEl>
                            <li className={`${p.liPayCont}`} style={{ paddingTop: `15px` }} >
                                Наличная оплата
                                <img src={money} alt="Наличная оплата"/>
                            </li>
                            <li className={`${p.liPayCont}`} style={{ paddingTop: `15px` }} >
                                Банковский перевод
                                <img src={translation} alt="Банковский перевод"/>
                            </li>
                            
                        </UlEl>

                        <p className="p1">
                            Ежедневно с 8:00 до 19:00
                        </p>
                    </div>
                    <div style={{ maxWidth: `300px` }}>
                        <h4 className={`${p.h4}`}>
                            Реквизиты
                        </h4>
                        <Requisites />
                    </div>
                </div>
            </div>
        </>
    )
}
export default PayEl