import { NavLink } from "react-router-dom"
import nav from '../topMenu.module.scss'
import React from 'react'
import logo from '../../../../assets/images/jpegPng/logo.png'

function Menu(props) {
    // if (!props.sStyle) { to="/about" style={{ fontSize: `0.9rem` }}>О нас
    //     return <div>Загрузка стилей меню...</div>to="/delivery" style={{ fontSize: `0.9rem` }}>Оплата и доставка
    // }to="/contacts" style={{ fontSize: `0.9rem` }}>Контакты
    let items = [
        {
            id: 1,
            link: '/categories',
            item: 'Категории'
        },
        {
            id: 2,
            link: '/service',
            item: 'Услуги'
        },
        {
            id: 3,
            link: '/delivery',
            item: 'Оплата'
        },
        {
            id: 4,
            link: '/contacts',
            item: 'Контакты'
        },
        {
            id: 5,
            link: '/about',
            item: 'О нас'
        }


    ]
    let menuElement = items.map(menu =>
        <NavLink className={`${nav.item} aos-init`}
            key={menu.id}
            to={menu.link}
            activeClassName={nav.activeLink}>
            <p className={nav.itemMenu}>{menu.item}</p>
        </NavLink>
    )
    return (
        <>
            <div className={props.active ?
                `${nav.navMenu}  
                 ${nav.active}` :
                `${nav.navMenu}
                `}

                onClick={() => props.setActive(false)}>
                <div className={`${nav.blur}`} />
                <nav style={{ color: `#fff` }} className={`${nav.menuContainerPhone}`} >
                    <NavLink to='/'>
                        <img className={`p05 pb0`} src={logo} alt='Главная' />
                    </NavLink>
                    <hr className={`${nav.hr}`} />
                    <div className={`pl1 dFlex`}>
                        <NavLink className={`${nav.item} aos-init menuItemNone`}
                            key='1'
                            to='/'>
                            <p className={nav.itemMenu}>Главная</p>
                        </NavLink>
                        {menuElement}{/*header__list header__link */}
                    </div>
                </nav>
            </div>

        </>
    )

}
export default Menu