import React from "react";
import '../footer.scss'

function Phone(props) {



    return (
        <div className='ml1 mr1' style={{}}>
            <a href="tel:+79892429393" style={{ color: `#fff`, textDecoration: `none` }}>
                +7 (989) 242-93-93
            </a>
        </div>
    )
}

export default Phone