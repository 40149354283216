import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getCompressors } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class CompressorsFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getCompressors(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sCompressors={this.props.sCompressors}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sCompressors: state.subItemsPage.sCompressors
})

let WithUrlDataContainerComponent = withRouter(CompressorsFPContainer)

export default connect(mapStateToProps, { getCompressors })(WithUrlDataContainerComponent)
