import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import TPContainer from './page/TPContainer'


class Thermals extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Тепловое оборудование
                </h1>
                <BreadCrumbs>Тепловое оборудование</BreadCrumbs>
                <TPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Thermals
