import {
    hotelApi
} from "../api/api"

const ROOM_RED = 'ROOM-RED'
const FULL_ROOM = 'FULL-ROOM'
const SET_ROOMS = 'SET-ROOMS'

let initionState = {
    roomsItem: [],
    sRoom: null,

}

const roomsReduscer = (state = initionState, action) => {

    switch (action.type) {
        case ROOM_RED: {
            return {
                ...state,
                roomsItem: [ /*...state.roomsItem,*/ ...action.roomsItem]
            }
        }
        case FULL_ROOM: {
            return {
                ...state,
                roomsItem: [ /*...state.roomsItem,*/ ...action.roomsItem]
            }
        }
        case SET_ROOMS: {
            return {
                ...state,
                sRoom: action.sRoom
            }
        }
        default:
            return state;
    }

}
export const roomAC = (roomsItem) => ({
    type: ROOM_RED,
    roomsItem
})

const setRooms = (sRoom) => ({
    type: SET_ROOMS,
    sRoom
})

export const getRoom = (linkMore) => (dispatch) => {
    hotelApi.getRoom(linkMore).then(response => {
        dispatch(setRooms(response.data))
    })
}
export default roomsReduscer;