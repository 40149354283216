import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import WeldingsPContainer from './page/WeldingsPContainer'

class Weldings extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Сварочное оборудование
                </h1>
                <BreadCrumbs>Сварочное оборудование</BreadCrumbs>
                <WeldingsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Weldings
