import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getSeamcutters } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class SeamCFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getSeamcutters(id)
debugger
   }

   render() {
      return (
         <Full
            {...this.props}
            sSeamcutters={this.props.sSeamcutters}
         />
      )
   }
}
let mapStateToProps = (state) => ({

   sSeamcutters: state.subItemsPage.sSeamcutters
})

let WithUrlDataContainerComponent = withRouter(SeamCFPContainer)

export default connect(mapStateToProps, { getSeamcutters })(WithUrlDataContainerComponent)
