import React from 'react'
import * as axios from "axios";
import { NavLink } from 'react-router-dom';
import is from '../../scss/itemsStyle.module.scss'

function VibroslatsPage(props) {
    if (props.vibroslatsItem.length === 0) {
        axios.get(`${process.env.REACT_APP_SERVER}vibroslats`).then(response => {
            props.vibroslatsHot(response.data)
        })
    }

    let element = props.vibroslatsItem.map(vp =>
        <div key={vp._id} className={`m1 ${is.cardCont}`} >
            <NavLink className='dFlex noWrap fDirect justifyContentSpaceBetween' style={{ width: `100%`, height: `100%` }} to={`vibroslats/${vp._id}`}>
                <div style={{ maxWidth: `300px`, width: `100%` }}>
                    <center> <img src={`${process.env.REACT_APP_SERVER}${vp.picture}`} alt={vp.title} />
                    </center>
                </div>
                <div className={`${is.textCont} pt1 pl1 pr1`}>
                    <p style={{ fontSize: `1rem`, color: `#000` }}>{vp.title}</p>
                </div>
                <hr className='m05' />
                <div className={`p05`} style={{ color: `#555`, fontSize: `0.8rem` }}>
                    {vp.hMin.map(p => <div key={p._id} className={`dFlex alignItemsCenter justifyContentSpaceBetween`}>
                        <b>
                            <p>{p.type}:</p>
                        </b>
                        <p>{p.name}</p>

                    </div>
                    )}
                </div>
                <hr className='m05' />
                <NavLink to={`vibroslats/${vp._id}`}>
                    <div className={`p05 tACenter`}>
                        <div className={`btns btn3`} >
                            Аренда от {vp.price}₽
                        </div>
                    </div>
                </NavLink>
            </NavLink>
        </div >

    )

    return (
        <div className='dFlex justifyContentCenter'>
            {element}
        </div>
    )
}

export default VibroslatsPage