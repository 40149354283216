import n from './next.module.scss'

function Prew(props) {
    const {className, style, onClick } = props;
    return (
      <div
        className={`${className} ${n.buttonPrev}`}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }
  export default Prew