import React from 'react'
import './App.scss';
import Error404 from './error/Error';

import ScrollToTop from '../hook/ScrollToTop';                          // scroll при переходе

import HeaderContainer from './header/HeaderContainer';                 // Header
import Routs from './Routs';                                            // Route
import FContainer from './footer/FContainer';                           // footer
import ScrollTop from './elements/scrollTop/ScrollTop';

function App(props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', }}>
            {/* Header */}
            <section className="header">
                <HeaderContainer />
            </section>
            <ScrollToTop>
                <div
                    style={{// maxWidth: `1400px`, width: `100%`, margin: `auto`
                    }}
                >    {/* scroll при переходе */}
                    <Routs />
                </div>
            
            {/* footer */}
            <section className="footer">
                <FContainer />
            </section>
            <ScrollTop/>
            </ScrollToTop>
        </div>
    );
}

export default App;
