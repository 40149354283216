
import ConcretmixsPage from './ConcretmixsPage'
import { connect } from "react-redux"
import { concretmixAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        concretmixItem: state.subItemsPage.concretmixItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        concretmixHot(concretmixItem) {
            dispatch(concretmixAC(concretmixItem))
        }
    }
}

export const ConcretmixsPContainer = connect(mapStateToProps, mapDispatchToProps)(ConcretmixsPage);
export default ConcretmixsPContainer;