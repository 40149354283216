import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import JHPContainer from './page/JHPContainer'

// import card from './card.module.scss'

class Jackhammers extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Отбойные молотки
                </h1>
                <BreadCrumbs>Отбойные молотки</BreadCrumbs>
                <JHPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Jackhammers
