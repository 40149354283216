import React from 'react'
import Err from './error.module.scss'


function Error404(props) {

    // debugger
    return (
        <div style={{width:'100%', height:'auto',padding:'180px 0 0 0', flex:'1 1 auto'}}>
нет страницы   
     </div>
    )
}



export default Error404
