import React from "react";
import '../footer.scss'

function Email(props) {

    return (
        <div className='ml1 mr1'>
            <a href="mailto:info@krayprokat93.ru" rel="noreferrer" target='_blank'>
                info@krayprokat93.ru
            </a>
        </div>
    )
}

export default Email