
import TilecuttersPage from './TilecuttersPage'
import { connect } from "react-redux"
import { tilecutterAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        tilecutterItem: state.subItemsPage.tilecutterItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        tilecutterHot(tilecutterItem) {
            dispatch(tilecutterAC(tilecutterItem))
        }
    }
}

export const TilecuttersPContainer = connect(mapStateToProps, mapDispatchToProps)(TilecuttersPage);
export default TilecuttersPContainer;