import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import VPPContainer from './page/VPPContainer'

class Vibroplit extends React.Component {

    componentDidMount() {


    }

    render() {

        return (
            <>
                <h1 className='p1'>
                    Виброплиты
                </h1>
                <BreadCrumbs>Виброплиты</BreadCrumbs>
                <VPPContainer />
                <BtnPrev/>
            </>
        )
    }
}



export default Vibroplit