import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getWashingequipment } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class WashingequsFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getWashingequipment(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sWashingequipment={this.props.sWashingequipment}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sWashingequipment: state.subItemsPage.sWashingequipment
})

let WithUrlDataContainerComponent = withRouter(WashingequsFPContainer)

export default connect(mapStateToProps, { getWashingequipment })(WithUrlDataContainerComponent)
