import VibLegs from './VibLegs'
import { connect } from "react-redux"
import {vibroLegsAC} from '../../../../redux/subItemsReduser'

let mapStateToProps = (state) => {

    return {
        vibroLegsItem: state.subItemsPage.vibroLegsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        vibroLegsHot(vibroLegsItem) {
            dispatch(vibroLegsAC(vibroLegsItem))
        }
    }
}

export const VLContainer = connect(mapStateToProps, mapDispatchToProps)(VibLegs);
export default VLContainer;