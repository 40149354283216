import PerforatorsPage from './PerforatorsPage'
import { connect } from "react-redux"
import { perforatorsAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        perforatorsItem: state.subItemsPage.perforatorsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        perforatorsHot(perforatorsItem) {
            dispatch(perforatorsAC(perforatorsItem))
        }
    }
}

export const PContainer = connect(mapStateToProps, mapDispatchToProps)(PerforatorsPage);
export default PContainer;