let initionState = {
    itemLogotype: [{
        id: 1,
        startLink:'/',
        logoImg: 'https://trip-advance.ru/images/wallp/log.ico',
        logoName:'«КОСМОС»',
        hotelName:'Демо ОТЕЛЬ «Космос»',
        hotelSlogan:'',
    }, ],
    itemsMenu: [
        // {
        //     id: 1,
        //     item: null,/*главная*/ 
        //     hrefLink: '/',
        // },
        {
            id: 2,
            item: 'Категории',
            hrefLink: '/categories',
        },
        // {
        //     id: 3,
        //     item: 'Услуги',
        //     hrefLink: '/services',
        // },
        {
            id: 4,
            item: 'Доставка',
            hrefLink: '/promotions',
        },
        {
            id: 5,
            item: 'Об организации',
            hrefLink: '/about_us',
        },
        {
            id: 6,
            item: 'Контакты',
            hrefLink: '/contacts',
        },
        
        // {
        //     id: 7,
        //     item: 'Бронирование',
        //     hrefLink: '/booking?adults=2',
        // },
    ],
    itemsSocialContacts: [{
            id: 1,
            socLink: 'tel:+79892429393',
            phoneNomber: '+7 (989) 242-93-93',
            text:'связаться с менеджером'
        }
        // {
        //     id: 2,
        //     socImg: 'https://trip-advance.ru/images/icons/phone-new.svg',
        //     phoneLink: 'https://wa.me/<phone>',
        //     phoneNomber: '',
        //     // Вариант WhatsApp
        // },
        // {
        //     id: 3,
        //     socImg: '',
        //     phoneLink: 'https://telegram.me/<USERNAME>',
        //     phoneNomber: '',
        // },
        //     // Вариант telegram
    ],
    itemSocNet:[
        {
            id:1,
            style:'green',
            image:
                {
                    path:[
                        {
                            id:1,
                            path:'M168.5,378.294c22.799,12.436,48.468,18.99,74.592,18.999h0.064c86.027,0,156.056-70.025,156.091-156.089   c0.016-41.707-16.205-80.924-45.675-110.427C324.102,101.273,284.91,85.017,243.154,85c-86.035,0-156.058,70.018-156.092,156.08   c-0.012,27.511,7.176,54.363,20.836,78.035L85.753,400L168.5,378.294z M243.207,111.363c34.653,0.012,67.227,13.525,91.722,38.047   c24.494,24.522,37.976,57.118,37.963,91.784c-0.029,71.536-58.229,129.737-129.736,129.737h-0.053   c-23.28-0.009-46.113-6.263-66.032-18.084l-4.737-2.812l-49.103,12.881l13.106-47.875l-3.085-4.909   c-12.987-20.656-19.846-44.529-19.836-69.044C113.445,169.559,171.645,111.363,243.207,111.363z'
                        },
                        {
                            id:2,
                            path:'M246.394,305.652c9.305,4.019,16.57,6.418,22.234,8.215c9.343,2.969,17.846,2.55,24.566,1.546   c7.493-1.119,23.075-9.434,26.326-18.544c3.249-9.112,3.249-16.921,2.274-18.547c-0.975-1.627-3.575-2.603-7.475-4.556   c-3.9-1.952-23.075-11.386-26.65-12.689c-3.575-1.301-6.175-1.951-8.775,1.952c-2.6,3.904-10.074,12.69-12.35,15.293   c-2.275,2.602-4.55,2.929-8.449,0.976c-3.9-1.952-16.467-6.07-31.364-19.358c-11.595-10.342-19.422-23.114-21.697-27.018   c-2.275-3.904-0.243-6.015,1.71-7.959c1.755-1.748,3.901-4.556,5.85-6.833c1.95-2.277,2.6-3.905,3.9-6.506   c1.3-2.604,0.65-4.881-0.325-6.833c-0.975-1.952-8.775-21.15-12.025-28.96c-3.166-7.604-6.381-6.574-8.775-6.695   c-2.272-0.113-4.875-0.137-7.475-0.137c-2.6,0-6.826,0.976-10.4,4.88c-3.575,3.905-13.65,13.341-13.65,32.537   c0,19.198,13.975,37.743,15.925,40.346C181.717,249.364,207.269,288.758,246.394,305.652z'
                        },
                        {
                            id:3,
                            path:'M0,0v485h485V0H0z M455,455H30V30h425V455z'
                        },

                    ],
                    width:'32',
                    height:'32',
                    x:'0',
                    y:'0',
                    viewBox:'0 0 500 500'
                }
            ,
            link:'https://wa.me/79892429393',
            text:'whatsapp'
        },
        {
            id:2,
            style:'deep-purple',
            image:
                {
                    path:[
                        {
                            id:1,
                            path:'M180.428,304.197c36.332,36.332,80.711,64.548,132.199,85.521c35.751,14.563,65.944-0.643,78.29-37.357   c2.216-6.591,2.37-13.043-0.014-19.522c-5.76-15.657-60.331-55.305-76.661-56.194c-12.523-0.681-19.306,7.829-25.037,16.275   c-10.838,15.953-24.576,16.042-39.899,9.224c-15.419-6.862-28.91-15.662-40.037-26.79c-11.127-11.127-19.928-24.618-26.79-40.037   c-6.818-15.323-6.729-29.061,9.225-39.898c8.446-5.731,16.955-12.514,16.274-25.037c-0.889-16.329-40.537-70.901-56.194-76.66   c-6.479-2.384-12.931-2.229-19.522-0.014c-36.714,12.346-51.92,42.54-37.357,78.29C115.88,223.486,144.096,267.865,180.428,304.197   z'
                        },
                        {
                            id:2,
                            path:'M251.163,112.035c8.003-1.27,16.22-1.554,24.42-0.841c25.271,2.19,49.319,13.528,67.715,31.924   c17.844,17.843,28.819,40.917,30.905,64.971c0.712,8.217,0.366,16.456-1.028,24.484c-0.993,5.714,2.835,11.15,8.548,12.142   c0.608,0.105,1.213,0.157,1.81,0.157c5.01,0,9.445-3.6,10.332-8.705c1.705-9.813,2.129-19.87,1.261-29.892   c-2.513-28.971-15.645-56.674-36.978-78.007c-21.886-21.885-50.563-35.379-80.75-37.997c-9.903-0.858-19.837-0.515-29.523,1.022   c-5.728,0.909-9.634,6.288-8.726,12.016C240.056,109.036,245.429,112.942,251.163,112.035z'
                        },
                        {
                            id:3,
                            path:'M348.181,235.643c0.449,0.057,0.895,0.084,1.337,0.084c5.209,0,9.729-3.875,10.402-9.178   c0.722-5.679,0.834-11.482,0.335-17.25c-1.803-20.777-11.341-40.765-26.857-56.281c-16.068-16.069-37.029-25.97-59.022-27.877   c-6.19-0.535-12.405-0.415-18.474,0.36c-5.752,0.735-9.819,5.994-9.084,11.747c0.734,5.753,6.009,9.815,11.746,9.084   c4.587-0.585,9.298-0.676,13.997-0.269c17.076,1.48,33.408,9.224,45.987,21.804c12.028,12.027,19.409,27.385,20.785,43.245   c0.371,4.285,0.288,8.588-0.245,12.791C338.357,229.656,342.428,234.912,348.181,235.643z'
                        },
                        {
                            id:4,
                            path:'M314.272,229.411c0.242,0.017,0.483,0.025,0.724,0.025c5.474,0,10.088-4.245,10.465-9.787   c0.164-2.402,0.139-4.866-0.074-7.327c-2.333-26.909-26.067-49.887-54.034-52.312c-2.282-0.197-4.567-0.25-6.796-0.159   c-5.795,0.239-10.298,5.13-10.059,10.924s5.137,10.29,10.924,10.058c1.339-0.055,2.725-0.022,4.117,0.099   c17.503,1.517,33.497,16.723,34.926,33.203c0.12,1.386,0.135,2.761,0.045,4.087C304.115,224.008,308.486,229.018,314.272,229.411z'
                        },
                        {
                            id:5,
                            path:'M0,0v485h485V0H0z M455,455H30V30h425V455z'
                        },

                    ],
                    width:'32',
                    height:'32',
                    x:'0',
                    y:'0',
                    viewBox:'0 0 500 500'
                }
            ,
            link:'https://viber.click/79892429393',
            text:'viber'
        },
        // {
        //     id:3,
        //     image:
        //         {
        //             path:[
        //                 {
        //                     id:1,
        //                     path:'M90.564,236.876l72.583,27.089l28.094,90.351c1.798,5.784,8.874,7.923,13.569,4.083l40.46-32.981   c4.24-3.458,10.282-3.628,14.712-0.412l72.975,52.981c5.023,3.65,12.142,0.899,13.401-5.172l53.458-257.136   c1.375-6.632-5.139-12.164-11.454-9.721L90.48,220.873C83.128,223.706,83.191,234.114,90.564,236.876z M186.713,249.546   l141.854-87.367c2.548-1.565,5.172,1.882,2.981,3.913L214.479,274.913c-4.116,3.829-6.77,8.957-7.522,14.522l-3.986,29.553   c-0.531,3.945-6.071,4.335-7.162,0.517l-15.337-53.891C178.717,259.468,181.276,252.901,186.713,249.546z'
        //                 },
        //                 {
        //                     id:2,
        //                     path:'M0,0v485h485V0H0z M455,455H30V30h425V455z'
        //                 },

        //             ],
        //             width:'32',
        //             height:'32',
        //             x:'0',
        //             y:'0',
        //             viewBox:'0 0 500 500'
        //         }
        //     ,
        //     link:'https://telegram.com',
        //     text:'telegram'
        // },
        // {
        //     id:4,
        //     image:
        //         {
        //             path:[
        //                 {
        //                     id:1,
        //                     path:'M124.536,347.92c39.056,4.353,78.587,4.382,117.948,4.353c39.377,0.029,78.909,0,117.952-4.353   c16.494-1.841,30.195-14.21,34.057-30.96c5.491-23.846,5.507-49.9,5.507-74.462c0-24.562,0.049-50.603-5.446-74.45   c-3.857-16.766-17.559-29.118-34.057-30.959c-39.056-4.365-78.591-4.382-117.948-4.365c-39.361-0.016-78.909,0-117.969,4.365   c-16.477,1.841-30.183,14.193-34.024,30.959C85.065,191.896,85,217.937,85,242.499c0,24.562,0,50.615,5.495,74.462   C94.336,333.711,108.038,346.079,124.536,347.92z M201.855,187.23c33.374,17.416,66.54,34.707,100.138,52.237   c-33.5,17.368-66.67,34.577-100.138,51.932V187.23z'
        //                 },
        //                 {
        //                     id:2,
        //                     path:'M0,0v485h485V0H0z M455,455H30V30h425V455z'
        //                 },

        //             ],
        //             width:'32',
        //             height:'32',
        //             x:'0',
        //             y:'0',
        //             viewBox:'0 0 500 500'
        //         }
        //     ,
        //     link:'https://youtube.com',
        //     text:'youtube'
        // },
        // {
        //     id:5,
        //     style:'pink',
        //     image:
        //         {
        //             path:[
        //                 {
        //                     id:1,
        //                     path:'M120.89,400c81.071,0,162.193,0,243.264,0c1.385,0,2.769-0.102,4.166-0.254c17.807-2.083,31.651-17.667,31.651-35.576   c0-81.071,0-162.18,0-243.251c0-1.042,0.051-2.083-0.102-3.124C397.938,99.035,382.405,85,363.505,85   c-80.626,0-161.252,0-241.917,0.051c-2.185,0-4.42,0.139-6.554,0.546c-17.857,3.417-30.012,18.201-30.012,36.363v120.61   c0,40.085,0,80.22,0,120.317c0,2.032,0.152,4.115,0.445,6.147C87.803,386.448,103.286,400,120.89,400z M303.125,133.468   c0.051-6.948,5.411-12.053,12.396-12.104c11.964,0,23.865,0,35.83,0c7.087,0.051,12.345,5.258,12.345,12.409   c0.051,11.901,0.051,23.853,0,35.767c0,7.189-5.258,12.409-12.447,12.409h-17.858c-5.957,0-11.964,0-17.959,0   c-6.897,0-12.256-5.169-12.307-12.066C303.074,157.727,303.074,145.623,303.125,133.468z M242.49,181.948   c33.493,0,60.584,27.028,60.635,60.521c0,33.493-27.041,60.584-60.534,60.635c-33.531,0-60.673-27.143-60.622-60.686   C182.02,209.077,209.111,181.948,242.49,181.948z M121.385,219.797v-1.384h27.345c-6.109,24.653-3.328,48.264,8.777,70.592   c8.827,16.321,21.477,28.971,37.608,38.104c31.905,18.01,71.596,16.27,101.951-4.458c16.829-11.52,28.933-26.85,36.173-45.952   c7.188-19.052,8.091-38.498,3.074-58.336h27.383c0,0.546,0,1.042,0,1.588v130.529c0,8.192-4.954,13.197-13.146,13.197H148.679   c-4.865,0-9.678,0.051-14.543,0c-7.583,0-12.752-5.156-12.752-12.752C121.385,307.218,121.385,263.501,121.385,219.797z'
        //                 },
        //                 {
        //                     id:2,
        //                     path:'M0,0v485h485V0H0z M455,455H30V30h425V455z'
        //                 },

        //             ],
        //             width:'32',
        //             height:'32',
        //             x:'0',
        //             y:'0',
        //             viewBox:'0 0 500 500'
        //         }
        //     ,
        //     link:'https://instagram.com',
        //     text:'instagram'
        // },
    ]
}

const headerReduscer = (state = initionState, action) => {
    return state;
}

export default headerReduscer;