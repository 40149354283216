
import EquipaircondsPage from './EquipaircondsPage'
import { connect } from "react-redux"
import { airconditionAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        airconditionItem: state.subItemsPage.airconditionItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        airconditionHot(airconditionItem) {
            dispatch(airconditionAC(airconditionItem))
        }
    }
}

export const EquipaircondsPContainer = connect(mapStateToProps, mapDispatchToProps)(EquipaircondsPage);
export default EquipaircondsPContainer;