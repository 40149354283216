import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getConcretmix } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class ConcretmixsFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getConcretmix(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sConcretmix={this.props.sConcretmix}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sConcretmix: state.subItemsPage.sConcretmix
})

let WithUrlDataContainerComponent = withRouter(ConcretmixsFPContainer)

export default connect(mapStateToProps, { getConcretmix })(WithUrlDataContainerComponent)
