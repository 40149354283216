
import BulgariansPage from './BulgariansPage'
import { connect } from "react-redux"
import { bulgarianAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        bulgarianItem: state.subItemsPage.bulgarianItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        bulgarianHot(bulgarianItem) {
            dispatch(bulgarianAC(bulgarianItem))
        }
    }
}

export const BulgariansPContainer = connect(mapStateToProps, mapDispatchToProps)(BulgariansPage);
export default BulgariansPContainer;