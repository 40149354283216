import { connect } from "react-redux"
import { serviceAC } from "../../../../redux/servicesReduser"
import Service from './Service'

let mapStateToProps = (state) => {

    return {
        serviceItems: state.servicesPage.serviceItems,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        serviceHot(serviceItems) {
            dispatch(serviceAC(serviceItems))
        }
    }
}

export const SContainer = connect(mapStateToProps, mapDispatchToProps)(Service);
export default SContainer;