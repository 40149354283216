import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import JigsawsPContainer from './page/JigsawsPContainer'

class Jigsaws extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Бензопилы. Лобзики. Торцевые пилы
                </h1>
                <BreadCrumbs>Бензопилы. Лобзики. Торцевые пилы</BreadCrumbs>
                <JigsawsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Jigsaws
