
import SportequsPage from './SportequsPage'
import { connect } from "react-redux"
import { sportequipmentAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        sportequipmentItem: state.subItemsPage.sportequipmentItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        sportequipmentHot(sportequipmentItem) {
            dispatch(sportequipmentAC(sportequipmentItem))
        }
    }
}

export const SportequsPContainer = connect(mapStateToProps, mapDispatchToProps)(SportequsPage);
export default SportequsPContainer;