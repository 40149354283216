import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import GPContainer from './page/GPContainer'


class Generators extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Генераторы, стабилизаторы
                </h1>
                <BreadCrumbs>Генераторы, стабилизаторы</BreadCrumbs>
                <GPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Generators
