import React from "react";
import { connect } from 'react-redux'
// import { getStyle } from '../../redux/styleReducer'
import Header from "./Header";


class HeaderContainer extends React.Component {
    
    componentDidMount() {
            // this.props.getStyle()      
    }

    render() {

        return (
            <>
                <Header {...this.props} 
                // sStyle={this.props.sStyle}
                // styleItems={this.props.styleItems}
                />
            
            </>
        )
    }
}
let mapStateToProps = (state) => ({
    // sStyle: state.styleModule.sStyle,
    // styleItems: state.styleModule.styleItems
})

export default connect(mapStateToProps)(HeaderContainer)