import React from "react";
import { connect } from 'react-redux'
import { getСategory } from '../../redux/categoriesReduser'
// import { getBasement } from '../../redux/footerReduser'
import Footer from "./Footer";


class FContainer extends React.Component {

    componentDidMount() {
        this.props.getСategory()
    }

    render() {

        return (
            <>
                <Footer
                    {...this.props}
                    sСategory={this.props.sСategory}
                    //
                    getСategory={this.props.getСategory}
                    // basementItems={this.props.basementItems}
                />
            </>
        )
    }
}
let mapStateToProps = (state) => ({
    sСategory: state.categoriesPage.sСategory
    // basementItems: state.footerModule.basementItems,
    // itemsMenu: state.headerPage.itemsMenu,
    
})
  
export default connect(mapStateToProps, {
    getСategory

})(FContainer)







// import { connect } from "react-redux"
// import { styleAC } from "../../redux/styleReducer";
// import Footer from "./Footer";



// let mapStateToProps = (state) => {

//     return {
//         styleItems: state.styleModule.styleItems,
//         itemsMenu: state.headerPage.itemsMenu
//     }
// }
// let mapDispatchToProps = (dispatch) => {
//     return {

//         styleHot(styleItems) {
//             dispatch(styleAC(styleItems))
//         }

//     }
// }
// export const HContainer = connect(mapStateToProps, mapDispatchToProps)(Footer);
// export default HContainer;