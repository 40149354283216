import React from "react";
import { connect } from 'react-redux'
import AboutShop from "./AboutShop";


class AHContainer extends React.Component {

    componentDidMount() {
        // this.props.getStyle()
    }

    render() {

        return (
            <>
                <AboutShop
                    // {...this.props}
                    // sStyle={this.props.sStyle}
                />
            </>
        )
    }
}

let mapStateToProps = (state) => ({
    // sStyle: state.styleModule.sStyle,
})
// let mapDispatchToProps = (dispatch) => {
//         return {
//             aboutHot(aboutUsItems) {
//                 dispatch(aboutAC(aboutUsItems))
//             }
//         }
//     }

export default connect(mapStateToProps,/*mapDispatchToProps,*/ {
    // getStyle
})(AHContainer)

