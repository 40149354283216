import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import SMPContainer from './page/SMPContainer'

class SeamCutters extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Штроборезы ручные, Швонарезчики
                </h1>
                <BreadCrumbs>Штроборезы ручные, Швонарезчики</BreadCrumbs>
                <SMPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default SeamCutters
