import React from 'react';
import { NavLink } from 'react-router-dom';



function BreadCrumbsService(props) {

    return (
        <div className='pl1'>
            <NavLink to='/' style={{ fontSize: `0.8rem`, color: `#a9a9a9` }}>
                Главная
            </NavLink> <span style={{ fontSize: `0.8rem`, color: `#a9a9a9` }}> / </span>
            <NavLink to='/service' style={{ fontSize: `0.8rem`, color: `#a9a9a9` }}>
                Услуги
            </NavLink> <span style={{ fontSize: `0.8rem`, color: `#a9a9a9` }}> / {props.children} </span>
        </div>
    )
}

export default (BreadCrumbsService);