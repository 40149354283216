
import FrasersPage from './FrasersPage'
import { connect } from "react-redux"
import { frasersAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        frasersItem: state.subItemsPage.frasersItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        frasersHot(frasersItem) {
            dispatch(frasersAC(frasersItem))
        }
    }
}

export const WeldingsPContainer = connect(mapStateToProps, mapDispatchToProps)(FrasersPage);
export default WeldingsPContainer;