import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getAircondition } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class EquipaircondsFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getAircondition(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sAircondition={this.props.sAircondition}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sAircondition: state.subItemsPage.sAircondition
})

let WithUrlDataContainerComponent = withRouter(EquipaircondsFPContainer)

export default connect(mapStateToProps, { getAircondition })(WithUrlDataContainerComponent)
