import Aos from 'aos';
import 'aos/dist/aos.css'
import React, { useEffect } from 'react';

function AOS({ children }) {

useEffect(()=>{
    Aos.init({
        once : true
    },[])
})

  return (
        <>
            {children}
        </>
    )
}

export default (AOS);

// AOS . init ( ) ;

// // Вы также можете передать необязательный объект настроек 
// // ниже перечисленных настроек по умолчанию 
// AOS . init ( { 
//   // Глобальные настройки: 
//   disable : false ,  // принимают следующие значения: 'phone', 'tablet', 'mobile', boolean, expression или function 
//   startEvent : 'DOMContentLoaded' ,  // имя события, отправленного на документ, что AOS должен инициализироваться в 
//   initClassName : 'aos-init' ,  // класс, применяемый после инициализации 
//   animatedClassName : 'aos-animate' ,  // класс, применяемый к анимации 
//   useClassNames :false ,  // если true, добавит содержимое `data-aos` в качестве классов при прокрутке 
//   disableMutationObserver : false ,  // отключает автоматическое обнаружение мутаций (дополнительно) 
//   debounceDelay : 50 ,  // задержка при 
//   устранении неполадок , используемая при изменении размера окна (дополнительно ) throttleDelay : 99 ,  // задержка газа, используемая при прокрутке страницы (дополнительно)
  
//   // Настройки, которые можно переопределить для каждого элемента с помощью атрибутов data-aos- *: 
//   offset : 120 ,  // смещение (в пикселях) от исходной точки срабатывания 
//   delay : 0 ,  // значения от 0 до 3000 , с шагом 50 мс 
//   duration : 400 ,  // значения от 0 до 3000, с шагом 50 мс 
//   easing : 'легкость' ,  // замедление по умолчанию для анимации AOS 
//   once : false ,  // должна ли анимация выполняться только один раз - при прокрутке 
//   mirror : false ,  // должны ли элементы анимироваться при прокрутке мимо них
//   anchorPlacement : 'top-bottom' ,  // определяет, какая позиция элемента относительно окна должна запускать анимацию

// } ) ;

/* <div
data-aos="fade-up"
data-aos-offset="200"
data-aos-delay="50"
data-aos-duration="1000"
data-aos-easing="ease-in-out"
data-aos-mirror="true"
data-aos-once="false"
data-aos-anchor-placement="top-center"
>
</div> */

/*
Fade animations:    Затухание анимации:

fade                тускнеть
fade-up             растворяться
fade-down           угасание
fade-left           исчезать влево
fade-right          исчезать вправо
fade-up-right       исчезать-вверх-вправо
fade-up-left        исчезать-вверх-влево
fade-down-right     плавно-вниз-вправо
fade-down-left      исчезать-вниз-влево



Flip animations:   Переворот анимации: 

flip-up             перевернуть
flip-down           перевернутый
flip-left           перевернуть влево
flip-right          перевернуть вправо








*/