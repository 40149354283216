import React from "react";
import { connect } from 'react-redux'
// import { getStyle } from '../../../redux/styleReducer'
// import { getBasement } from '../../redux/footerReduser'
import SocNetwork from "./SocNetwork";


class SNFContainer extends React.Component {

    componentDidMount() {
        // this.props.getStyle()
        // this.props.getBasement()
    }

    render() {

        return (
            <>
                <SocNetwork
                    {...this.props}
                    // sStyle={this.props.sStyle}
                    // sBasement={this.props.sBasement}
                    itemSocNet={this.props.itemSocNet}
                    // styleItems={this.props.styleItems}
                />
            </>
        )
    }
}
let mapStateToProps = (state) => ({
    // sStyle: state.styleModule.sStyle,
    // sBasement: state.footerModule.sBasement,
    itemSocNet: state.headerPage.itemSocNet,
    // styleItems: state.styleModule.styleItems
    
})
  
export default connect(mapStateToProps, {
    // getStyle,
    // getBasement

})(SNFContainer)

