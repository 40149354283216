import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getWoodsanders } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class WoodsandersFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getWoodsanders(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sWoodsanders={this.props.sWoodsanders}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sWoodsanders: state.subItemsPage.sWoodsanders
})

let WithUrlDataContainerComponent = withRouter(WoodsandersFPContainer)

export default connect(mapStateToProps, { getWoodsanders })(WithUrlDataContainerComponent)
