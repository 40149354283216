import React from 'react'
import { connect } from 'react-redux'
import { getMeasuring } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class InfoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getMeasuring(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sMeasuring={this.props.sMeasuring}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sMeasuring: state.subItemsPage.sMeasuring
})

let WithUrlDataContainerComponent = withRouter(InfoContainer)

export default connect(mapStateToProps, { getMeasuring })(WithUrlDataContainerComponent)
