import React from "react";
import { connect } from 'react-redux'
import { getСategory } from '../../redux/categoriesReduser'
import CategPage from "./CategPage";
import { seo } from '../../hook/seo'



class CategContainer extends React.Component {

    componentDidMount() {
        this.props.getСategory()
        seo({
            title: 'КрайПрокат-93 | Каталог инструментов | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе',
            metaDescription: 'КрайПрокат-93 | Каталог инструментов | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе'
         });
    }

    render() {
        return (
            <>
                <CategPage
                    {...this.props}
                    sСategory={this.props.sСategory}
                />
            </>
        )
    }
}

let mapStateToProps = (state) => ({
    sСategory: state.categoriesPage.sСategory,

})


export default connect(mapStateToProps, {
    getСategory
})(CategContainer)


// import { connect } from "react-redux"
// import AboutHotel from "./AboutHotel"
// import { aboutAC } from "../../../redux/aboutUsReducer"


// let mapStateToProps = (state) => {

//     return {
//          aboutUsItems: state.aboutUsPage.aboutUsItems,
//     }
// }
// let mapDispatchToProps = (dispatch) => {
//     return {
//         aboutHot(aboutUsItems) {
//             dispatch(aboutAC(aboutUsItems))
//         }
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(AboutHotel);