import React from 'react'
import imgsr from '../../../assets/images/svg/more.svg'
import hour from '../../../assets/images/svg/hour-glass.svg'
import pay from '../../../assets/images/svg/pay.svg'


function StickLabel(props) {
    let stickItems = [
        {
            "id": 1,
            "title": "Более 300",
            "text": "наименований оборудования",
            "img": `${imgsr}`
        },
        {
            "id": 2,
            "title": "Доставка",
            "text": "быстрая, до места",
            "img": `${hour}`
        },
        {
            "id": 4,
            "title": "Оплата",
            "text": "с НДС и без",
            "img": `${pay}`
        },
    ]
    let itemsStickLabel = stickItems.map(item => <div key={item.id} className="promotion__cont_items">
        <div className="promotion_block"></div>
        <div className="promotion__cont_itemsImage">
            <img src={item.img} alt={item.title} />
        </div>
        <div className="promotion__cont_itemsText">
            
            <h3>
                {item.title}
            </h3>
            <p>
                {item.text}
            </p>
        </div>
    </div>

    )
    return (
        <>
            <div className="promotion__cont flexBox">
                {itemsStickLabel}
            </div>
        </>
    )

}

export default StickLabel