
import DrillsPage from './DrillsPage'
import { connect } from "react-redux"
import { drillsAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        drillsItem: state.subItemsPage.drillsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        drillsHot(drillsItem) {
            dispatch(drillsAC(drillsItem))
        }
    }
}

export const DrillsPContainer = connect(mapStateToProps, mapDispatchToProps)(DrillsPage);
export default DrillsPContainer;