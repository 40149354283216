import React from 'react'
import { NavLink } from 'react-router-dom'
import serv from './service.module.scss'
import logo from '../../../../assets/images/jpegPng/logoOld.png'
import * as axios from "axios";

function Service(props) {

    if (props.serviceItems.length === 0) {
        axios.get(`${process.env.REACT_APP_SERVER}services`).then(response => {
            props.serviceHot(response.data)
        })
    }
let serviceItems = [
    {
        "about": {

            "mini": [
                {
                    "id": "1",
                    "title": "Прочистка канализации",
                    "text": "Ищите услуги прочистка канализации в Анапе? Лучше всего сразу нанять профессионалов."
                }
            ]
        },
        "_id": "6gght233s",
        "title": "Прочистка канализации",
        "picture": "https://cdn-ru.bitrix24.ru/b17255068/landing/0e5/0e5af2a554766dea8452ab478834ca30/title3_1x.jpg"
    }
]

    let cardService = props.serviceItems.map(l =>
        <div className={`${serv.container} m05 dFlex`}>
            <div className={`${serv.header}`}>
                <NavLink to={`/service/${l._id}`}>
                    <div className={`${serv.headerFon} dFlex`} style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER}${l.picture})` }}>
                        <h2 className={`${serv.titleHeader} pl1 pr1`}>{l.title}</h2>
                    </div>
                </NavLink>
            </div>
            <div className={`${serv.body} dFlex p05`}>
                {l.about.mini.map(m =>
                    <div key={m.id}>
                        <h3 className={`${serv.titleBody}`}>{l.title}</h3>
                        <p>
                            {m.text}
                        </p>
                    </div>
                )}

                <NavLink to={`/service/${l._id}`}>
                    <div className={`btns btn3 mt05 mr05 ml05`} style={{ minHeight: `37px` }}>
                        Подробнее...
                    </div>
                </NavLink>
            </div>
            <hr className={`${serv.hr}`} />
            <div className={`${serv.footer} dFlex justifyContentCenter alignItemsCenter mb05`}>
                <img src={`${logo}`} alt="КрайПрокат93 | Аренда инструментов в Анапе" style={{ width: `90%` }} />
            </div>
        </div>
    )
    let cardService2 = serviceItems.map(l =>
        <div className={`${serv.container} m05 dFlex`}>
            <div className={`${serv.header}`}>
                <NavLink to={`/serv/${l._id}`}>
                    <div className={`${serv.headerFon} dFlex`} style={{ backgroundImage: `url(${l.picture})` }}>
                        <h2 className={`${serv.titleHeader} pl1 pr1`}>{l.title}</h2>
                    </div>
                </NavLink>
            </div>
            <div className={`${serv.body} dFlex p05`}>
                {l.about.mini.map(m =>
                    <div key={m.id}>
                        <h3 className={`${serv.titleBody}`}>{l.title}</h3>
                        <p>
                            {m.text}
                        </p>
                    </div>
                )}

                <NavLink to={`/serv/${l._id}`}>
                    <div className={`btns btn3 mt05 mr05 ml05`} style={{ minHeight: `37px` }}>
                        Подробнее...
                    </div>
                </NavLink>
            </div>
            <hr className={`${serv.hr}`} />
            <div className={`${serv.footer} dFlex justifyContentCenter alignItemsCenter mb05`}>
                <img src={`${logo}`} alt="КрайПрокат93 | Аренда инструментов в Анапе" style={{ width: `90%` }} />
            </div>
        </div>
    )

    return (
        <div className={`${serv.contServ} dFlex`}>
            {cardService}
            {
                cardService2
            }
        </div>
    )
}



export default Service
