import React from 'react'
import Requisites from './requisites/Requisites'
import c from './contact.module.scss'
function Contact(props) {



   // const config = {
   //    api: `${process.env.REACT_APP_API}`,
   //    title: 'Задайте вопрос',
   //    description: 'Напишите нам о своем запросе, мы свяжемся с вами в течение 24 часов.',
   //    successMessage: 'Спасибо за ваше сообщение.',
   //    errorMessage: 'Заполните корректно все поля',
   //    fields: {
   //       firstName: '',
   //       lastName: '',
   //       email: '',
   //       msg: ''
   //    },
   //    fieldsConfig: [
   //       { id: 1, label: 'First Name', fieldName: 'firstName', type: 'text', placeholder: 'Your First Name', isRequired: true, klassName: 'first-name-field' },
   //       { id: 2, label: 'Last Name', fieldName: 'lastName', type: 'text', placeholder: 'Your Last Name', isRequired: true, klassName: 'last-name-field' },
   //       { id: 3, label: 'Email', fieldName: 'email', type: 'email', placeholder: ' Your Email', isRequired: true, klassName: 'email-field' },
   //       { id: 4, label: 'Message', fieldName: 'msg', type: 'textarea', placeholder: 'Write something.....', isRequired: true, klassName: 'message-field' }
   //    ]
   // }


   let contactElement = props.itemsContact.map(cnt => <div className={`${c.allContainer}`}>
<div className={`${c.elem}`}></div>
      <a target="_blank" rel="noreferrer" href={cnt.contact.link} >
         <div className={` ${c.containerImg}`}>
            <div className={` ${c.img}`}>
               <svg
                  className={`blue-fill fill-lighten-1`}
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  viewBox={cnt.contact.viewBox}
                  x={cnt.contact.x}
                  y={cnt.contact.y}
                  width={cnt.contact.width}
                  height={cnt.contact.height}
                  enableBackground={`new ${cnt.contact.viewBox}`}>
                  <g>
                     {cnt.contact.path.map((g) =>
                        (<path key={g.id} d={`${g.d}`} />)
                     )}
                  </g>
               </svg>
            </div>
            <h3 className={`blue-text text-lighten-1 `}>
               {cnt.contact.about}
            </h3>
         </div>
      </a>
      <a href={cnt.contact.link} >
         <div className={`blue-text text-lighten-1 ${c.containerText}`}>
            {cnt.contact.text}
         </div>
      </a>
   </div>

   )
   // let mapsElement = props.itemsMap.map(map => <div key={map.id}>
   //    <iframe className={mapS.mapIframe} title={map.mapTitle} src={map.mapLink} frameBorder="0"></iframe>
   // </div>

   // )

   return (
      <div style={{ padding: '1rem 0' }}>
         <div className="main-cont">
            <div>
               <iframe title='Карта' src="https://yandex.ru/map-widget/v1/-/CCUNJZBLWD" width="100%" height="400" frameBorder="1" style={{ position: `relative` }} />
            </div>
            <div>
               <div>
                  <div style={{ width: `100%` }} >
                     <h3 style={{ padding: '10px' }}>Координаты для GPS навигаторов: 44.899805, 37.365083</h3>
                     <div className={`dFlex justifyContentSpaceEvenly`}>
                        {contactElement}
                     </div>

                  </div>

               </div>
               {/* <FForm /> */}
               <div style={{ width: `100%` }} className={``}>
                  <h3>
                     РЕКВИЗИТЫ
                  </h3>
                  <Requisites />
               </div>
            </div>
         </div>
      </div>
   )
}



export default Contact
