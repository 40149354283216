import React from 'react'
import { connect } from 'react-redux'
import { getAircondition } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class InfoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getAircondition(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sAircondition={this.props.sAircondition}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sAircondition: state.subItemsPage.sAircondition
})

let WithUrlDataContainerComponent = withRouter(InfoContainer)

export default connect(mapStateToProps, { getAircondition })(WithUrlDataContainerComponent)
