import React from "react";
import * as axios from "axios";
import { Link, NavLink } from "react-router-dom";


class PriceList extends React.Component {
   
    componentDidUpdate() {
        var headertext = [];
        var headers = document.querySelectorAll(".miyazaki th"),
            tablebody = document.querySelector(".miyazaki tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }
    }
    componentDidMount() {
        var headertext = [];
        var headers = document.querySelectorAll(".miyazaki th"),
            tablebody = document.querySelector(".miyazaki tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }}
        constructor(props){
            super(props);
        //1
        if (props.vibratingPlatesItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}subdvibroplits`).then(response => {
               props.vPlatesHot(response.data)
            })
        }
        //2
        if (props.vibroLegsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}vibrorammers`).then(response => {
               props.vLHot(response.data)
            })
        }
        //3
        if (props.perforatorsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}perforators`).then(response => {
                props.perforatorsHot(response.data)
            })
        }
        //4
        if (props.jackhammersItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}jackhammers`).then(response => {
               props.jackhammersHot(response.data)
            })
        }
        //5
        if (props.seamcuttersItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}seamcutters`).then(response => {
                props.seamcuttersHot(response.data)
            })
        }
        //6
        if (props.grindingconItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}grindings`).then(response => {
                props.grindingconHot(response.data)
            })
        }
        //7
        if (props.generatorsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}generators`).then(response => {
                props.generatorsHot(response.data)
            })
        }
        //8
        if (props.thermalItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}thermals`).then(response => {
                props.thermalHot(response.data)
            })
        }
        //9
        if (props.compressorsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}compressors`).then(response => {
                props.compressorsHot(response.data)
            })
        }
        //10
        if (props.yamoburyItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}yamoburys`).then(response => {
               props.yamoburyHot(response.data)
            })
        }
        //11
        if (props.pumpsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}pamps`).then(response => {
                props.pumpsHot(response.data)
            })
        }
        //12
        if (props.concretmixItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}concretmixs`).then(response => {
                props.concretmixHot(response.data)
            })
        }
        //13
        if (props.tilecutterItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}tilecutters`).then(response => {
                props.tilecutterHot(response.data)
            })
        }
        //14
        if (props.vibroslatsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}vibroslats`).then(response => {
                props.vibroslatsHot(response.data)
            })
        }
        //15
        if (props.screwdriversItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}screwdrivers`).then(response => {
                props.screwdriversHot(response.data)
            })
        }
        //16
        if (props.rohliItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}rohlis`).then(response => {
                props.rohliHot(response.data)
            })
        }
        //17
        if (props.airconditionItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}equipairconds`).then(response => {
                props.airconditionHot(response.data)
            })
        }
        //18
        if (props.weldingItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}weldings`).then(response => {
                props.weldingHot(response.data)
            })
        }
        //19
        if (props.frasersItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}frasers`).then(response => {
                props.frasersHot(response.data)
            })
        }
        //20
        if (props.woodsandersItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}woodsanders`).then(response => {
               props.woodsandersHot(response.data)
            })
        }
        //21
        if (props.metalcuttingItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}metalcuttings`).then(response => {
                props.metalcuttingHot(response.data)
            })
        }
        //22
        if (props.measuringItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}levels`).then(response => {
                props.measuringHot(response.data)
            })
        }
        //23
        if (props.chainsawsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}jigsaws`).then(response => {
                props.chainsawsHot(response.data)
            })
        }
        //24
        if (props.vacuumsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}vacuums`).then(response => {
                props.vacuumsHot(response.data)
            })
        }
        //25
        if (props.bulgarianItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}bulgarians`).then(response => {
                props.bulgarianHot(response.data)
            })
        }
        //26
        if (props.washingequipmentItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}washingequs`).then(response => {
                props.washingequipmentHot(response.data)
            })
        }
        //27
        if (props.plumbingequipmentItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}plumbingequs`).then(response => {
                props.plumbingequipmentHot(response.data)
            })
        }
        //28
        if (props.scaffoldingItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}scaffoldings`).then(response => {
                props.scaffoldingHot(response.data)
            })
        }
        //29
        if (props.formworkequipmentItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}formworkequs`).then(response => {
               props.formworkequipmentHot(response.data)
            })
        }
        //30
        if (props.stairstourItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}stairstours`).then(response => {
                props.stairstourHot(response.data)
            })
        }
        //31
        if (props.wiringequipmentItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}electricalinstalls`).then(response => {
                props.wiringequipmentHot(response.data)
            })
        }
        //32
        if (props.drillsItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}drills`).then(response => {
                props.drillsHot(response.data)
            })
        }
        //33
        if (props.sportequipmentItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}sportequs`).then(response => {
                props.sportequipmentHot(response.data)
            })
        }
        //34
        if (props.boersItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}drillscrowns`).then(response => {
                props.boersHot(response.data)
            })
        }
        //35
        if (props.miscellaneousItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}others`).then(response => {
                props.miscellaneousHot(response.data)
            })
        }
        //36
        if (props.consumablesItem.length === 0) {
            axios.get(`${process.env.REACT_APP_SERVER}expendables`).then(response => {
                props.consumablesHot(response.data)
            })
        }
    }

    render() {

        let as = [
            {
                th: [
                    { tr: 'Название' },
                    { tr: 'Цена' }
                ]
            }
        ]


        let th = as.map(a =>
            <>
                {a.th.map(t =>
                    <th>
                        {t.tr}
                    </th>
                )}
            </>

        )

        //1
        let itemPlates = this.props.vibratingPlatesItem.map(v =>
            <>
                <tr style={{ minWidth: `100vw` }}>
                    <td>
                        <b>{v.title}</b>
                    </td>
                    <td>
                        от {v.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //2
        let itemLegs = this.props.vibroLegsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //3
        let itemW = this.props.perforatorsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //4
        let itemE = this.props.jackhammersItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //5
        let itemR = this.props.seamcuttersItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //6
        let itemT = this.props.grindingconItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //7
        let itemY = this.props.generatorsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //8
        let itemU = this.props.thermalItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //9
        let itemI = this.props.compressorsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //10
        let itemO = this.props.yamoburyItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //11
        let itemP = this.props.pumpsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //12
        let itemA = this.props.concretmixItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //13
        let itemS = this.props.tilecutterItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //14
        let itemD = this.props.vibroslatsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //15
        let itemF = this.props.screwdriversItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //16
        let itemG = this.props.rohliItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //17
        let itemH = this.props.airconditionItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //18
        let itemJ = this.props.weldingItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //19
        let itemK = this.props.frasersItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //20
        let itemL = this.props.woodsandersItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //21
        let itemZ = this.props.metalcuttingItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //22
        let itemX = this.props.measuringItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //23
        let itemC = this.props.chainsawsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //24
        let itemV = this.props.vacuumsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //25
        let itemB = this.props.bulgarianItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //26
        let itemN = this.props.washingequipmentItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //27
        let itemM = this.props.plumbingequipmentItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //28
        let itemQQ = this.props.scaffoldingItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //29
        let itemWW = this.props.formworkequipmentItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //30
        let itemEE = this.props.stairstourItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //31
        let itemRR = this.props.wiringequipmentItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //32
        let itemTT = this.props.drillsItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //33
        let itemYY = this.props.sportequipmentItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //34
        let itemUU = this.props.boersItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //35
        let itemII = this.props.miscellaneousItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )
        //36
        let itemOO = this.props.consumablesItem.map(s =>
            <>
                <tr>
                    <td>
                        <b>{s.title}</b>
                    </td>
                    <td>
                        от {s.price}руб./сутки
                    </td>
                </tr>
            </>
        )




        return (
            <>
                <div className="p1">
                    <h1>
                        Прайс
                    </h1>
                    <table class="miyazaki">
                        <thead>
                            <tr>
                                {th}
                            </tr>
                        </thead>
                        <tbody>
                            <br />
                            <center>Вибротрамбовки (Виброноги)</center>
                            <br />
                            {itemLegs}
                            <br />
                            <center>Вибротрамбовки (Виброплиты)</center>
                            <br />
                            {itemPlates}
                            <br />
                            <center>Перфораторы</center>
                            <br />
                            {itemW}
                            <br />
                            <center>Отбойные молотки</center>
                            <br />
                            {itemE}
                            <br />
                            <center>Штроборезы ручные, Швонарезчики</center>
                            <br />
                            {itemR}
                            <br />
                            <center>Шлифовальные машины по бетону. (Вертолёты)</center>
                            <br />
                            {itemT}
                            <br />
                            <center>Генераторы, стабилизаторы</center>
                            <br />
                            {itemY}
                            <br />
                            <center>Тепловое оборудование</center>
                            <br />
                            {itemU}
                            <br />
                            <center>Компрессоры, краскопульты, пескоструй</center>
                            <br />
                            {itemI}
                            <br />
                            <center>Садовое оборудование, Ямобуры</center>
                            <br />
                            {itemO}
                            <br />
                            <center>Мотопомпы, Электро-насосы</center>
                            <br />
                            {itemP}
                            <br />
                            <center>Бетономешалки</center>
                            <br />
                            {itemA}
                            <br />
                            <center>Плиткорезы</center>
                            <br />
                            {itemS}
                            <br />
                            <center>Вибраторы для бетона. Виброрейки</center>
                            <br />
                            {itemD}
                            <br />
                            <center>Шуруповерты</center>
                            <br />
                            {itemF}
                            <br />
                            <center>Рохли, Тельфер, Таль, Домкрат</center>
                            <br />
                            {itemG}
                            <br />
                            <center>Оборудование для монтажа кондиционеров</center>
                            <br />
                            {itemH}
                            <br />
                            <center>Сварочное оборудование</center>
                            <br />
                            {itemJ}
                            <br />
                            <center>Рубанки, фрейзеры</center>
                            <br />
                            {itemK}
                            <br />
                            <center>Шлифовальные машины по дереву</center>
                            <br />
                            {itemL}
                            <br />
                            <center>Металлорежущие инструменты</center>
                            <br />
                            {itemZ}
                            <br />
                            <center>Уровни, нивелиры, измерительные приборы</center>
                            <br />
                            {itemX}
                            <br />
                            <center>Бензопилы. Лобзики. Торцевые пилы</center>
                            <br />
                            {itemC}
                            <br />
                            <center>Пылесосы</center>
                            <br />
                            {itemV}
                            <br />
                            <div>
                            <center>Болгарки</center>
                            </div>
                            
                            <br />
                            {itemB}
                            <br />
                            <center>Моющее оборудование</center>
                            <br />
                            {itemN}
                            <br />
                            <center>Сантехническое оборудование</center>
                            <br />
                            {itemM}
                            <br />
                            <center>Строительные леса</center>
                            <br />
                            {itemQQ}
                            <br />
                            <center>Опалубочное оборудование</center>
                            <br />
                            {itemWW}
                            <br />
                            <center>Лестницы и вышки тура</center>
                            <br />
                            {itemEE}
                            <br />
                            <center>Электромонтажное оборудование</center>
                            <br />
                            {itemRR}
                            <br />
                            <center>Дрели</center>
                            <br />
                            {itemTT}
                            <br />
                            <center>Спортивное снаряжение</center>
                            <br />
                            {itemYY}
                            <br />
                            <center>Буры, коронки для перфораторов</center>
                            <br />
                            {itemUU}
                            <br />
                            <center>Разное</center>
                            <br />
                            {itemII}
                            <br />
                            <center>Расходные Материалы</center>
                            <br />
                            {itemOO}

                        </tbody>
                    </table>
                </div>
            </>
        )
    }

}

export default PriceList