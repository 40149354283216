
import WoodsandersPage from './WoodsandersPage'
import { connect } from "react-redux"
import { woodsandersAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        woodsandersItem: state.subItemsPage.woodsandersItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        woodsandersHot(woodsandersItem) {
            dispatch(woodsandersAC(woodsandersItem))
        }
    }
}

export const WoodsandersPContainer = connect(mapStateToProps, mapDispatchToProps)(WoodsandersPage);
export default WoodsandersPContainer;