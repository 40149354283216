import React from "react";
import { NavLink } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import BreadCrumbs from "../../../elements/breadСrumbs/BreadCrumbs";
import f from '../../scss/full.module.scss'
import InfoContainer from "./info/infoContainer";


function Full(props) {


    if (!props.sCompressors) {
        return <div></div>
    }
    let title = props.sCompressors.title
    let about = props.sCompressors.about
    let picture = props.sCompressors.picture
    
    let hBig = props.sCompressors.hMax
    let hBigItems = hBig.map(items =>
        <div className={`dFlex noWrap fDirect ${f.specifications} `}>
            <div className={`dFlex noWrap justifyContentSpaceBetween p05`}>
                <b><p>{items.type}:</p></b>
                <p style={{ maxWidth: `290px`, textAlign: `right` }}>{items.name}</p>
            </div>
            <hr style={{ margin: `0` }} />
            <div></div>
        </div>
    )



    return (
        <>
            <br />
            <BreadCrumbs>
                <NavLink to='/categories/сompressors'>
                Компрессоры, краскопульты, пескоструй /
                </NavLink> {title}
            </BreadCrumbs>
            <br />

            <h1 className={`p1 ${f.container}`} style={{ fontFamily: `MyFont`, fontWidth: `900` }}>
                {title}
            </h1>
            <br />
            <div className={`dFlex justifyContentSpaceBetween ${f.container} mb1`}>
                <div className={`${f.contImg} dFlex alignItemsCenter justifyContentCenter p3`}>
                    <SRLWrapper>
                        <img src={`${process.env.REACT_APP_SERVER}${picture}`} alt={title} />
                    </SRLWrapper>
                </div>
                <hr />
                <div className={`p1 ${f.cardCont} `} >
                    <h3>
                        Характеристики
                    </h3>
                    <div className={`dFlex fDirect`} style={{ alignContent: ` center` }}>
                        {hBigItems}
                    </div>

                </div>
                <div className={`p1 ${f.deliveryCont}`}>
                    <InfoContainer />
                </div>
                <div className="p1">
                    <h3>
                        Описание:
                    </h3>
                    <p>
                        {about}
                    </p>
                </div>

            </div>
        </>
    );

}

export default Full