import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getMiscellaneous } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class SportequsFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getMiscellaneous(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sMiscellaneous={this.props.sMiscellaneous}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sMiscellaneous: state.subItemsPage.sMiscellaneous
})

let WithUrlDataContainerComponent = withRouter(SportequsFPContainer)

export default connect(mapStateToProps, { getMiscellaneous })(WithUrlDataContainerComponent)
