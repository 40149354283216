import React from 'react'
import h from './header.module.scss'
import TMContainer from './rootMenu/tMContainer'
import SLContainer from './contactsPhone/SLContainer'
import SNHContainer from './socNetHeader/SNHContainer'
import map from '../../assets/images/svg/map.svg'
// import { NavLink } from 'react-router-dom'



function Header(props) {
    // if (!props.sStyle) {
    //     return <div></div>
    // }

    return (

        <header className="header__cont " style={{ width: `100%` }}>
            <div style={{ width: `100%`, boxShadow: `0 0 5px 0 #000000` }}>
                <div className='flexBox' style={{ maxWidth: `1400px`, margin: `auto` }}>
                    <div className="header__cont_menu">
                        <TMContainer />
                    </div>

                </div>
            </div>
            <div className={`dFlex wrap justifyContentSpaceEvenly alignItemsCenter pl1 pr1 ${h.contObject}`}>
                {/* <div className={`dFlex alignItemsCenter`}> */}
                <SLContainer />
                <a href='https://yandex.ru/maps/1107/anapa/?from=mapframe&ll=37.365265%2C44.899618&mode=usermaps&source=mapframe&um=constructor%3ACj3iZKziW3YjmEAJopweK_IVKvEzID5I&utm_source=mapframe&z=17' rel="noreferrer"  target="_blank" className={`${h.adres} dFlex justifyContentSpaceEvenly alignItemsCenter`} style={{}}>
                    <img  src={`${map}`} alt="адрес" />
                    <p>
                        Адрес: Россия, г. Анапа, Анапское ш., д.99а,&nbsp;корп.&nbsp;1
                    </p>
                </a>
                {/* </div> */}
                <SNHContainer />
            </div>

        </header>
    )
}



export default Header
