
import JigsawsPage from './JigsawsPage'
import { connect } from "react-redux"
import { chainsawsAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        chainsawsItem: state.subItemsPage.chainsawsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        chainsawsHot(chainsawsItem) {
            dispatch(chainsawsAC(chainsawsItem))
        }
    }
}

export const JigsawsPContainer = connect(mapStateToProps, mapDispatchToProps)(JigsawsPage);
export default JigsawsPContainer;