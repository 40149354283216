
import ThermalsPage from './ThermalsPage'
import { connect } from "react-redux"
import { thermalAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        thermalItem: state.subItemsPage.thermalItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        thermalHot(thermalItem) {
            dispatch(thermalAC(thermalItem))
        }
    }
}

export const TPContainer = connect(mapStateToProps, mapDispatchToProps)(ThermalsPage);
export default TPContainer;