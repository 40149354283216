import React from 'react';
import { NavLink } from 'react-router-dom';
import f from '../../../scss/full.module.scss'



function Infos(props) {
    if (!props.sPlumbingequipment) {
        return <div></div>
    }
    let price = props.sPlumbingequipment.price
    let links = [
        {
            id: 1,
            title: `Аренда`,
            textBtn: `Подробнее...`,
            price: `от ${price}₽ в сутки`,
            link: `priceList`
        },
        {
            id: 2,
            title: `Доставка`,
            textBtn: `Подробнее...`,
            price: `от 400₽ в черте города`,
            link: `deliveryList`
        }
    ]

    let cardItems = links.map(l =>
        <div key={l.id} className={`${f.delivery} dFlex`}style={{alignItems: `flex-end`}}>
            <h3>
                {l.title}
            </h3>
            <div style={{maxHeight:`60px`, height:`100%`}}>
                <p style={{textAlign:`center`}}>
                    {l.price}
                </p>
            </div>
            <NavLink   to={`/${l.link}`}>
                <div className={`btns btn3`}>
                    {l.textBtn}
                </div>
            </NavLink>
        </div>
    )
    return (
        <div className={`dFlex justifyContentCenter`} style={{width:`100%`}}>
            {cardItems}
            <div className={`${f.delivery} dFlex`} style={{alignItems: `flex-end`}}>
                <h3>
                    Оплата
                </h3>
                <div>
                    <p>
                        Различные способы оплаты
                    </p>
                </div>
                <NavLink to='/delivery'>
                    <div className={`btns btn3`}>
                        Подробнее...
                    </div>
                </NavLink>
            </div>
            <div className={`${f.delivery} dFlex`} style={{ alignItems: `flex-end`, minWidth: `170px`, justifyContent:`center` }}>
                <h3>
                    Аренда
                </h3>
                <div style={{maxHeight: `60px`, height: `100%`}}>
                    <p style={{fontSize:`0.85rem`}}>
                        +7 (989) 242-93-93
                    </p>
                </div>
                <a href='tel:+79892429393'>
                    <div className={`btns btn3`}>
                        Позвонить
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Infos;