import React from 'react'
import SContainer from './service/page/SContainer'
import { seo } from '../../hook/seo'
import c from './services.module.scss'
import fon from '../../assets/images/jpegPng/service.png'
import BreadСrumbsHome from '../elements/breadСrumbsHome/BreadСrumbsHome'


class Services extends React.Component {
   componentDidMount(props) {
      seo({
         title: 'КрайПрокат-93 |  Предоставляемые услуги В Анапе | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе',
         metaDescription: 'КрайПрокат-93 | Предоставляемые услуги В Анапе | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе'
      });

   }

   render() {
      return (
         <div>
            <div className={`${c.imgContPrew}`} style={{ backgroundImage: `url(${fon})` }}>
               <div style={{ maxWidth: `1400px`, width: `100%`, margin: `0 auto` }}>
                  <h1 className='p2 tACenter' >
                     Наши услуги
                  </h1>
               </div>
            </div>
            <div className="main-cont">
               <div className={`pt1`}>
                  <BreadСrumbsHome>
                     Услуги
                  </BreadСrumbsHome>
               </div>
               <h2>Услуги</h2>
               <SContainer />
            </div>
         </div>
      )
   }
}

export default Services
