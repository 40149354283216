
import React from "react";
import { Link } from "react-router-dom";

class Delivery extends React.Component {


    componentDidUpdate() {
        var headertext = [];
        var headers = document.querySelectorAll(".miyazaki th"),
            tablebody = document.querySelector(".miyazaki tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }
    }
    componentDidMount() {
        var headertext = [];
        var headers = document.querySelectorAll(".miyazaki th"),
            tablebody = document.querySelector(".miyazaki tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }
    }
    render() {
        let thTab = [
            { name: 'Нас. пункт' },
            { name: 'Расстояние, км' },
            { name: 'Экспресс Доставка' },

        ]
        let deliv = [
            { city: 'Анапа', distance: 'черта города', exp: '300' },
            { city: 'Алексеевка', distance: 'черта города', exp: '500' },
            { city: 'Анапская', distance: '5', exp: '500' },
            { city: 'Бужор', distance: '14', exp: '600' },
            { city: 'Благовещенская', distance: '40', exp: '1300' },
            { city: 'Варваровка', distance: '10', exp: '600' },
            { city: 'Виноградный', distance: '25', exp: '900' },
            { city: 'Витязево', distance: '20', exp: '600' },
            { city: 'Воскресенский', distance: '12', exp: '500' },
            { city: 'Вестник', distance: '34', exp: '1200' },
            { city: 'Веселая Горка', distance: '45', exp: '1500' },
            { city: 'Гайкодзор', distance: '20', exp: '700' },
            { city: 'Гостагаевская', distance: '24', exp: '900' },
            { city: 'Горный', distance: '45', exp: '1600' },
            { city: 'Джемете', distance: '9', exp: '500' },
            { city: 'Джигинка', distance: '35', exp: '1000' },
            { city: 'Заря', distance: '16', exp: '500' },
            { city: 'Иваново', distance: '40', exp: '1300' },
            { city: 'Красная Скала', distance: '24', exp: '800' },
            { city: 'Красная Горка', distance: '40', exp: '1300' },
            { city: 'Красный Курган', distance: '20', exp: '700' },
            { city: 'Красный', distance: '12', exp: '500' },
            { city: 'Капустино', distance: '22', exp: '800' },
            { city: 'Куматырь', distance: '14', exp: '600' },
            { city: 'Курбатский', distance: '12', exp: '500' },
            { city: 'Куток', distance: '20', exp: '700' },
            { city: 'Лиманный', distance: '24', exp: '700' },
            { city: 'Кафе "Орел"', distance: '20', exp: '700' },
            { city: 'Макотра', distance: '8', exp: '500' },
            { city: 'Павловка', distance: '10', exp: '500' },
            { city: 'Пионерный', distance: '18', exp: '600' },
            { city: 'Просторный', distance: '12', exp: '500' },
            { city: 'Пятихатки', distance: '15', exp: '600' },
            { city: 'Раевская', distance: '28', exp: '1000' },
            { city: 'Рассвет', distance: '14', exp: '600' },
            { city: 'Разнокол', distance: '50', exp: '1300' },
            { city: 'Разнокол (Малый)', distance: '45', exp: '1200' },
            { city: 'Розы Люксембург', distance: '50', exp: '1300' },
            { city: 'СОТ Здоровье', distance: '20', exp: '700' },
            { city: 'Суворово-Черкесский', distance: '30', exp: '1100' },
            { city: 'Сукко', distance: '18', exp: '700' },
            { city: 'Супсех', distance: '3', exp: '500' },
            { city: 'Тарусино', distance: '12', exp: '500' },
            { city: 'Уташ', distance: '25', exp: '1000' },
            { city: 'Утриш (Большой)', distance: '20', exp: '1000' },
            { city: 'Усатова Балка', distance: '16', exp: '600' },
            { city: 'Ханчикрак Верхний', distance: '38', exp: '1300' },
            { city: 'Ханчикрак Нижний', distance: '43', exp: '1300' },
            { city: 'Чекон', distance: '36', exp: '1200' },
            { city: 'Чекон Верхний', distance: '34', exp: '1200' },
            { city: 'Чембурка', distance: '6', exp: '500' },
            { city: 'Цибанобалка', distance: '16', exp: '600' },
            { city: 'Юровка', distance: '40', exp: '1200' },
            { city: 'Пионерский проспект (до роты ДПС)', distance: 'черта города', exp: '500' },
        ]


        let th = thTab.map(t =>
            <th>
                {t.name}
            </th>
        )
        let td = deliv.map(t =>
            <tr>
                <td>
                    <b>{t.city}</b>
                </td>
                <td>
                    <b>{t.distance}</b>
                </td>
                <td>
                    <b>от  {t.exp} рублей</b>
                </td>
            </tr>
        )
        return (
            <>
                <div className="p1">
                    <h1 className="pb1">
                        Доставка
                    </h1>
                    <table class="miyazaki deliv">
                        <thead>
                            <tr>
                                {th}
                            </tr>
                        </thead>
                        <tbody>
                            {td}
                        </tbody>
                    </table>
                    <div className="p1">
                        <Link to='/delivery'>
                            <div className="btns btn3">
                                Вернуться назад
                            </div>
                        </Link>
                    </div>
                </div>
            </>
        )
    }


}

export default Delivery