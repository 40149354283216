
import DrillscrownsPage from './DrillscrownsPage'
import { connect } from "react-redux"
import { boersAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        boersItem: state.subItemsPage.boersItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        boersHot(boersItem) {
            dispatch(boersAC(boersItem))
        }
    }
}

export const SportequsPContainer = connect(mapStateToProps, mapDispatchToProps)(DrillscrownsPage);
export default SportequsPContainer;