import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getSportequipment } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class SportequsFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getSportequipment(id)

   }

   render() {
      return (
         <>
            <Full
               {...this.props}
               sSportequipment={this.props.sSportequipment}
            />
         </>
      )
   }
}
let mapStateToProps = (state) => ({
   sSportequipment: state.subItemsPage.sSportequipment
})

let WithUrlDataContainerComponent = withRouter(SportequsFPContainer)

export default connect(mapStateToProps, { getSportequipment })(WithUrlDataContainerComponent)
