import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import PlumbingequsPContainer from './page/PlumbingequsPContainer'

class Plumbingequs extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Сантехническое оборудование
                </h1>
                <BreadCrumbs>Сантехническое оборудование</BreadCrumbs>
                <PlumbingequsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Plumbingequs
