import React from 'react'
import { connect } from 'react-redux'
import { getVibratingPlates } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class InfoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getVibratingPlates(id)

   }

   render() {
      return (
         <Infos 
         {...this.props} 
         sVibratingPlates={this.props.sVibratingPlates}
          />
      )
   }
}
let mapStateToProps = (state) => ({
   sVibratingPlates: state.subItemsPage.sVibratingPlates
})

let WithUrlDataContainerComponent = withRouter(InfoContainer)

export default connect(mapStateToProps, { getVibratingPlates })(WithUrlDataContainerComponent)
