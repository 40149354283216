import React from "react";
import { connect } from "react-redux"
import Contact from './Contact'
// import { styleAC } from "../../../redux/styleReducer"

class CContainer extends React.Component{
componentDidMount(){
    
}
render(){
    return(
<Contact 
{...this.props}
/>        
    )
}

}
let mapStateToProps = (state) => {

    return {
        itemsContact: state.contactsPage.contactItems,
        itemsMap: state.contactsPage.mapsItem,
        // styleItems: state.styleModule.styleItems
    }
}
let mapDispatchToProps = (dispatch) => {
    return {
        // styleHot(styleItems) {
        //     dispatch(styleAC(styleItems))
        // }
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(CContainer);

