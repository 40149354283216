
import MetalcuttingsPage from './MetalcuttingsPage'
import { connect } from "react-redux"
import { metalcuttingAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        metalcuttingItem: state.subItemsPage.metalcuttingItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        metalcuttingHot(metalcuttingItem) {
            dispatch(metalcuttingAC(metalcuttingItem))
        }
    }
}

export const MetalcuttingsPContainer = connect(mapStateToProps, mapDispatchToProps)(MetalcuttingsPage);
export default MetalcuttingsPContainer;