import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getBulgarian } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class BulgariansFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getBulgarian(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sBulgarian={this.props.sBulgarian}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sBulgarian: state.subItemsPage.sBulgarian
})

let WithUrlDataContainerComponent = withRouter(BulgariansFPContainer)

export default connect(mapStateToProps, { getBulgarian })(WithUrlDataContainerComponent)
