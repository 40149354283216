import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss'
import { seo } from '../../hook/seo'
import AbTabs from './aboutTabs/AbTabs';
import Description from './description/Description';
import StickLabel from './stickLabel/StickLabel';
import FRSContainer from './slider/FRSContainer';
import SOContainer from './specialOffers/SOContainer';
import ContactContainer from './contacts/ContactContainer';
import h from './home.module.scss'
import fon from '../../assets/images/jpegPng/homeFon.png'
import fon1 from '../../assets/images/jpegPng/p1.webp'
import fon2 from '../../assets/images/jpegPng/p2.webp'
import fon3 from '../../assets/images/jpegPng/p3.webp'
import fon4 from '../../assets/images/jpegPng/p4.png'
import fon5 from '../../assets/images/jpegPng/p5.webp'
import fon6 from '../../assets/images/jpegPng/p6.webp'
import fon7 from '../../assets/images/jpegPng/p7.webp'
import fon8 from '../../assets/images/jpegPng/p8.webp'
import fon9 from '../../assets/images/jpegPng/p9.webp'
// import { NavLink } from 'react-router-dom';
import Service from './service/Service';

export default class Home extends React.Component {

   componentDidMount() {
      seo({
         title: 'КрайПрокат-93 | Сервис аренды и проката строительной техники',
         metaDescription: 'Мета'
      });

   }

   render() {
      return (
         <>
            <div className={``}>
               <div>
                  <div className={`${h.imgContPrew}`} style={{ backgroundImage: `url(${fon7})`, transform: `scale(-1, 1)`}}>
                     <div style={{ maxWidth: `1400px`, width: `100%`, margin: `0 auto`, transform: `scale(-1, 1)` }}>
                        <h1 className={`tACenter p2`}>
                           Аренда строительного инструмента и оборудования в Анапе.
                        </h1>
                     </div>
                  </div>
                  <div style={{ maxWidth: `1100px`, margin: `auto` }}>
                     <h3 className='p1'>
                        Категории
                     </h3>
                     <FRSContainer />
                  </div>
                  <div className={`mb1`}>
                     <h2 className={`tACenter p1`}>
                        Специальные предложения
                     </h2>
                     <div style={{ maxWidth: `1170px`, margin: `auto` }}>
                        <SOContainer />
                     </div>
                  </div>
               </div>
            </div>

            <div className="promotions">
               <StickLabel />
            </div>
            <div className='mt2'>
               <Service/>
               
            </div>
            <Description />
            <div className="content__about">
               {/* <AbTabs /> */}
            </div>
            <ContactContainer />
         </>
      )
   }
}