
import RohliPage from './RohliPage'
import { connect } from "react-redux"
import { rohliAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        rohliItem: state.subItemsPage.rohliItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        rohliHot(rohliItem) {
            dispatch(rohliAC(rohliItem))
        }
    }
}

export const RohliPContainer = connect(mapStateToProps, mapDispatchToProps)(RohliPage);
export default RohliPContainer;