
import JackhammersPage from './JackhammersPage'
import { connect } from "react-redux"
import { jackhammersAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        jackhammersItem: state.subItemsPage.jackhammersItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        jackhammersHot(jackhammersItem) {
            dispatch(jackhammersAC(jackhammersItem))
        }
    }
}

export const JHPContainer = connect(mapStateToProps, mapDispatchToProps)(JackhammersPage);
export default JHPContainer;