
import StairstoursPage from './StairstoursPage'
import { connect } from "react-redux"
import { stairstourAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        stairstourItem: state.subItemsPage.stairstourItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        stairstourHot(stairstourItem) {
            dispatch(stairstourAC(stairstourItem))
        }
    }
}

export const StairstoursPContainer = connect(mapStateToProps, mapDispatchToProps)(StairstoursPage);
export default StairstoursPContainer;