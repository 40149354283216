import {
    hotelApi
} from "../api/api"

const CATEGORIES_RED = 'CATEGORIES-RED'
const SET_CATEGORIES = 'SET-CATEGORIES'

let initionState = {
    categoriesItem: [],
    sСategory: null,

}

const categoriesReduscer = (state = initionState, action) => {

    switch (action.type) {
        case CATEGORIES_RED: {
            return {
                ...state,
                categoriesItem: [ /*...state.roomsItem,*/ ...action.categoriesItem]
            }
        }
        case SET_CATEGORIES: {
            return {
                ...state,
                sСategory: action.sСategory
            }
        }
        default:
            return state;
    }

}
export const categoryAC = (categoriesItem) => ({
    type: CATEGORIES_RED,
    categoriesItem
})

const setCategories = (sСategory) => ({
    type: SET_CATEGORIES,
    sСategory
})

export const getСategory = () => (dispatch) => {
    hotelApi.getСategory().then(response => {
        dispatch(setCategories(response.data))
    })
}
export default categoriesReduscer;