import React from 'react';



function UlEl(props)  {

  return (

    
        <ul  className={/*${props.sStyle.map(s => s.subject)}-text*/` push`}>
            {props.children}
        </ul>
    )
}

export default (UlEl);