import React from 'react'
import { connect } from 'react-redux'
import Full from './fullPage/Full'
import { getPerforators } from '../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class PerContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getPerforators(id)

   }

   render() {
      return (
         <Full 
         {...this.props} 
         sPerforators={this.props.sPerforators}
          />
      )
   }
}
let mapStateToProps = (state) => ({
   sPerforators: state.subItemsPage.sPerforators
})

let WithUrlDataContainerComponent = withRouter(PerContainer)

export default connect(mapStateToProps, { getPerforators })(WithUrlDataContainerComponent)
