import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import WoodsandersPContainer from './page/WoodsandersPContainer'

class Woodsanders extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Шлифовальные машины по дереву
                </h1>
                <BreadCrumbs>Шлифовальные машины по дереву</BreadCrumbs>
                <WoodsandersPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Woodsanders
