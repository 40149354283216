
import WeldingsPage from './WeldingsPage'
import { connect } from "react-redux"
import { weldingAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        weldingItem: state.subItemsPage.weldingItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        weldingHot(weldingItem) {
            dispatch(weldingAC(weldingItem))
        }
    }
}

export const WeldingsPContainer = connect(mapStateToProps, mapDispatchToProps)(WeldingsPage);
export default WeldingsPContainer;