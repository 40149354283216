import { NavLink } from 'react-router-dom';
import s from './specialOffers.module.scss'
import dev from '../../../assets/images/svg/hour-glass.svg'
import reviews from '../../../assets/images/jpegPng/reviews.png'
import rm from '../../../assets/images/jpegPng/reviewsMin.png'
import bez1 from '../../../assets/images/jpegPng/map.png'
import minMap from '../../../assets/images/jpegPng/mapIcon.png'
import sk from '../../../assets/images/svg/skidka.svg'

function SpecialOffers(props) {

  return (
    <>
      <div className={`dFlex alignItemsCenter justifyContentCenter`}>
        <a href='https://yandex.ru/maps/1107/anapa/?from=mapframe&ll=37.365265%2C44.899618&mode=usermaps&source=mapframe&um=constructor%3ACj3iZKziW3YjmEAJopweK_IVKvEzID5I&utm_source=mapframe&z=17' target="_blank" className={`mb1`}>
          <div className={`${s.containerCard}`}>
            <div className={`${s.cardBlock}`}></div>
            <div className={`dFlex ${s.contImgs}`}>
              <img className={`${s.cardImg}`} src={`${bez1}`} alt='Расположение на карте' />
              <img className={`${s.mapIcon}`} src={minMap} alt="Карта" />
            </div>
            <div className={`p1 ${s.aboutCardCont}`}>
              <h3>
                Расположение
              </h3>
              <p>
                Информация о расположении нашей органичации для Вашего удобства. Вы всегда сможете проверить как добраться до нашей организации. 
              </p>
            </div>
          </div>
        </a>
        <div className={`mb1 dFlex justifyContentSpaceEvenly fDirect ${s.contCenterCard}`}>
          <div className='mb1'>
            <NavLink to='/deliveryList'>
              <div className={`${s.containerCardT} dFlex alignItemsCenter justifyContentSpaceEvenly`}>
              <div className={`${s.cardBlock}`}></div>
                <div className={`dFlex ${s.cardImgT}`}>
                  <img className='' src={`${dev}`} alt='Экспресс Доставка' />
                </div>
                <div className={`p1 ${s.aboutCardContT}`}>
                  <h3>
                    Экспресс Доставка
                  </h3>
                  <p>
                    При необходимости можем осуществить внеочередную доставку необходимого Вам оборудования до места назначения.
                  </p>
                </div>
              </div>
            </NavLink>
          </div>
          <div className=''>
            <NavLink to=''>
              <div className={`${s.containerCardT} ${s.containerCardT2} dFlex alignItemsCenter`} >
              <div className={`${s.cardBlock}`}></div>
                <div className={`p1 ${s.aboutCardContT}`}>
                  <h3>
                    Скидка на первый заказ
                  </h3>
                  <p>
                    Если Вы ещё не были нашим клиентом предлагаем для вас скудку в 10% на первый заказ оборудования. Скидка не превышает 2000 рублей
                  </p>
                </div>
                <div>
                  <img className={`${s.cardImgT}`} src={`${sk}`} alt='Безопасность' />
                </div>
              </div>
            </NavLink>
          </div>
        </div>
        <a href='https://yandex.ru/maps/org/krayprokat93/37299804812/reviews/?ll=37.364992%2C44.899783&z=15' target={`_blanck`} className={`mb1`}>
          <div className={`${s.containerCard}`}>
          <div className={`${s.cardBlock}`}></div>
            <div className={`dFlex ${s.contImgsB}`}>
              <img className={`${s.cardImgB}`} src={`${reviews}`} alt='Безопасность' />
              <img className={`${s.reviewsIcon}`} src={rm} alt="Карта" />
            </div>
            <div className={`p1 ${s.aboutCardCont}`}>
              <h3>
                Что о нас говорят?
              </h3>
              <p>
                Положительные отзывы расскажут Вам намного больше, чем "сухое" описание наших достоинств. <br /><br />
              </p>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
export default SpecialOffers