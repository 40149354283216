import React from "react";
import { connect } from 'react-redux'
import SpecialOffers from "./SpecialOffers";




class SOContainer extends React.Component {

    componentDidMount() {
        // this.props.getСategory()
    }

    render() {

        return (
            <>
                <SpecialOffers
                    {...this.props}
                    // sСategory={this.props.sСategory}
                />
            </>
        )
    }
}

let mapStateToProps = (state) => ({
    // sСategory: state.categoriesPage.sСategory
})


export default connect(mapStateToProps, {
    // getСategory
})(SOContainer)
