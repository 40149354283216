import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getThermal } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class GCFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getThermal(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sThermal={this.props.sThermal}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sThermal: state.subItemsPage.sThermal
})

let WithUrlDataContainerComponent = withRouter(GCFPContainer)

export default connect(mapStateToProps, { getThermal })(WithUrlDataContainerComponent)
