import React, { useState } from 'react'
import nav from './topMenu.module.scss'
import Menu from './menu/Menu'
import { Link } from 'react-router-dom'
import logo from '../../../assets/images/jpegPng/logo.png'

function TopMenu(props) {

    const [menuActive, setMenuActive] = useState(false)

    return (
        <>

            <div className={nav.contMenu}>{/*header*/}
                <div>{/*container*/}
                    <div className={nav.menuBody}>{/*header__body*/}
                        <Link to='/'><img style={{ maxWidth: `200px` }} src={logo} alt="КрайПрокате-93 | Прокат инстументов | Аренда строительного инструмента" /></Link>{/*header__logo */}

                        <div
                            className={`${nav.burgerMenu}`}
                            onClick={() => setMenuActive(!menuActive)}>
                            <span />
                        </div>
                        <Menu
                            active={menuActive}
                            setActive={setMenuActive}
                        />
                    </div>
                </div>
            </div>
        </>
    )

}



export default TopMenu
