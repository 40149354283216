import r from './req.module.scss'

function Requisites(props) {
    return (
        <div className={`dFlex ${r.contTabs} pb1`}>

            <div className={`${r.contTab}`}>
                <p>
                    ИП Мамедов Георгий Сергеевич 350029, Россия, Краснодарский&nbsp;край, Краснодар, проезд им Чернышевского 4-й, д 19
                </p>

            </div>
            <div className={`${r.contTab}`}>
                <p><b>ИНН:</b> 231151278766</p>
                <p><b>ОГРН:</b> 314231117000041</p>
                <p><b>БАНК:</b> АО "Тинькофф Банк"</p>
            </div>
            <div className={`${r.contTab}`}>
                <p><b>БИК:</b> 044525974</p>
                <p><b>Р/счет:</b> 40802810800000384039</p>
                <p><b>К/счет:</b> 30101810145250000974</p>
            </div>
        </div>
    )
}
export default Requisites