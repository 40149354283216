import { Nav } from "react-bootstrap"
import { NavLink } from "react-router-dom"
import s from './serv.module.scss'

function Service(props) {
    return (
        <>
            <NavLink to='/service/665f7ca6d77f9b0411b9c654' className={`${s.usluga} dFlex justifyContentSpaceBetween alignItemsCenter ${s.uslugCont}`} style={{ backgroundImage: `url(https://specalmaz.ru/tmpl/images/header.jpg)` }}>
                <img src="https://i.pinimg.com/originals/57/cf/be/57cfbe3771123715ac14db28952adee2.jpg" alt="" />
                <div className={`${s.aboutCont} p1`}>
                    <h2 className='m0 p0' style={{ color: `#ffffff` }}>
                        Услуга Алмазного бурения
                    </h2>
                    <p style={{ color: `#ffffff` }}>
                        Ищите услуги алмазного бурения в Анапе? Лучше всего сразу нанять профессионалов, обладающих экспертными знаниями в сфере техники и инструмента, необходимого для работы. По всем вопросам, связанным с профессиональным бурением и сверлением, вы можете обращаться в "КрайПрокат-93".
                    </p>
                </div>
            </NavLink>
            <div className="pt1">
                <NavLink to='/service'>
                    <div className="btns btn3 p07">
                        Больше услуг...
                    </div>
                </NavLink>
            </div>
        </>
    )
}
export default Service