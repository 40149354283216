
import ElectricalinstallsPage from './ElectricalinstallsPage'
import { connect } from "react-redux"
import { wiringequipmentAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        wiringequipmentItem: state.subItemsPage.wiringequipmentItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        wiringequipmentHot(wiringequipmentItem) {
            dispatch(wiringequipmentAC(wiringequipmentItem))
        }
    }
}

export const ElectricalinstallsPContainer = connect(mapStateToProps, mapDispatchToProps)(ElectricalinstallsPage);
export default ElectricalinstallsPContainer;