import {
    hotelApi
} from "../api/api"

const PROMOTIONS_RED = 'PROMOTIONS-RED'
const FULL_PROMOTIONS = 'FULL-PROMOTIONS'
const SET_PROMOTIONS = 'SET-PROMOTIONS'
const SET_SUPERMENU = 'SET-SUPERMENU'

let initionState = {
    promotionItems: [],
    sMenuItem:[],
    sliderItems: [{
            id: 1,
            title: 'Фото 1',
            urlPhoto: 'https://images.pexels.com/photos/3201921/pexels-photo-3201921.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
        },
        {
            id: 2,
            title: 'Фото 2',
            urlPhoto: 'https://cdn.pixabay.com/photo/2017/06/07/08/56/girl-2379705_960_720.jpg',
        },
        {
            id: 3,
            title: 'Фото 3',
            urlPhoto: 'https://images.pexels.com/photos/6782567/pexels-photo-6782567.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        },
        {
            id: 4,
            title: 'Фото 4',
            urlPhoto: 'https://images.pexels.com/photos/261411/pexels-photo-261411.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
        },
        {
            id: 5,
            title: 'Фото 5',
            urlPhoto: 'https://www.onetwotrip.com/ru/blog/wp-content/uploads/2017/08/read.jpg',
        },
        {
            id: 6,
            title: 'Фото 6',
            urlPhoto: 'https://images.pexels.com/photos/5371676/pexels-photo-5371676.jpeg',
        },
    ],
    sPromotion: null,
    sSMenu: null,
}


const homeReducer = (state = initionState, action) => {
    switch (action.type) {
        case PROMOTIONS_RED: {
            return {
                ...state,
                promotionItems: [ /*...state.promotionItems,*/ ...action.promotionItems]
            }
        }
        case FULL_PROMOTIONS: {
            return {
                ...state,
                promotionItems: [ /*...state.promotionItems,*/ ...action.promotionItems]
            }
        }
        case SET_PROMOTIONS: {
            return {
                ...state,
                sPromotion: action.sPromotion
            }
        }
        case SET_SUPERMENU: {
            return {
                ...state,
                sSMenu: action.sSMenu
            }
        }
        default:
            return state;
    }
}
export const promotionAC = (promotionItems) => ({
    type: PROMOTIONS_RED,
    promotionItems
})
const setPromotion = (sPromotion) => ({
    type: SET_PROMOTIONS,
    sPromotion
})
export const getPromotion = (linkMore) => (dispatch) => {
    hotelApi.getPromotion(linkMore).then(response => {
        dispatch(setPromotion(response.data))
    })
}

export const sMenuAC = (sMenuItems) => ({
    type: PROMOTIONS_RED,
    sMenuItems
})
const setSMenu = (sSMenu) => ({
    type: SET_SUPERMENU,
    sSMenu
})
export const getSMenu = () => (dispatch) => {
    hotelApi.getSMenu().then(response => {
        dispatch(setSMenu(response.data))
    })
}


export default homeReducer;