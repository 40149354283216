import {
    hotelApi
} from "../api/api"

const ABOUT = 'ABOUT'
const SET_ABOUT = 'SET_ABOUT'
const GALLERY = 'GALLERY'
const SET_GALLERY = 'SET_GALLERY'

let initionState = {
    aboutUsItems: [],
    galleryImage: [],
    sAbout: null,
    sGallery: null,
}

const aboutUsReducer = (state = initionState, action) => {

    switch (action.type) {
        case ABOUT: {
            return {
                ...state,
                aboutUsItems: [ /*...state.aboutUsItems,*/ ...action.aboutUsItems]
            }
        }
        case SET_ABOUT: {
            return {
                ...state,
                sAbout: action.sAbout
            }
        }
        case GALLERY: {
            return {
                ...state,
                galleryImage: [ /*...state.galleryImage,*/ ...action.galleryImage]
            }
        }
        case SET_GALLERY: {
            return {
                ...state,
                sGallery: action.sGallery
            }
        }
        default:
            return state;
    }
}

export const aboutAC = (aboutUsItems) => ({
    type: ABOUT,
    aboutUsItems
})
const setAbout = (sAbout) => ({
    type: SET_ABOUT,
    sAbout
})
export const getAbout = () => (dispatch) => {
    hotelApi.getAbout().then(response => {
        dispatch(setAbout(response.data))
    })
}
export const galleryAC = (galleryImage) => ({
    type: GALLERY,
    galleryImage
})
const setGallery = (sGallery) => ({
    type: SET_GALLERY,
    sGallery
})
export const getGallery = () => (dispatch) => {
    hotelApi.getGallery().then(response => {
        dispatch(setGallery(response.data))
    })
}



export default aboutUsReducer;