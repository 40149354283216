import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import ScaffoldingsPContainer from './page/ScaffoldingsPContainer'

class Scaffoldings extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Строительные леса
                </h1>
                <BreadCrumbs>Строительные леса</BreadCrumbs>
                <ScaffoldingsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Scaffoldings
