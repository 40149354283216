import React from 'react'
import { connect } from 'react-redux'
import Full from './fullPage/Full'
import { getVibratingPlates } from '../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class VibContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getVibratingPlates(id)

   }

   render() {
      
      return (
         <Full 
         {...this.props} 
         sVibratingPlates={this.props.sVibratingPlates}
          />
      )
   }
}
let mapStateToProps = (state) => ({
   sVibratingPlates: state.subItemsPage.sVibratingPlates
})

let WithUrlDataContainerComponent = withRouter(VibContainer)

export default connect(mapStateToProps, { getVibratingPlates })(WithUrlDataContainerComponent)
