import React from 'react'
import { NavLink } from 'react-router-dom';
import Slider from "react-slick";
import Next from './Next';
import Prew from './Prew';
import n from './next.module.scss'
// import FRS from './FRSlider.module.scss'
// import * as axios from "axios";

function FRSlider(props) {
    if (!props.sСategory) {
        return <div>Загрузка Категорий...</div>
    }

    // {/* ${props.styleItems.map(st => st.subject)} lighten-1}`*/} 
    const settings = {
        dots: false,                // точки
        focusOnSelect: false,       // щелкнуть на слайд чтобы прокрутилось до него
        fade: false,                // одно изображение при true
        lazyLoad: true,             // медленная загрузка
        pauseOnHover: true,         // Пауза при наведении
        infinte: true,              // Неравномерные множества
        speed: 750,                 // скорость перелистывания
        slidesToShow: 4,            // Количество видимых слайдов сразу
        arrows: true,               // Стрелки вперёд назад
        prevArrow: <Prew />,
        nextArrow: <Next />,
        slidesToScroll: 1,          // Количество перелист блоков за раз
        className: "roomSlides",    // Класс у контейнера
        autoplay: true,             // авто запуск
        autoplaySpeed: 3000,        // врямя до первого авто запуска потом нужно вычитать скорость прокрутки
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }

    return (
        // Слайдер и показ фото пересмотреть!!!
        <>
           <Slider {...settings}>
                {props.sСategory.map(sl =>
                    <div key={sl._id}>
                        <NavLink to={`categories/${sl.link}`}>
                            <div className='p1'>
                                <div className={`dFlex alignItemsCenter justifyContentCenter fDirect ${n.shadow}`}>
                                    <img width='80%' style={{ maxWidth: `250px`, height: `250px`, objectFit: `contain` }} src={`${process.env.REACT_APP_SERVER}${sl.picture}`} alt="" />
                                    <p className={`mb1 tACenter pl1 pr1`} style={{ minHeight: `65px` }}>
                                        {sl.title}
                                    </p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                )}
            </Slider>
            <div>
                <div style={{maxWidth:`210px`}} className={`mA mb2`}>
                    <NavLink to='/categories'>
                        <div className={`btns btn3`}>
                            Все категории
                        </div>
                    </NavLink>
                </div>
            </div>
        </>
    );
}
export default FRSlider