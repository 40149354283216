import VibPlitPage from './VibPlitPage'
import { connect } from "react-redux"
import { vibratingPlatesAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        vibratingPlatesItem: state.subItemsPage.vibratingPlatesItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        vibratingPlatesHot(vibratingPlatesItem) {
            dispatch(vibratingPlatesAC(vibratingPlatesItem))
        }
    }
}

export const VPPContainer = connect(mapStateToProps, mapDispatchToProps)(VibPlitPage);
export default VPPContainer;