
import VibroslatsPage from './VibroslatsPage'
import { connect } from "react-redux"
import { vibroslatsAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        vibroslatsItem: state.subItemsPage.vibroslatsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        vibroslatsHot(vibroslatsItem) {
            dispatch(vibroslatsAC(vibroslatsItem))
        }
    }
}

export const VibroslatsPContainer = connect(mapStateToProps, mapDispatchToProps)(VibroslatsPage);
export default VibroslatsPContainer;