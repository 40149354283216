import React from "react";
import { connect } from 'react-redux'
import ContactHome from "./ContactHome";



class CategContainer extends React.Component {

    componentDidMount() {
        // this.props.getСategory()
    }

    render() {

        return (
            <>
                <ContactHome
                    {...this.props}
                    // sСategory={this.props.sСategory}
                />
            </>
        )
    }
}

let mapStateToProps = (state) => ({
    // sСategory: state.categoriesPage.sСategory,

})


export default connect(mapStateToProps,/*mapDispatchToProps,*/ {
    // getСategory
})(CategContainer)
