import React from 'react'
import { connect } from 'react-redux'
import { getGrindingcon } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class infoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getGrindingcon(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sGrindingcon={this.props.sGrindingcon}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sGrindingcon: state.subItemsPage.sGrindingcon
})

let WithUrlDataContainerComponent = withRouter(infoContainer)

export default connect(mapStateToProps, { getGrindingcon })(WithUrlDataContainerComponent)
