import React from "react";
import { connect } from 'react-redux'
// import { getStyle } from '../../../redux/styleReducer'
import TopMenu from "./topMenu"


class TMContainer extends React.Component {

    componentDidMount() {
        // this.props.getStyle()
    }

    render() {

        return (
            <>
                <TopMenu
                    {...this.props}
                />
            </>
        )
    }
}
let mapStateToProps = (state) => ({
    // sStyle: state.styleModule.sStyle,
    // itemsMenu: state.headerPage.itemsMenu,
    
})
  
export default connect(mapStateToProps, {
    // getStyle
})(TMContainer)



// import { connect } from "react-redux"
// import TopMenu from "./topMenu"


// let mapStateToProps = (state) => {
    
//     return {
    
//         itemsMenu: state.headerPage.itemsMenu
//     }
// }
// let mapDispatchToProps = (dispatch) => {
//     return {

//         }
//     }

// export const TMContainer = connect(mapStateToProps, mapDispatchToProps)(TopMenu);
// export default TMContainer;