import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getMeasuring } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class LevelsFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getMeasuring(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sMeasuring={this.props.sMeasuring}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sMeasuring: state.subItemsPage.sMeasuring
})

let WithUrlDataContainerComponent = withRouter(LevelsFPContainer)

export default connect(mapStateToProps, { getMeasuring })(WithUrlDataContainerComponent)
