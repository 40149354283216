import p from './phoneHeader.module.scss';
import phone from '../../../assets/images/svg/phone.svg'

function SocLink(props) {

    let logoElements = props.itemsSocContact.map(soccntcs =>
        <a rel="noreferrer" key={soccntcs.id} href={soccntcs.socLink}>
            <div>
                <div>
                    <img src={`${phone}`} alt="Телефон" />
                    <p>{soccntcs.phoneNomber}</p>
                    {/* {soccntcs.text} */}
                </div>
            </div>
        </a>
    )

    return (
        <div data-aos='fade-right' className={p.cont}>
            {logoElements}
        </div>
    )
}



export default SocLink
