import React from 'react'
import { connect } from 'react-redux'
import { getYamobury } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class infoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getYamobury(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sYamobury={this.props.sYamobury}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sYamobury: state.subItemsPage.sYamobury
})

let WithUrlDataContainerComponent = withRouter(infoContainer)

export default connect(mapStateToProps, { getYamobury })(WithUrlDataContainerComponent)
