import { connect } from "react-redux"
import SocLink from './SocLink'


let mapStateToProps = (state) => {
    
    return {

        itemsSocContact: state.headerPage.itemsSocialContacts
    }
}
let mapDispatchToProps = (dispatch) => {
    return {

        }
    }

export const SLContainer = connect(mapStateToProps, mapDispatchToProps)(SocLink);
export default SLContainer;