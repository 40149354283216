import { hotelApi } from "../api/api"

const OFFER = 'OFFER'
const SET_OFFER = 'SET-OFFER'


let initionState = {
    offerItems: [
    ],
     sOffer: null,
}

const offerReducer = (state = initionState, action) => {

    switch (action.type) {
        case OFFER: {
            return { ...state, offerItems: [/*...state.offerItems,*/ ...action.offerItems]}
        }
        case SET_OFFER: {
            return {
                ...state,
                sOffer: action.sOffer
            }
        }
        default:
            return state;
    }
}

export const offerAC = (offerItems) => ({
    type: OFFER,
    offerItems
})
const setOffer = (sOffer) => ({
    type: SET_OFFER,
    sOffer
})
export const getOffer = ()  => (dispatch) => {
    hotelApi.getOffer().then(response => {
        dispatch(setOffer(response.data))
    })
}
export default offerReducer;