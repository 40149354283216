import React from 'react'
import { connect } from 'react-redux'
import { getVibroslats } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class InfoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getVibroslats(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sVibroslats={this.props.sVibroslats}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sVibroslats: state.subItemsPage.sVibroslats
})

let WithUrlDataContainerComponent = withRouter(InfoContainer)

export default connect(mapStateToProps, { getVibroslats })(WithUrlDataContainerComponent)
