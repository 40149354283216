import React from "react";
import { NavLink } from "react-router-dom";
import fs from './fullService.module.scss'
import Galerys from "../../../../hook/Galerys";
import BreadCrumbsService from "../../../elements/breadСrumbs/BreadCrumbsService";
import foto1 from '../../../../assets/images/jpegPng/pocKan1.jpg'
import foto2 from '../../../../assets/images/jpegPng/pocKan2.jpg'
import foto3 from '../../../../assets/images/jpegPng/pocKan3.jpg'
import foto4 from '../../../../assets/images/jpegPng/pocKan4.jpg'
import foto5 from '../../../../assets/images/jpegPng/pocKan5.jpg'
import foto6 from '../../../../assets/images/jpegPng/pocKan6.jpg'
import foto7 from '../../../../assets/images/jpegPng/pocKan7.jpg'
import foto8 from '../../../../assets/images/jpegPng/pocKan8.jpg'
import foto9 from '../../../../assets/images/jpegPng/pocKan9.jpg'


class NoServFService extends React.Component {

    componentDidUpdate() {
        var headertext = [];
        var headers = document.querySelectorAll("#miyazaki th"),
            tablebody = document.querySelector("#miyazaki tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }
    }
    componentDidMount() {
        var headertext = [];
        var headers = document.querySelectorAll("#miyazaki th"),
            tablebody = document.querySelector("#miyazaki tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }
    }


    render() {

        let noServ =
        {
            "about": {
                "page": [
                    {
                        "id": "1",
                        "title": "Прочистка канализации",
                        "text": "Пpoчиcтка кaнализaции, уcтранение зaсoров, oтогрев кaнaлизации кpуглocутoчнo, недорoго. Служба по прочистке канализации выпoлнит пpочиcтку, пpомывку, очиcтку кaнализaции и колoдцeв професcионaльным обopудовaниeм. Устрaнениe зaсоров канализации любoй сложнoсти. приедeм в удoбнoе для Вас время."
                    },
                    {
                        "id": "2",
                        "title": "Гидродинамическая чистка труб",
                        "text": "Механическая обработка внутренней полости труб струей воды под высоким давлением (обычно в интервале 100-200 атмосфер). Под сильным напором поверхность ошкуривается, как если бы вы работали щеткой."
                    },
                    {
                        "id": "3",
                        "title": "Механическая чистка труб",
                        "text": "Технология устранения засоров с помощью гибкого металлического троса. Его вводят в полость трубопроводов до момента контакта с пробкой. Трос вращают вокруг продольной оси, погружая его конец в тело засора. От этого пробка разрушается и понемногу смывается в трубу или выводится наружу. Рабочий конец троса снабжается наконечником."
                    },
                    {
                        "id": "4",
                        "title": "Видео инспекция труб",
                        "text": "Видеоинспекция трубопроводов-это форма телеприсутствия, используемая для визуального осмотра внутренних помещений трубопроводов, водопроводных систем и ливневых стоков. Распространенным применением сантехника является определение состояния канализационных линий малого диаметра и подключение бытовых дренажных труб"
                    },
                    {
                        "id": "5",
                        "title": "Заключаем договора с организациями",
                        "text": "Заключаем договора с организациями."
                    }
                ],
                "full": [
                    {
                        "id": "1",
                        "title": "Ищите отличных специалистов?",
                        "text": "Ищите услуги прочистки канализации в Анапе? Лучше всего сразу нанять профессионалов, обладающих экспертными знаниями в сфере техники и инструмента, необходимого для работы. По всем вопросам, связанным с профессиональной прочисткой канализации (Гидродинамическая Чистка, Механическая чистка, Видео инспекция труб), вы можете обращаться в КрайПрокат93.ру."
                    }
                ],
                "mini": [
                    {
                        "id": "1",
                        "title": "Прочистка канализации",
                        "text": "Ищите услуги прочистки канализации в Анапе? Лучше всего сразу нанять профессионалов."
                    }
                ],
                "ul": [
                    {
                        "id": "1",
                        "title": "Цены на выполнение работ по прочистки канализации",
                        "headerTable": [
                            {
                                "text": "Вид услуги"
                            },
                            {
                                "text": "Цена"
                            },
                            {
                                "text": "+ за след. М"
                            }
                        ],
                        "text": [
                            {
                                "id": "1",
                                "text": "Видео инспекция труб до 10м",
                                "price": "2500",
                                "more": "250"
                            },
                            {
                                "id": "2",
                                "text": "Гидродинамическая чистка труб до 10м",
                                "price": "3000",
                                "more": "300"
                            },
                            {
                                "id": "3",
                                "text": "Механическая чистка труб до 10м",
                                "price": "2000",
                                "more": "150"
                            }
                        ]
                    }
                ]
            },
            "_id": "6gght233s",
            "title": "Прочистка канализации",
            "imgSlid": [
                {
                    "id": "1",
                    "img": `${foto1}`
                },
                {
                    "id": "2",
                    "img": `${foto2}`
                },
                {
                    "id": "3",
                    "img": `${foto3}`
                },
                {
                    "id": "4",
                    "img": `${foto4}`
                },
                {
                    "id": "5",
                    "img": `${foto5}`
                },
                {
                    "id": "6",
                    "img": `${foto6}`
                },
                {
                    "id": "7",
                    "img": `${foto7}`
                },
                {
                    "id": "8",
                    "img": `${foto8}`
                },
                {
                    "id": "9",
                    "img": `${foto9}`
                }
            ],
            "picture": "https://cdn-ru.bitrix24.ru/b17255068/landing/0e5/0e5af2a554766dea8452ab478834ca30/title3_1x.jpg"
        }

        let service = noServ

        let title = service.title
        let aboutPage = service.about.page
        let aboutFull = service.about.full
        let aboutMini = service.about.mini
        let aboutUl = service.about.ul
        let slidImage = service.imgSlid
        let topImage = service.picture

        return (
            <>
                <div key={noServ._id} className='mt05'>
                    <div className={fs.fonImage} style={{ backgroundImage: `url(${topImage})` }}>

                        <div><h2>{`${title}`}</h2>
                            {aboutMini.map(abM =>
                                <p key={abM.id}>
                                    {abM.text}
                                </p>
                            )}
                        </div>

                    </div>
                    <div className="main-cont">

                        <BreadCrumbsService><b>{title}</b></BreadCrumbsService>

                        <h2>{title}</h2>

                        {aboutFull.map(abF => <div key={abF.id}>
                            <h4>{abF.title}</h4>
                            <p>{abF.text}</p>
                        </div>)}
                        {aboutPage.map(abP => <div key={abP.id}>
                            <h4>{abP.title}</h4>
                            <p>{abP.text}</p>
                        </div>)}
                        <div>
                            <h4>
                                Телефон для заказа услуг:
                            </h4>
                            <a href='tel:+79184742231'>
                                <div style={{ display: `flex`, flexWrap: `wrap`, alignItems: `center`, justifyContent: `space-evenly` }}>
                                    <p className="p1" style={{ fontSize: `18px` }}>
                                        <b>Номер телефона: +7(918)474-22-31</b>
                                    </p>
                                    <div className="btns btn3 m1" style={{ margin: `0` }}>
                                        Заказать услугу...
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div style={{ display: `none` }}>
                            {aboutMini.map(abM => <div key={abM.id}>
                                <h4>{abM.title}</h4>
                                <p>{abM.text}</p>
                            </div>)}
                        </div>


                        <div>
                            <h3>Галерея</h3>
                            <div className={fs.galaryCont}>
                                <Galerys>
                                    {slidImage.map(sl =>
                                        <a href={sl.img} key={sl.id}>
                                            <img src={sl.img} alt={`${title} | Услуги КрайПрокат-93`} />
                                        </a>
                                    )}
                                </Galerys>
                            </div>
                            {aboutUl.map(abU =>
                                <div key={abU.id}>
                                    <h4 className={`pb1`} style={{ textAlign: `center`, fontSize: `1.3rem` }}>{abU.title}</h4>
                                    <table id="miyazaki">

                                        <thead>
                                            <tr>
                                                {abU.headerTable.map(ht =>
                                                    <th key={ht.id}>
                                                        {ht.text}
                                                    </th>
                                                )}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {abU.text.map(li =>
                                                <>
                                                    <tr key={li._id}>
                                                        <td data-th=''>{li.text}</td>
                                                        <td data-th=''>от {li.price} руб.</td>
                                                        <td data-th=''>{li.more} руб.</td>
                                                    </tr>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>

                        <div className={`dFlex ${fs.btnsCont}`}>
                            <NavLink to='/service'>
                                <button className={`btns btn3 pl05 pr05`} style={{ textAlign: `center`, width: `180px`, padding: `8px 0`, margin: `15px auto 10px`, borderColor: `#fff0` }}><span className={`blue-text text-lighten-5`}>Вернуться назад</span></button>
                            </NavLink>
                            <a href='tel:+79184742231'>
                                <button className={`btns btn3 pl05 pr05`} style={{ textAlign: `center`, width: `180px`, padding: `8px 0`, margin: `15px auto 10px`, borderColor: `#fff0` }}><span className={`blue-text text-lighten-5`}>Позвонить...</span></button>
                            </a>
                        </div>
                    </div>
                    {/* <CContainer /> */}
                </div>

            </>
        );
    }
}

export default NoServFService