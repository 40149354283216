import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import VibroslatsPContainer from './page/VibroslatsPContainer'

class Vibroslats extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Вибраторы для бетона. Виброрейки
                </h1>
                <BreadCrumbs>Вибраторы для бетона. Виброрейки</BreadCrumbs>
                <VibroslatsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Vibroslats
