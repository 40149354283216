import React from 'react';
import { NavLink } from 'react-router-dom';
import f from '../../../scss/full.module.scss'
import rub from '../../../../../assets/images/svg/ruble.svg'



function Infos(props) {
    if (!props.sBulgarian) {
        return <div></div>
    }
    let price = props.sBulgarian.price
    let rubs = <img style={{width:`0.45rem`, margin:`-2px 3px 0 `, height:`auto`,}}  src={`${rub}`} alt="рублей" />
    let links = [
        {
            id: 1,
            title: `Аренда`,
            textBtn: `Подробнее...`,
            price: `от ${price}`,
            priceN: ` в сутки`,
            link: `priceList`
        },
        {
            id: 2,
            title: `Доставка`,
            textBtn: `Подробнее...`,
            price: `от 400`,
            priceN: ` в черте города`,
            link: `deliveryList`
        }
    ]

    let cardItems = links.map(l =>
        <div key={l.id} className={`${f.delivery} dFlex`} style={{ alignItems: `flex-end` }}>
            <h3>
                {l.title}
            </h3>
            <div style={{ maxHeight: `60px`, height: `100%` }}>
                <p style={{textAlign:`center`}}>
                    {l.price}{rubs} {l.priceN}
                </p>
            </div>
            <NavLink  to={`/${l.link}`}>
                <div className={`btns btn3`}>
                    {l.textBtn}
                </div>
            </NavLink>
        </div>
    )
    return (
        <div className={`dFlex justifyContentCenter`} style={{ width: `100%` }}>
            {cardItems}
            <div className={`${f.delivery} dFlex`} style={{ alignItems: `flex-end` }}>
                <h3>
                    Оплата
                </h3>
                <div>
                    <p>
                        Различные способы оплаты
                    </p>
                </div>
                <NavLink to='/delivery'>
                    <div className={`btns btn3`}>
                        Подробнее...
                    </div>
                </NavLink>
            </div>
            <div className={`${f.delivery} dFlex`} style={{ alignItems: `flex-end`, minWidth: `170px`, justifyContent:`center` }}>
                <h3>
                    Аренда
                </h3>
                <div style={{maxHeight: `60px`, height: `100%`}}>
                    <p style={{fontSize:`0.85rem`}}>
                        +7 (989) 242-93-93
                    </p>
                </div>
                <a href='tel:+79892429393'>
                    <div className={`btns btn3`}>
                        Позвонить
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Infos;