import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getYamobury } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'

class YFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getYamobury(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sYamobury={this.props.sYamobury}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sYamobury: state.subItemsPage.sYamobury
})

let WithUrlDataContainerComponent = withRouter(YFPContainer)

export default connect(mapStateToProps, { getYamobury })(WithUrlDataContainerComponent)
