import React from 'react'
import { connect } from 'react-redux'
import { getBulgarian } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class InfoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getBulgarian(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sBulgarian={this.props.sBulgarian}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sBulgarian: state.subItemsPage.sBulgarian
})

let WithUrlDataContainerComponent = withRouter(InfoContainer)

export default connect(mapStateToProps, { getBulgarian })(WithUrlDataContainerComponent)
