import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import DrillscrownsPContainer from './page/DrillscrownsPContainer'

class Drillscrowns extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Буры, коронки для перфораторов
                </h1>
                <BreadCrumbs>Буры, коронки для перфораторов</BreadCrumbs>
                <DrillscrownsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Drillscrowns
