
import VacuumsPage from './VacuumsPage'
import { connect } from "react-redux"
import { vacuumsAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        vacuumsItem: state.subItemsPage.vacuumsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        vacuumsHot(vacuumsItem) {
            dispatch(vacuumsAC(vacuumsItem))
        }
    }
}

export const VacuumsPContainer = connect(mapStateToProps, mapDispatchToProps)(VacuumsPage);
export default VacuumsPContainer;