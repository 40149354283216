import React from "react";
import { NavLink } from "react-router-dom";
import fs from './fullService.module.scss'
import Galerys from "../../../../hook/Galerys";
import BreadCrumbsService from "../../../elements/breadСrumbs/BreadCrumbsService";
import almaz1 from '../../../../assets/images/jpegPng/almaz (1).jpeg'
import almaz2 from '../../../../assets/images/jpegPng/almaz (2).jpg'
import almaz3 from '../../../../assets/images/jpegPng/almaz (3).jpg'
import almaz4 from '../../../../assets/images/jpegPng/almaz (4).jpg'
import almaz5 from '../../../../assets/images/jpegPng/almaz (5).jpg'
import almaz6 from '../../../../assets/images/jpegPng/almaz (6).jpg'
import almaz7 from '../../../../assets/images/jpegPng/almaz (7).jpg'
import almaz8 from '../../../../assets/images/jpegPng/almaz (8).jpg'
import almaz9 from '../../../../assets/images/jpegPng/almaz (9).webp'
// function FullService(props) {
class FullService extends React.Component {

    componentDidUpdate() {

        var headertext = [];

        var headers = document.querySelectorAll("#miyazaki th"),
            tablebody = document.querySelector("#miyazaki tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }

        var headertext = [];

        var headers = document.querySelectorAll("#miyazaki2 th"),
            tablebody = document.querySelector("#miyazaki2 tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }

        var headertext = [];

        var headers = document.querySelectorAll("#miyazaki3 th"),
            tablebody = document.querySelector("#miyazaki3 tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }
        
        var headertext = [];

        var headers = document.querySelectorAll("#miyazaki4 th"),
            tablebody = document.querySelector("#miyazaki4 tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }
        
        var headertext = [];

        var headers = document.querySelectorAll("#miyazaki5 th"),
            tablebody = document.querySelector("#miyazaki5 tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }

        
        var headertext = [];

        var headers = document.querySelectorAll("#miyazaki6 th"),
            tablebody = document.querySelector("#miyazaki6 tbody");
        for (var i = 0; i < headers.length; i++) {
            var current = headers[i];
            headertext.push(current.textContent.replace(/\r?\n|\r/, ""));
        }

        for (var i = 0, row; (row = tablebody.rows[i]); i++) {
            for (var j = 0, col; (col = row.cells[j]); j++) {
                col.setAttribute("data-th", headertext[j]);

            }
        }
    }
    
    render() {

        if (!this.props.sService) {
            return <></>
        }
debugger

let imgSlid = {
            "id":"12554fgfhbvb12",
            "imgSlid":[
                            {
                                "id": "1",
                                "img": `${almaz1}`
                            },
                            {
                                "id": "2",
                                "img": `${almaz2}`
                            },
                            {
                                "id": "3",
                                "img": `${almaz3}`
                            },
                            {
                                "id": "4",
                                "img": `${almaz4}`
                            },
                            {
                                "id": "5",
                                "img": `${almaz5}`
                            },
                            {
                                "id": "6",
                                "img": `${almaz6}`
                            },
                            {
                                "id": "7",
                                "img": `${almaz7}`
                            },
                            {
                                "id": "8",
                                "img": `${almaz8}`
                            },
                            {
                                "id": "9",
                                "img": `${almaz9}`
                            }
                    ]
                }
        let slidImage = imgSlid.imgSlid

        let service = this.props.sService

        let title = service.title
        let aboutPage = service.about.page
        let aboutFull = service.about.full
        let aboutMini = service.about.mini
        let aboutUl = service.about.ul
        //let slidImage = imgSlid
        let topImage = service.picture
        
        return (
            <>
                <div key={service._id}>
                    <div className={fs.fonImage} style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER}${topImage})` }}>

                        <div><h2>{`${title}`}</h2>
                            {aboutMini.map(abM =>
                                <p key={abM.id}>
                                    {abM.text}
                                </p>
                            )}
                        </div>

                    </div>
                    <div className="main-cont">

                        <BreadCrumbsService><b>{title}</b></BreadCrumbsService>

                        <h2>{title}</h2>
                        {aboutPage.map(abP => <div key={abP.id}>
                            <h4>{abP.title}</h4>
                            <p>{abP.text}</p>
                        </div>)}
                        {aboutFull.map(abF => <div key={abF.id}>
                            <h4>{abF.title}</h4>
                            <p>{abF.text}</p>
                        </div>)}
                        <div style={{ display: `none` }}>
                            {aboutMini.map(abM => <div key={abM.id}>
                                <h4>{abM.title}</h4>
                                <p>{abM.text}</p>
                            </div>)}
                        </div>
                        <div>
                            <h4>
                                Телефон для заказа услуг:
                            </h4>
                            <a href='tel:+79883180826'>
                                <div style={{ display: `flex`, flexWrap: `wrap`, alignItems: `center`, justifyContent: `space-evenly` }}>
                                    <p className="p1" style={{ fontSize: `18px` }}>
                                        <b>Номер телефона: +7(988)318-08-26</b>
                                    </p>
                                    <div className="btns btn3 m1" style={{ margin: `0` }}>
                                        Заказать услугу...
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div>
                            <h3>Галерея</h3>
                            <div className={fs.galaryCont}>
                                <Galerys>
                                    {slidImage.map(sl =>
                                        <a href={sl.img} key={sl.id}>
                                            <img src={sl.img} alt={`${title} | Услуги КрайПрокат-93`} />
                                        </a>
                                    )}
                                </Galerys>
                            </div>
                            {aboutUl.map(abU =>
                                <div key={abU.id}>
                                    <h4 className={`pb1`} style={{ textAlign: `center`, fontSize: `1.3rem` }}>{abU.title}</h4>
                                    <table id="miyazaki">

                                        <thead>
                                            <tr>
                                                {abU.headerTable.map(ht =>
                                                    <th key={ht.id}>
                                                        {ht.text}
                                                    </th>
                                                )}

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {abU.text.map(li =>
                                                <>
                                                    <tr key={li._id}>
                                                        <td data-th=''>{li.number}</td>
                                                        <td data-th=''>{li.text}</td>
                                                        <td data-th=''>{li.price}</td>
                                                        <td data-th=''>от {li.price2} руб.</td>
                                                    </tr>
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                           <div>
                                
                                <br />
                                <h4 className={`pb1`} style={{ textAlign: `center`, fontSize: `1.3rem` }}>Демонтаж</h4>
                                <table id="miyazaki3">

                                <thead>
                                    <tr>
                                            <th>
                                                №
                                            </th>
                                            <th>
                                                Материал / услуга
                                            </th>
                                            <th>
                                                Ед. изм.
                                            </th>
                                            <th>
                                                Цена руб.
                                            </th>
                                    </tr>
                                </thead>
                                <tbody>
                                        <>
                                        <tr>
                                                <td data-th=''>1</td>
                                                <td data-th=''>Блок, кирпич, пеноблок, камень рыхлый</td>
                                                <td data-th=''>м³</td>
                                                <td data-th=''>6000</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>2</td>
                                                <td data-th=''>Бетон, Железобетон, плотный камень</td>
                                                <td data-th=''>м³</td>
                                                <td data-th=''>15000</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>3</td>
                                                <td data-th=''>Штукатурка</td>
                                                <td data-th=''>м³</td>
                                                <td data-th=''>8000</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>4</td>
                                                <td data-th=''>Стяжка (цементно-песчанная)</td>
                                                <td data-th=''>м³</td>
                                                <td data-th=''>5000</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>5</td>
                                                <td data-th=''>Плитка, ГКЛ (без клея)</td>
                                                <td data-th=''>м²</td>
                                                <td data-th=''>300</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>6</td>
                                                <td data-th=''>Окна, двери без сохранения</td>
                                                <td data-th=''>м²</td>
                                                <td data-th=''>300</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>7</td>
                                                <td data-th=''>Окна, двери с сохранением</td>
                                                <td data-th=''>м²</td>
                                                <td data-th=''>700</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>8</td>
                                                <td data-th=''>Услуги отбойного молотка</td>
                                                <td data-th=''>час</td>
                                                <td data-th=''>1000</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>9</td>
                                                <td data-th=''>Подготовительные работы</td>
                                                <td data-th=''>час</td>
                                                <td data-th=''>600</td>
                                            </tr>
                                            <tr>
                                                <td data-th=''>10</td>
                                                <td data-th=''>Простой мастера по вине заказчика (согласуется менеджером)</td>
                                                <td data-th=''>час</td>
                                                <td data-th=''>500</td>
                                            </tr>

                                        </>
                                </tbody>
                                </table> 
<br />
                                <div className={`dFlex miya ${fs.btnsCont}`}>
                                    <div>
                                        <table id="miyazaki4">
                                            <thead>
                                                <tr>
                                                        <th>
                                                            Коронки 18-90 (мм)
                                                        </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    <>
                                                        <tr>
                                                            <td data-th=''>Ø-18</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-20</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-25</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-28</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-32</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-36</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-42</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-50</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-60</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-70</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-77</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>Ø-80</td>
                                                        </tr>
                                                    </>
                                            </tbody>
                                        </table> 
                                    </div>
                                    <div>
                                        <table id="miyazaki5">
                                            <thead>
                                                <tr>
                                                        <th>
                                                        Ø отверстия (мм)
                                                        </th>
                                                        <th>
                                                            Блок, кирпич**
                                                        </th>
                                                        <th>
                                                            Бетон, камень**
                                                        </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    <>
                                                        <tr>
                                                            <td data-th=''>18-90</td>
                                                            <td data-th=''>20</td>
                                                            <td data-th=''>30</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>102</td>
                                                            <td data-th=''>22</td>
                                                            <td data-th=''>34</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>112</td>
                                                            <td data-th=''>25</td>
                                                            <td data-th=''>37</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>122</td>
                                                            <td data-th=''>27</td>
                                                            <td data-th=''>40</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>127</td>
                                                            <td data-th=''>28</td>
                                                            <td data-th=''>42</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>132</td>
                                                            <td data-th=''>29</td>
                                                            <td data-th=''>44</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>142</td>
                                                            <td data-th=''>31</td>
                                                            <td data-th=''>47</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>152</td>
                                                            <td data-th=''>33</td>
                                                            <td data-th=''>50</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>162</td>
                                                            <td data-th=''>36</td>
                                                            <td data-th=''>53</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>172</td>
                                                            <td data-th=''>38</td>
                                                            <td data-th=''>57</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>182</td>
                                                            <td data-th=''>40</td>
                                                            <td data-th=''>60</td>
                                                        </tr>                                                 
                                                    </>
                                            </tbody>
                                        </table> 
                                    </div>
                                    <div>
                                    <table id="miyazaki6">
                                            <thead>
                                                <tr>
                                                        <th>
                                                        Ø отверстия (мм)
                                                        </th>
                                                        <th>
                                                            Блок, кирпич**
                                                        </th>
                                                        <th>
                                                            Бетон, камень**
                                                        </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                    <>
                                                        <tr>
                                                            <td data-th=''>192</td>
                                                            <td data-th=''>42</td>
                                                            <td data-th=''>63</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>200</td>
                                                            <td data-th=''>44</td>
                                                            <td data-th=''>66</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>225</td>
                                                            <td data-th=''>49</td>
                                                            <td data-th=''>74</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>250</td>
                                                            <td data-th=''>55</td>
                                                            <td data-th=''>82</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>270</td>
                                                            <td data-th=''>59</td>
                                                            <td data-th=''>89</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>300</td>
                                                            <td data-th=''>66</td>
                                                            <td data-th=''>99</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>325</td>
                                                            <td data-th=''>71</td>
                                                            <td data-th=''>107</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>350</td>
                                                            <td data-th=''>77</td>
                                                            <td data-th=''>115</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>370</td>
                                                            <td data-th=''>81</td>
                                                            <td data-th=''>122</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>400</td>
                                                            <td data-th=''>88</td>
                                                            <td data-th=''>132</td>
                                                        </tr>
                                                        <tr>
                                                            <td data-th=''>500</td>
                                                            <td data-th=''>110</td>
                                                            <td data-th=''>165</td>
                                                        </tr>                                                 
                                                    </>
                                            </tbody>
                                        </table> 
                                    </div>
                                </div>
                                <br />
                                <p>
                                *Стоимость отверствия определяется исходя из фактической глубины, но не менее 10 см.
                                </p>
                                <p>
                                **Стоимость в рублях за 1 см³ глубины сверления.
                                </p>
                                <h3>
                                    <u>Условия, необходимые для выполнения работ</u>
                                </h3>
                                <ul>
                                    <li>обеспечение на объекте монтожа: электропитания ~220V, мочность от 5 кВт/ч (для одной установки алмазного сверления)</li>
                                    <li>определиние центра отверствия, его разметка и диаметр</li>
                                    <li>наличие воды на расстоянии не более 50м</li>
                                    <li>согласование по времени работ</li>
                                </ul>
                                <h3>Дополнительные услуги</h3>
                             <table id="miyazaki2">

                                        <thead>
                                            <tr>
                                                    <th>
                                                        №
                                                    </th>
                                                    <th>
                                                        Наименование
                                                    </th>
                                                    <th>
                                                        Значение
                                                    </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                <>
                                                <tr>
                                                        <td data-th=''>K¹</td>
                                                        <td data-th=''>Чистое сверление (с пылесосом)</td>
                                                        <td data-th=''>+50%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K²</td>
                                                        <td data-th=''>Сверление под углом к поверхности</td>
                                                        <td data-th=''>+20%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K³</td>
                                                        <td data-th=''>Вертикальное сверление (с улавливанием керна)</td>
                                                        <td data-th=''>+20%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K⁴</td>
                                                        <td data-th=''>Работа на высоте от 2 до 3,5м</td>
                                                        <td data-th=''>+10%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K⁵</td>
                                                        <td data-th=''>Сверление бетона без крепления анкером (вакуумное крепление)</td>
                                                        <td data-th=''>+30%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K⁶</td>
                                                        <td data-th=''>Сверление (снизу-вверх)</td>
                                                        <td data-th=''>+100%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K⁷</td>
                                                        <td data-th=''>Определение точки выхода отверствий и поиск проводки</td>
                                                        <td data-th=''>300 руб./точка</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K⁸</td>
                                                        <td data-th=''>Проведение работ в ночное время суток с 20:00 до 6:00</td>
                                                        <td data-th=''>+50%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K⁹</td>
                                                        <td data-th=''>Повышенное содержание арматуры, толщина арматурного стержня ≥18мм</td>
                                                        <td data-th=''>+20%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K¹⁰</td>
                                                        <td data-th=''>Работа в труднодоступных, опасных местах или в стеснённых условиях</td>
                                                        <td data-th=''>+20%</td>
                                                    </tr>
                                                    <tr>
                                                        <td data-th=''>K¹¹</td>
                                                        <td data-th=''>Работы за пределами Анапского района</td>
                                                        <td data-th=''>+10%</td>
                                                    </tr>
                                                </>
                                        </tbody>
                        </table> 
                            </div>
                        </div>

                        <div className={`dFlex ${fs.btnsCont}`}>
                            <NavLink to='/service'>
                                <button className={`btns btn3 pl05 pr05`} style={{ textAlign: `center`, width: `180px`, padding: `8px 0`, margin: `15px auto 10px`, borderColor: `#fff0` }}><span className={`blue-text text-lighten-5`}>Вернуться назад</span></button>
                            </NavLink>
                            <a href='tel:+79883180826'>
                                <button className={`btns btn3 pl05 pr05`} style={{ textAlign: `center`, width: `180px`, padding: `8px 0`, margin: `15px auto 10px`, borderColor: `#fff0` }}><span className={`blue-text text-lighten-5`}>Позвонить...</span></button>
                            </a>
                        </div>
                    </div>
                    {/* <CContainer /> */}
                    <script>

                    </script>
                </div>

            </>
        );
    }
}

export default FullService