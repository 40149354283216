
import LevelsPage from './LevelsPage'
import { connect } from "react-redux"
import { measuringAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        measuringItem: state.subItemsPage.measuringItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        measuringHot(measuringItem) {
            dispatch(measuringAC(measuringItem))
        }
    }
}

export const MetalcuttingsPContainer = connect(mapStateToProps, mapDispatchToProps)(LevelsPage);
export default MetalcuttingsPContainer;