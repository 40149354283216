import React from 'react';
import { SRLWrapper } from "simple-react-lightbox"
function Galary({ children }) {

  return (
    <SRLWrapper>
        <div className='contPhotoGalary'>
            {children}
        </div>
      
    </SRLWrapper>
    )
}

export default (Galary);