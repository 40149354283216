import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import RohliPContainer from './page/RohliPContainer'

class Rohli extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Рохли, Тельфер, Таль, Домкрат
                </h1>
                <BreadCrumbs>Рохли, Тельфер, Таль, Домкрат</BreadCrumbs>
                <RohliPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Rohli
