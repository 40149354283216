import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import EquipaircondsPContainer from './page/EquipaircondsPContainer'

class Equipairconds extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Оборудование для монтажа кондиционеров
                </h1>
                <BreadCrumbs>Оборудование для монтажа кондиционеров</BreadCrumbs>
                <EquipaircondsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Equipairconds
