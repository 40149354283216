import React from 'react'
import { connect } from 'react-redux'
import { getConsumables } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class InfoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getConsumables(id)

   }

   render() {
      return (
         <>
            <Infos
               {...this.props}
               sConsumables={this.props.sConsumables}
            />
         </>
      )
   }
}
let mapStateToProps = (state) => ({
   sConsumables: state.subItemsPage.sConsumables
})

let WithUrlDataContainerComponent = withRouter(InfoContainer)

export default connect(mapStateToProps, { getConsumables })(WithUrlDataContainerComponent)
