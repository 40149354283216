import { connect } from "react-redux"
import Delivery from "./Delivery";


let mapStateToProps = (state) => {

    return {

    }
}

export const DeliveryContainer = connect(mapStateToProps)(Delivery);
export default DeliveryContainer;