import React from 'react'

function MapFooter(props) {

    return (
        <>
        
        <div style={{ position: `relative`, overflow: `hidden` }}>
            <a href="https://yandex.ru/maps/1107/anapa/?utm_medium=mapframe&utm_source=maps" style={{ color: `#eee`, fontSize: `0.8rem`, position: `absolute`, top: `0px` }}>
                Анапа
            </a>
            <a href="https://yandex.ru/maps/1107/anapa/?from=api-maps&ll=37.364052%2C44.899656&mode=usermaps&origin=jsapi_2_1_79&um=constructor%3ACj3iZKziW3YjmEAJopweK_IVKvEzID5I&utm_medium=mapframe&utm_source=maps&z=16" style={{ color: `#eee`, fontSize: `1rem`, position: `absolute`, top: `14px` }}>
                Яндекс Карты — транспорт, навигация, поиск мест. Аренда строительного инструмента и оборудования в Анапе.
            </a>
            <iframe title='Карта Footer' src="https://yandex.ru/map-widget/v1/-/CCUJR8BM~C" width="100%" height="200px" frameBorder="1" style={{ position: `relative` }}></iframe>
        </div>
        </>
        
    )
}



export default MapFooter
