import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import LevelsPContainer from './page/LevelsPContainer'

class Levels extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Уровни, нивелиры, измерительные приборы
                </h1>
                <BreadCrumbs>Уровни, нивелиры, измерительные приборы</BreadCrumbs>
                <LevelsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Levels
