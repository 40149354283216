
import PampsPage from './PampsPage'
import { connect } from "react-redux"
import { pumpsAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        pumpsItem: state.subItemsPage.pumpsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        pumpsHot(pumpsItem) {
            dispatch(pumpsAC(pumpsItem))
        }
    }
}

export const JHPContainer = connect(mapStateToProps, mapDispatchToProps)(PampsPage);
export default JHPContainer;