import UlEl from "../elements/ul/UlEl"
import fon from '../../assets/images/jpegPng/devil.jpg'
import c from './dev.module.scss'
// import DeliveryEl from "./element/DeliveryEl"
import PayEl from "./element/PayEl"
import BreadСrumbsHome from "../elements/breadСrumbsHome/BreadСrumbsHome"
import React from "react"
import { seo } from '../../hook/seo'

class Delivery extends React.Component {
    componentDidMount() {
        seo({
            title: 'КрайПрокат-93 | Оплата и доставка | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе',
            metaDescription: 'КрайПрокат-93 | Оплата и доставка | город Анапа | Аренда строительного инструмента В Анапе | Прокат инструментов В Анапе'
        });
    }
    render() {
        return (
            <div>
                <div className={`${c.imgContPrew}`} style={{ backgroundImage: `url(${fon})` }}>
                    <div style={{ maxWidth: `1400px`, width: `100%`, margin: `0 auto` }}>
                        <h1 className='p2 tACenter' >
                            Оплата
                        </h1>
                    </div>
                </div>
                <div style={{ maxWidth: `1170px`, margin: `auto`, width: `100%` }} className={`p1`}>
                    <BreadСrumbsHome>
                        Оплата
                    </BreadСrumbsHome>
                    <PayEl />
                    {/* <DeliveryEl /> */}
                </div>
            </div>
        )
    }
}

export default Delivery