import React from "react";
import { connect } from 'react-redux'
import { getСategory } from '../../../redux/categoriesReduser'
import FRSlider from "./FRSlider";



class DSTContainer extends React.Component {

    componentDidMount() {
        this.props.getСategory()
    }

    render() {

        return (
            <>
                <FRSlider
                    {...this.props}
                    sСategory={this.props.sСategory}
                />
            </>
        )
    }
}

let mapStateToProps = (state) => ({
    sСategory: state.categoriesPage.sСategory
})


export default connect(mapStateToProps, {
    getСategory
})(DSTContainer)
