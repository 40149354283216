
import FormworkequsPage from './FormworkequsPage'
import { connect } from "react-redux"
import { formworkequipmentAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        formworkequipmentItem: state.subItemsPage.formworkequipmentItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        formworkequipmentHot(formworkequipmentItem) {
            dispatch(formworkequipmentAC(formworkequipmentItem))
        }
    }
}

export const FormworkequsPContainer = connect(mapStateToProps, mapDispatchToProps)(FormworkequsPage);
export default FormworkequsPContainer;