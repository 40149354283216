import React from 'react'
import { connect } from 'react-redux'
import { getMetalcutting } from '../../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'
import Infos from './Infos'



class InfoContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getMetalcutting(id)

   }

   render() {
      return (
 <>
          <Infos 
         {...this.props} 
         sMetalcutting={this.props.sMetalcutting}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sMetalcutting: state.subItemsPage.sMetalcutting
})

let WithUrlDataContainerComponent = withRouter(InfoContainer)

export default connect(mapStateToProps, { getMetalcutting })(WithUrlDataContainerComponent)
