import s from "./socNet.module.scss";
import React from "react";

function SocNetwork(props) {
  // if (!props.sStyle) {
  //   return <div>Загрузка стилей...</div>;
  // }
  let logoElements = props.itemSocNet.map((soccntcs) => (
    <a target="_blank" rel="noreferrer" key={soccntcs.id} href={soccntcs.link}>
      <div className={`ml05 mr05 ${s.items}`} style={{}}>
        <svg
          className={`${s.svgCont}  ${soccntcs.style}-fill fill-lighten-4`}
          width={`${soccntcs.image.width}px`}
          height={`${soccntcs.image.height}px`}
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 500 500"
        >
          <g>
            {soccntcs.image.path.map((g) => (
              <path key={g.id} d={g.path} />
            ))}
          </g>
        </svg>

        {/* {soccntcs.image}
                {soccntcs.text} */}
      </div>
    </a>
  ));
  return (
    <div className={`${s.cont}`}>
      <div className={`${s.contSocIcon}`}>
        {logoElements}
      </div>
    </div>
  );
}

export default SocNetwork;
