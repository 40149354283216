
import YamoburysPage from './YamoburysPage'
import { connect } from "react-redux"
import { yamoburyAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        yamoburyItem: state.subItemsPage.yamoburyItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        yamoburyHot(yamoburyItem) {
            dispatch(yamoburyAC(yamoburyItem))
        }
    }
}

export const YPContainer = connect(mapStateToProps, mapDispatchToProps)(YamoburysPage);
export default YPContainer;