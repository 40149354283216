import React from 'react';



function OlEl(props)  {

  return (

    
        <ol  className={/*${props.sStyle.map(s => s.subject)}-text*/` pushs`}>
            {props.children}
        </ol>
    )
}

export default (OlEl);