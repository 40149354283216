import { connect } from "react-redux"
import { 
    //1
    vibratingPlatesAC,
    //2
    vibroLegsAC,
    //3
    perforatorsAC,
    //4
    jackhammersAC,
    //5
    seamcuttersAC,
    //6
    grindingconAC,
    //7
    generatorsAC,
    //8
    thermalAC,
    //9
    compressorsAC,
    //10
    yamoburyAC,
    //11
    pumpsAC,
    //12
    concretmixAC,
    //13
    tilecutterAC,
    //14
    vibroslatsAC,
    //15
    screwdriversAC,
    //16
    rohliAC,
    //17
    airconditionAC,
    //18
    weldingAC,
    //19
    frasersAC,
    //20
    woodsandersAC,
    //21
    metalcuttingAC,
    //22
    measuringAC,
    //23
    chainsawsAC,
    //24
    vacuumsAC,
    //25
    bulgarianAC,
    //26
    washingequipmentAC,
    //27
    plumbingequipmentAC,
    //28
    scaffoldingAC,
    //29
    formworkequipmentAC,
    //30
    stairstourAC,
    //31
    wiringequipmentAC,
    //32
    drillsAC,
    //33
    sportequipmentAC,
    //34
    boersAC,
    //35
    miscellaneousAC,
    //36
    consumablesAC

 } from '../../../redux/subItemsReduser'
import PriceList from './PriceList'

let mapStateToProps = (state) => {

    return {
        //1
        vibratingPlatesItem: state.subItemsPage.vibratingPlatesItem,
        //2
        vibroLegsItem: state.subItemsPage.vibroLegsItem,
        //3
        perforatorsItem: state.subItemsPage.perforatorsItem,
        //4
        jackhammersItem: state.subItemsPage.jackhammersItem,
        //5
        seamcuttersItem: state.subItemsPage.seamcuttersItem,
        //6
        grindingconItem: state.subItemsPage.grindingconItem,
        //7
        generatorsItem: state.subItemsPage.generatorsItem,
        //8
        thermalItem: state.subItemsPage.thermalItem,
        //9
        compressorsItem: state.subItemsPage.compressorsItem,
        //10
        yamoburyItem: state.subItemsPage.yamoburyItem,
        //11
        pumpsItem: state.subItemsPage.pumpsItem,
        //12
        concretmixItem: state.subItemsPage.concretmixItem,
        //13
        tilecutterItem: state.subItemsPage.tilecutterItem,
        //14
        vibroslatsItem: state.subItemsPage.vibroslatsItem,
        //15
        screwdriversItem: state.subItemsPage.screwdriversItem,
        //16
        rohliItem: state.subItemsPage.rohliItem,
        //17
        airconditionItem: state.subItemsPage.airconditionItem,
        //18
        weldingItem: state.subItemsPage.weldingItem,
        //19
        frasersItem: state.subItemsPage.frasersItem,
        //20
        woodsandersItem: state.subItemsPage.woodsandersItem,
        //21
        metalcuttingItem: state.subItemsPage.metalcuttingItem,
        //22
        measuringItem: state.subItemsPage.measuringItem,
        //23
        chainsawsItem: state.subItemsPage.chainsawsItem,
        //24
        vacuumsItem: state.subItemsPage.vacuumsItem,
        //25
        bulgarianItem: state.subItemsPage.bulgarianItem,
        //26
        washingequipmentItem: state.subItemsPage.washingequipmentItem,
        //27
        plumbingequipmentItem: state.subItemsPage.plumbingequipmentItem,
        //28
        scaffoldingItem: state.subItemsPage.scaffoldingItem,
        //29
        formworkequipmentItem: state.subItemsPage.formworkequipmentItem,
        //30
        stairstourItem: state.subItemsPage.stairstourItem,
        //31
        wiringequipmentItem: state.subItemsPage.wiringequipmentItem,
        //32
        drillsItem: state.subItemsPage.drillsItem,
        //33
        sportequipmentItem: state.subItemsPage.sportequipmentItem,
        //34
        boersItem: state.subItemsPage.boersItem,
        //35
        miscellaneousItem: state.subItemsPage.miscellaneousItem,
        //36
        consumablesItem: state.subItemsPage.consumablesItem
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        //1
        vPlatesHot(vibratingPlatesItem) {
            dispatch(vibratingPlatesAC(vibratingPlatesItem))
        },
        //2
        vLHot(vibroLegsItem) {
            dispatch(vibroLegsAC(vibroLegsItem))
        },
        //3
        perforatorsHot(perforatorsItem) {
            dispatch(perforatorsAC(perforatorsItem))
        },
        //4
        jackhammersHot(jackhammersItem) {
            dispatch(jackhammersAC(jackhammersItem))
        },
        //5
        seamcuttersHot(seamcuttersItem) {
            dispatch(seamcuttersAC(seamcuttersItem))
        },
        //6
        grindingconHot(grindingconItem) {
            dispatch(grindingconAC(grindingconItem))
        },
        //7
        generatorsHot(generatorsItem) {
            dispatch(generatorsAC(generatorsItem))
        },
        //8
        thermalHot(thermalItem) {
            dispatch(thermalAC(thermalItem))
        },
        //9
        compressorsHot(compressorsItem) {
            dispatch(compressorsAC(compressorsItem))
        },
        //10
        yamoburyHot(yamoburyItem) {
            dispatch(yamoburyAC(yamoburyItem))
        },
        //11
        pumpsHot(pumpsItem) {
            dispatch(pumpsAC(pumpsItem))
        },
        //12
        concretmixHot(concretmixItem) {
            dispatch(concretmixAC(concretmixItem))
        },
        //13
        tilecutterHot(tilecutterItem) {
            dispatch(tilecutterAC(tilecutterItem))
        },
        //14
        vibroslatsHot(vibroslatsItem) {
            dispatch(vibroslatsAC(vibroslatsItem))
        },
        //15
        screwdriversHot(screwdriversItem) {
            dispatch(screwdriversAC(screwdriversItem))
        },
        //16
        rohliHot(rohliItem) {
            dispatch(rohliAC(rohliItem))
        },
        //17
        airconditionHot(airconditionItem) {
            dispatch(airconditionAC(airconditionItem))
        },
        //18
        weldingHot(weldingItem) {
            dispatch(weldingAC(weldingItem))
        },
        //19
        frasersHot(frasersItem) {
            dispatch(frasersAC(frasersItem))
        },
        //20
        woodsandersHot(woodsandersItem) {
            dispatch(woodsandersAC(woodsandersItem))
        },
        //21
        metalcuttingHot(metalcuttingItem) {
            dispatch(metalcuttingAC(metalcuttingItem))
        },
        //22
        measuringHot(measuringItem) {
            dispatch(measuringAC(measuringItem))
        },
        //23
        chainsawsHot(chainsawsItem) {
            dispatch(chainsawsAC(chainsawsItem))
        },
        //24
        vacuumsHot(vacuumsItem) {
            dispatch(vacuumsAC(vacuumsItem))
        },
        //25
        bulgarianHot(bulgarianItem) {
            dispatch(bulgarianAC(bulgarianItem))
        },
        //26
        washingequipmentHot(washingequipmentItem) {
            dispatch(washingequipmentAC(washingequipmentItem))
        },
        //27
        plumbingequipmentHot(plumbingequipmentItem) {
            dispatch(plumbingequipmentAC(plumbingequipmentItem))
        },
        //28
        scaffoldingHot(scaffoldingItem) {
            dispatch(scaffoldingAC(scaffoldingItem))
        },
        //29
        formworkequipmentHot(formworkequipmentItem) {
            dispatch(formworkequipmentAC(formworkequipmentItem))
        },
        //30
        stairstourHot(stairstourItem) {
            dispatch(stairstourAC(stairstourItem))
        },
        //31
        wiringequipmentHot(wiringequipmentItem) {
            dispatch(wiringequipmentAC(wiringequipmentItem))
        },
        //32
        drillsHot(drillsItem) {
            dispatch(drillsAC(drillsItem))
        },
        //33
        sportequipmentHot(sportequipmentItem) {
            dispatch(sportequipmentAC(sportequipmentItem))
        },
        //34
        boersHot(boersItem) {
            dispatch(boersAC(boersItem))
        },
        //35
        miscellaneousHot(miscellaneousItem) {
            dispatch(miscellaneousAC(miscellaneousItem))
        },
        //36
        consumablesHot(consumablesItem) {
            dispatch(consumablesAC(consumablesItem))
        },

    }
}

export const PLContainer = connect(mapStateToProps, mapDispatchToProps)(PriceList);
export default PLContainer;