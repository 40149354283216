
import ScrewdriversPage from './ScrewdriversPage'
import { connect } from "react-redux"
import { screwdriversAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        screwdriversItem: state.subItemsPage.screwdriversItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        screwdriversHot(screwdriversItem) {
            dispatch(screwdriversAC(screwdriversItem))
        }
    }
}

export const ScrewdriversPContainer = connect(mapStateToProps, mapDispatchToProps)(ScrewdriversPage);
export default ScrewdriversPContainer;