import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import DrillsPContainer from './page/DrillsPContainer'

class Drills extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Дрели
                </h1>
                <BreadCrumbs>Дрели</BreadCrumbs>
                <DrillsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Drills
