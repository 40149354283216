import React from "react";
import { connect } from 'react-redux'
// import { getStyle } from '../../redux/styleReducer'
import Contacts from "./Contacts";
// import { getRoom } from '../../redux/roomsReduser'


class CContainer extends React.Component {

    componentDidMount() {
        // this.props.getStyle()
    }

    render() {

        return (
            <>
                <Contacts
                    {...this.props}
                    // sStyle={this.props.sStyle}
                    // sRoom={this.props.sRoom}
                />
            </>
        )
    }
}

let mapStateToProps = (state) => ({
    // sStyle: state.styleModule.sStyle,
    // sRoom: state.styleModule.sRoom,
})

export default connect(mapStateToProps,{
    // getStyle,
    //getRoom
})(CContainer)