
import JackhammersPage from './GeneratorsPage'
import { connect } from "react-redux"
import { generatorsAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        generatorsItem: state.subItemsPage.generatorsItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        generatorsHot(generatorsItem) {
            dispatch(generatorsAC(generatorsItem))
        }
    }
}

export const GPContainer = connect(mapStateToProps, mapDispatchToProps)(JackhammersPage);
export default GPContainer;