
import ExpendablesPage from './ExpendablesPage'
import { connect } from "react-redux"
import { consumablesAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        consumablesItem: state.subItemsPage.consumablesItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        consumablesHot(consumablesItem) {
            dispatch(consumablesAC(consumablesItem))
        }
    }
}

export const OthersPContainer = connect(mapStateToProps, mapDispatchToProps)(ExpendablesPage);
export default OthersPContainer;