import React from 'react'
import { NavLink } from 'react-router-dom'
import BreadCrumbs from '../elements/breadСrumbs/BreadCrumbs'
import c from './scss/categ.module.scss'
import fon from '../../assets/images/jpegPng/categ.jpg'
import BreadСrumbsHome from '../elements/breadСrumbsHome/BreadСrumbsHome'
require('dotenv').config()

// import card from './card.module.scss'

function CategPage(props) {

    if (!props.sСategory) {
        return <div>Загрузка Категорий...</div>
    }


    let caregItem = props.sСategory.map(item =>
        <div key={item._id} className={`m1 ${c.cardCont}`} >
            <NavLink className='dFlex noWrap fDirect justifyContentSpaceBetween' style={{ width: `100%`, height: `100%` }} to={`/categories/` + `${item.link}`}>
                <div style={{ maxWidth: `230px`, width: `100%` }}>
                    <center> <img src={`${process.env.REACT_APP_SERVER}${item.picture}`} alt={item.title} />
                    </center>
                </div>
                <div className={`${c.textCont}`}>
                    <p>{item.title}</p>
                </div>
            </NavLink>
        </div >
    )

    return (
        <div >
            <div className={`${c.imgContPrew}`} style={{ backgroundImage: `url(${fon})` }}>
                <div style={{ maxWidth: `1400px`, width: `100%`, margin: `0 auto` }}>
                    <h1 className='p2 tACenter' >
                        Каталог оборудования
                    </h1>
                </div>
            </div>
            <div style={{ maxWidth: `1140px`, margin: `auto` }} className={`pt1`}>
                <div className='pt1'>
                    <BreadСrumbsHome>
                        Каталог оборудования
                    </BreadСrumbsHome>
                </div>
                <h2 className='p1' >
                    Каталог оборудования
                </h2>
                <div className='dFlex justifyContentSpaceEvenly' style={{ margin: `auto`, maxWidth: `1100px` }}>
                    {caregItem}
                </div>
            </div>
        </div>
    )
}


export default CategPage