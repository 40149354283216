import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getPlumbingequipment } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class PlumbingequsFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getPlumbingequipment(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sPlumbingequipment={this.props.sPlumbingequipment}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sPlumbingequipment: state.subItemsPage.sPlumbingequipment
})

let WithUrlDataContainerComponent = withRouter(PlumbingequsFPContainer)

export default connect(mapStateToProps, { getPlumbingequipment })(WithUrlDataContainerComponent)
