import * as axios from "axios"
require('dotenv').config()


const instance = axios.create({
    // baseURL: `${process.env.REACT_APP_SERVER}`,
    baseURL: process.env.REACT_APP_SERVER
})


export const hotelApi = {

    getСategory() {
        return instance.get(`categories/`)
    },
    getService(id) {
        return instance.get(`services/` + id)
    },
    /* subRoadBuildingEquipment start */
    //1 Вибротрамбовки (Виброноги)
    getVibroLegs(id) {
        return instance.get(`vibrorammers/` + id)
    },
    //2 Вибротрамбовки (Виброплиты)
    getVibratingPlates(id) {
    return instance.get(`subdvibroplits/` + id)
    },
    //3 Перфораторы
    getPerforators(id) {
        return instance.get(`perforators/` + id)
    },
    //4 Отбойные молотки
    getJackhammers(id) {
        return instance.get(`jackhammers/` + id)
    },
    //5 Штроборезы ручные, Швонарезчики
    getSeamcutters(id) {
        return instance.get(`seamcutters/` + id)
    },
    //6 Шлифовальные машины по бетону. (Вертолёты)
    getGrindingcon(id) {
        return instance.get(`grindings/` + id)
    },
    //7 Генераторы стабилизаторы
    getGenerators(id) {
        return instance.get(`generators/` + id)
    },
    //8 Тепловое оборудование
    getThermal(id) {
        return instance.get(`thermals/` + id)
    },
    //9 Компрессоры, краскопульты, пескоструй
    getCompressors(id) {
        return instance.get(`compressors/` + id)
    },
    //10 Садовое оборудование, Ямобуры
    getYamobury(id) {
        return instance.get(`yamoburys/` + id)
    },
    //11 Мотопомпы, Электро-насосы
    getPamps(id) {
        return instance.get(`pamps/` + id)
    },
    //12 Бетономешалки
    getConcretmix(id) {
        return instance.get(`concretmixs/` + id)
    },
    //13 Плиткорезы
    getTilecutter(id) {
        return instance.get(`tilecutters/` + id)
    },
    //14 Вибраторы для бетона. Виброрейки
    getVibroslats(id) {
        return instance.get(`vibroslats/` + id)
    },
    //15 Шуруповерты
    getScrewdrivers(id) {
        return instance.get(`screwdrivers/` + id)
    },
    //16 Рохли, Тельфер, Таль, Домкрат
    getRohli(id) {
        return instance.get(`rohlis/` + id)
    },
    //17 Оборудование для монтажа кондиционеров
    getAircondition(id) {
        return instance.get(`equipairconds/` + id)
    },
    //18 Сварочное оборудование
    getWelding(id) {
        return instance.get(`weldings/` + id)
    },
    //19 Рубанки, фрейзеры
    getFrasers(id) {
        return instance.get(`frasers/` + id)
    },
    //20 Шлифовальные машины по дереву
    getWoodsanders(id) {
        return instance.get(`woodsanders/` + id)
    },
    //21 Металлорежущие инструменты
    getMetalcutting(id) {
        return instance.get(`metalcuttings/` + id)
    },
    //22 Уровни, нивелиры, измерительные приборы
    getMeasuring(id) {
        return instance.get(`levels/` + id)
    },
    //23 Бензопилы. Лобзики. Торцевые пилы
    getChainsaws(id) {
        return instance.get(`jigsaws/` + id)
    },
    //24 Пылесосы
    getVacuums(id) {
        return instance.get(`vacuums/` + id)
    },
    //25 Болгарки
    getBulgarian(id) {
        return instance.get(`bulgarians/` + id)
    },
    //26 Моющее оборудование
    getWashingequipment(id) {
        return instance.get(`washingequs/` + id)
    },
    //27 Сантехническое оборудование
    getPlumbingequipment(id) {
        return instance.get(`plumbingequs/` + id)
    },
    //28 Строительные леса
    getScaffolding(id) {
        return instance.get(`scaffoldings/` + id)
    },
    //29 Опалубочное оборудование
    getFormworkequipmentg(id) {
        return instance.get(`formworkequs/` + id)
    },
    //30 Лестницы и вышки тура
    getStairstour(id) {
        return instance.get(`stairstours/` + id)
    },
    //31 Электромонтажное оборудование
    getWiringequipment(id) {
        return instance.get(`electricalinstalls/` + id)
    },
    //32 Дрели
    getDrills(id) {
        return instance.get(`drills/` + id)
    },
    //33 Спортивное снаряжение
    getSportequipment(id) {
        return instance.get(`sportequs/` + id)
    },
    //34 Буры, коронки для перфораторов
    getBoers(id) {
        return instance.get(`drillscrowns/` + id)
    },
    //35 Разное
    getMiscellaneous(id) {
        return instance.get(`others/` + id)
    },
    //36 Расходные Материалы
    getConsumables(id) {
        return instance.get(`expendables/` + id)
    },
    /* subRoadBuildingEquipment end */



    

    getRoom(linkMore) {
        return instance.get(`rooms/` + linkMore)
    },
    getPromotion(linkMore) {
        return instance.get(`promotions/` + linkMore)
    },
   
    // getStyle() {
    //     return instance.get(`thems/`)
    // },
    getAbout() {
        return instance.get(`about/`)
    },
    getGallery() {
        return instance.get(`gallery/`)
    },
    getBasement() {
        return instance.get(`basement/`)
    },
    getOffer() {
        return instance.get(`offer/`)
    },
    getBooking() {
        return instance.get(`booking/`)
    },
    getSMenu() {
        return instance.get(`smenu/`)
    },

}
export const itemsVibr = {
    getVibroPlita(linkMore) {
        return axios.get(`/vibroplidb.json/` + linkMore)
    },
}