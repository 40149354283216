
import PlumbingequsPage from './PlumbingequsPage'
import { connect } from "react-redux"
import { plumbingequipmentAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        plumbingequipmentItem: state.subItemsPage.plumbingequipmentItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        plumbingequipmentHot(plumbingequipmentItem) {
            dispatch(plumbingequipmentAC(plumbingequipmentItem))
        }
    }
}

export const PlumbingequsPContainer = connect(mapStateToProps, mapDispatchToProps)(PlumbingequsPage);
export default PlumbingequsPContainer;