import {
    hotelApi
} from "../api/api"

const SERVICE_RED = 'SERVICE-RED'
const FULL_SERVICE = 'FULL-SERVICE'
const SET_SERVICE = 'SET-SERVICE'

let initionState = {
    serviceItems: [],
    sService: null,
}

const servicesReduscer = (state = initionState, action) => {
    switch (action.type) {
        case SERVICE_RED: {
            return {
                ...state,
                serviceItems: [ /*...state.serviceItems,*/ ...action.serviceItems]
            }
        }
        case FULL_SERVICE: {
            return {
                ...state,
                serviceItems: [ /*...state.serviceItems,*/ ...action.serviceItems]
            }
        }
        case SET_SERVICE: {
            return {
                ...state,
                sService: action.sService
            }
        }
        default:
            return state;
    }
}
export const serviceAC = (serviceItems) => ({
    type: SERVICE_RED,
    serviceItems
})
const setService = (sService) => ({
    type: SET_SERVICE,
    sService
})

export const getService = (id) => (dispatch) => {
    hotelApi.getService(id).then(response => {
        dispatch(setService(response.data))
    })
}
export default servicesReduscer;