import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import PampsPContainer from './page/PampsPContainer'

// import card from './card.module.scss'

class Pamps extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Мотопомпы, Электро-насосы
                </h1>
                <BreadCrumbs>Мотопомпы, Электро-насосы</BreadCrumbs>
                <PampsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Pamps
