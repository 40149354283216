import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import FrasersPContainer from './page/FrasersPContainer'

class Frasers extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Рубанки, фрейзеры
                </h1>
                <BreadCrumbs>Рубанки, фрейзеры</BreadCrumbs>
                <FrasersPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Frasers
