import React from 'react'
import { connect } from 'react-redux'
import Full from './Full'
import { getScrewdrivers } from '../../../../redux/subItemsReduser'
import { withRouter } from 'react-router'



class ScrewdriversFPContainer extends React.Component {

   componentDidMount() {
      let id = this.props.match.params._id
      this.props.getScrewdrivers(id)

   }

   render() {
      return (
 <>
         <Full 
         {...this.props} 
         sScrewdrivers={this.props.sScrewdrivers}
          />
 </>
      )
   }
}
let mapStateToProps = (state) => ({
   sScrewdrivers: state.subItemsPage.sScrewdrivers
})

let WithUrlDataContainerComponent = withRouter(ScrewdriversFPContainer)

export default connect(mapStateToProps, { getScrewdrivers })(WithUrlDataContainerComponent)
