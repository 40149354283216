import React from 'react'
import OlEl from '../../elements/ol/OlEl'
import UlEl from '../../elements/ul/UlEl'

function PersonalData(props) {

    let orgName = 'Название организации'
    let adres = 'адрес'
    let orgNameMin = 'Название организации мини'
    let site = 'Название сайта'
    return (
        <div className={`p1 mA`} style={{ maxWidth: `1400px` }}>
            <h2>
                Политика оператора в отношении обработки персональных данных
            </h2>
            <OlEl>
                <li>
                    Введение
                    <br />
                    <br />
                    Настоящая политика определяет основные принципы, цели, условия и способы обработки персональных данных.
                    <br />
                    <br />
                    Положения Политики служат основой для разработки локальных нормативных актов, регламентирующих вопросы обработки персональных данных субъектов персональных данных.
                    <OlEl>
                        <li>
                            Цели
                            <br />
                            <br />
                            Настоящая Политика определяет принципы обработки персональных данных {orgName} (адрес {adres} (далее – Общество)) субъектов персональных данных.
                            <br />
                            <br />
                            Целью настоящей Политики является обеспечение защиты прав и свобод человека, и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                        </li>
                        <li>
                            Область применения
                            <br />
                            <br />
                            <OlEl>
                                <li>
                                    Настоящая Политика действует в отношении всей информации, содержащей персональные данные субъектов персональных данных, которую Общество и/или связанные с ним юридические лица могут получить о субъекте персональных данных при осуществления основной хозяйственной деятельности. Настоящая Политика является общедоступной
                                </li>
                                <li>
                                    Настоящая Политика распространяется на процессы обработки персональных данных в Обществе, осуществляемые как с использованием средств автоматизации, в том числе с использованием информационно-телекоммуникационных сетей, так и без использования таких средств.
                                </li>
                            </OlEl>
                        </li>
                        <li>
                            Ответственность
                            <br />
                            <br />
                            <OlEl>
                                <li>
                                    Целью настоящей Политики является обеспечение защиты прав и свобод человека, и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                                </li>
                                <li>
                                    Контроль за выполнением требований настоящей Политики осуществляется в процессе проведения внутренних аудитов системы менеджмента качества.
                                </li>
                            </OlEl>
                            <br />
                            <br />

                        </li>
                    </OlEl>
                    <OlEl>

                    </OlEl>
                </li>
                <li>
                    Термины, обозначения и сокращения, нормативные ссылки
                    <br />
                    <OlEl>
                        <li>
                            Нормативные ссылки

                            <br /><br />
                            Настоящий документ разработан с учетом требований:
                            <div>
                                <UlEl>
                                    <li>
                                        Конституции РФ;
                                    </li>
                                    <li>
                                        Федерального закона РФ от 27.07.2006 № 149-ФЗ «Об информации, информационных технологиях и о защите информации»
                                    </li>
                                    <li>
                                        Федерального закона РФ от 27.07.2006 № 152-ФЗ «О персональных данных»
                                    </li>
                                    <li>
                                        Постановления Правительства РФ от 01.11.2012 № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»
                                    </li>
                                    <li>
                                        Постановления Правительства РФ от 15.09.2008 № 687 «Об утверждении положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»
                                    </li>
                                    <li>
                                        Приказа ФСТЭК России от 18.02.2013 № 21 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»
                                    </li>
                                </UlEl>
                                <br />
                                В целях реализации положений политики, разработаны соответствующие локальные нормативные акты и иные документы, в том числе:
                                <br />
                                <UlEl>
                                    <li>
                                        Положение об обработке и защите персональных данных
                                    </li>
                                    <li>
                                        Инструкция по обеспечению безопасности персональных данных при их обработке
                                    </li>
                                    <li>
                                        Инструкция по обеспечению безопасности персональных данных при взаимодействии с контрагентами
                                    </li>
                                    <li>
                                        Инструкция по обеспечению безопасности персональных данных при организации пропускного режима
                                    </li>
                                    <li>
                                        Инструкция ответственного за организацию обработки персональных данных
                                    </li>
                                    <li>
                                        Инструкция ответственного за обеспечение безопасности персональных данных
                                    </li>
                                    <li>
                                        Инструкция по обработке обращений субъектов персональных данных
                                    </li>
                                    <li>
                                        Положение по обработке персональных данных по поручению третьих лиц
                                    </li>
                                </UlEl>
                            </div>

                        </li>
                        <li>
                            Термины и определения
                            <br />
                            <br />
                            <UlEl>
                                <li>
                                    информация: сведения (сообщения, данные) независимо от формы их представления
                                </li>
                                <li>
                                    персональные данные: Любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных)
                                </li>
                                <li>
                                    оператор персональных данных: Государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. В настоящей Политике под Оператором понимается {orgNameMin}, расположенное по адресу: {adres}.
                                </li>
                                <li>
                                    обработка персональных данных: Любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
                                </li>
                                <li>
                                    автоматизированная обработка персональных данных: Обработка персональных данных с помощью средств вычислительной техники.
                                </li>
                                <li>
                                    распространение персональных данных: Действия, направленные на раскрытие персональных данных неопределенному кругу лиц.
                                </li>
                                <li>
                                    предоставление персональных данных: Действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц.
                                </li>
                                <li>
                                    блокирование персональных данных: Временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).
                                </li>
                                <li>
                                    уничтожение персональных данных: Действия, в результате которых становится невозможным восстановить содержание персональных данных в информационной системе персональных данных и (или) в результате которых уничтожаются материальные носители персональных данных.
                                </li>
                                <li>
                                    обезличивание персональных данных: Действия, в результате которых становится невозможным без использования дополнительной информации определить принадлежность персональных данных конкретному субъекту персональных данных.
                                </li>
                                <li>
                                    информационная система персональных данных: Совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.
                                </li>
                                <li>
                                    трансграничная передача персональных данных: Передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому лицу или иностранному юридическому лицу.
                                </li>
                                <li>
                                    сайт(ы) Общества: Официальные сайты {orgNameMin}, включая, но не ограничиваясь следующими: <a href={`${site}`}>{site}</a>.
                                </li>
                                <li>
                                    пользователь: Любое физическое лицо, просматривающее страницы Сайта на своем компьютере и/или мобильном устройстве, в т.ч. покупатель Общества, являющийся зарегистрированным пользователем Сайта и имеющий личный кабинет на Сайте.
                                </li>
                                <li>
                                    личный кабинет: Приватная область Сайта, позволяющая зарегистрированному пользователю Сайта получать доступ к истории своих покупок и иных операций, а также хранить и изменять персональные данные, необходимые для совершения заказов.
                                </li>
                            </UlEl>
                        </li>
                    </OlEl>

                </li>
                <li>
                    <OlEl>
                        Согласие на обработку персональных данных
                        <UlEl>
                            <br />
                            Субъект персональных данных:
                            <li>
                                используя сайт Общества и заполняя веб-формы, содержащие персональные данные; и (или)
                            </li>
                            <li>
                                совершая звонок в колл-центр Общества и продолжая разговор с оператором; и/или
                            </li>
                            <li>
                                иным способом передавая свои персональные данные Обществу,
                            </li>
                            <br />
                            предоставляет Обществу согласие, на автоматизированную, а также без использования средств автоматизации обработку персональных данных, на условиях, указанных в пунктах 5-8 Политики.
                            <br />
                            Согласие на обработку персональных данных субъекта действует с даты его предоставления и до момента достижения целей обработки персональных данных, указанных в пункте 5.1 Политики. Персональные данные субъекта, хранятся не более 30 (тридцати) дней после момента достижения заявленных целей обработки, если иное не указано в Политике или не предусмотрено законодательством Российской Федерации.
                            <br />
                            Согласие полностью или частично может быть отозвано путем направления письменного запроса в адрес Общества в соответствии с пунктом 11 Политики.
                        </UlEl>
                    </OlEl>
                </li>
                <li>
                    <OlEl>
                        Права и обязанности субъектов персональных данных

                        <li>
                            <OlEl>
                                Права субъектов персональных данных
                                <li>
                                    Субъекты персональных данных имеют право (при личном обращении или при направлении письменного запроса) на получение информации, касающейся обработки их персональных данных, в том числе содержащей:
                                    <div>
                                        <UlEl>
                                            <li>подтверждение факта обработки персональных данных</li>
                                            <li>правовые основания и цели обработки персональных данных</li>
                                            <li>цели и применяемые оператором способы обработки персональных данных</li>
                                            <li>
                                                наименование и место нахождения оператора, сведения о лицах (за исключением работников оператора), которые имеют доступ к персональным данным или которым могут быть раскрыты персональные данные на основании договора с оператором или на основании федерального закона
                                            </li>
                                            <li>
                                                обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных, источник их получения, если иной порядок представления таких данных не предусмотрен федеральным законом
                                            </li>
                                            <li>
                                                сроки обработки персональных данных, в том числе сроки их хранения
                                            </li>
                                            <li>
                                                порядок осуществления субъектом персональных данных прав, предусмотренных настоящим Федеральным законом
                                            </li>
                                            <li>
                                                информацию об осуществленной или о предполагаемой трансграничной передаче данных
                                            </li>
                                            <li>
                                                наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку персональных данных по поручению оператора, если обработка поручена или будет поручена такому лицу
                                            </li>
                                        </UlEl>
                                    </div>
                                </li>
                                <li>
                                    Субъекты персональных данных вправе требовать уточнения своих персональных данных, их блокирования или уничтожения в случае, если, по мнению субъектов персональных данных, данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки.
                                </li>
                                <li>
                                    Также субъекты персональных данных могут отозвать данное ими согласие на обработку персональных данных, включая согласие на получение информации информационного и/или рекламного характера.
                                </li>
                            </OlEl>
                        </li>
                        <li>
                            Обязанности субъектов персональных данных
                            <OlEl>
                                <li>
                                    Субъекты персональных данных обязаны
                                    <div>
                                        <UlEl>
                                            <li>
                                                предоставлять достоверную и актуальную информацию о себе, в том числе актуальные контактные данные, принадлежащие субъекту персональных данных
                                            </li>
                                            <li>
                                                своевременно сообщать о необходимости внесения изменений (обновлений, уточнений) в персональные данные
                                            </li>
                                            <li>
                                                знакомиться с актуальными версиями юридических документов Общества, публикуемых на внутренних и внешних ресурсах Общества или доступных для ознакомления иным способом, в том числе настоящей Политики, Положения об обработке и защите персональных данных и иных документов Общества.
                                            </li>
                                        </UlEl>
                                    </div>
                                </li>
                            </OlEl>
                        </li>
                        <li>
                            Обязанности Оператора персональных данных
                            <div>
                                <UlEl>
                                    <li>
                                        осуществлять обработку персональных данных на законной и справедливой основе в соответствии с требованиями и условиями законодательства Российской Федерации
                                    </li>
                                    <li>
                                        обеспечивать конфиденциальность персональных данных и принимать необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных
                                    </li>
                                    <li>
                                        иные обязанности, установленные Федеральным законом РФ от 27.07.2006 г. № 152-ФЗ «О персональных данных»
                                    </li>
                                </UlEl>
                            </div>
                        </li>
                    </OlEl>
                </li>
                <li>
                    Цели и правовые основания обработки персональных данных
                    <OlEl>
                        <li>
                            <div>
                                Цели обработки персональных данных
                                <div>
                                    <UlEl>
                                        <li>
                                            Обработка персональных данных субъектов осуществляется Обществом для достижения конкретных, заранее определенных и законных целей, а именно:
                                        </li>
                                    </UlEl>
                                </div>
                                <div>
                                    <OlEl>
                                        <li>
                                            Для кандидатов, выразивших желание заключить трудовой договор с Обществом (далее – Кандидаты)
                                            <div>
                                                <UlEl>
                                                    <li>
                                                        принятие решения о предложении Кандидату должности в конкретном структурном подразделении
                                                    </li>
                                                    <li>
                                                        предоставление Кандидату информации, связанной с трудоустройством, через различные каналы связи (почта, СМС-сообщения, электронная почта, телефонный звонок)
                                                    </li>
                                                </UlEl>
                                            </div>
                                        </li>
                                        <li>
                                            Для физических лиц, заключивших трудовой договор с Обществом (далее – Работники):
                                            <div>
                                                <UlEl>
                                                    <li>
                                                        обеспечение соблюдения в отношении Работника действующего законодательства РФ в сфере трудовых и непосредственно связанных с ними отношений, в частности Трудового Кодекса РФ, ФЗ «О персональных данных» и иных нормативных правовых актов
                                                    </li>
                                                    <li>
                                                        содействие Работнику в обучении, развитии и карьерном перемещении
                                                    </li>
                                                    <li>
                                                        обеспечение личной безопасности Работника
                                                    </li>
                                                    <li>
                                                        улучшение качества труда и рабочей атмосферы в коллективе
                                                    </li>
                                                    <li>
                                                        контроль количества и качества выполняемой работы
                                                    </li>
                                                    <li>
                                                        обеспечение сохранности имущества Общества, Работников и третьих лиц
                                                    </li>
                                                    <li>
                                                        создание общедоступных источников информации, содержащих персональные данные
                                                    </li>
                                                    <li>
                                                        осуществление коммуникаций Работников
                                                    </li>
                                                    <li>
                                                        осуществление прав, обязанностей и законных интересов Работников, а также осуществления прав, обязанностей и законных интересов Общества или третьих лиц
                                                    </li>
                                                    <li>
                                                        обеспечение исполнения условий договоров с контрагентами Общества
                                                    </li>
                                                </UlEl>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                Для физических лиц, расторгнувших трудовой договор с Обществом (далее – Бывшие работники):
                                                <div>
                                                    <UlEl>
                                                        <li>
                                                            обеспечение соблюдения требований федеральных законов РФ и иных нормативных правовых актов
                                                        </li>
                                                    </UlEl>
                                                </div>
                                            </div>
                                        </li>

                                    </OlEl>
                                </div>
                            </div>
                        </li>
                        <li>
                            sad
                        </li>
                    </OlEl>
                </li>
            </OlEl>
        </div >
    )

}

export default PersonalData