import React from "react";
import { connect } from 'react-redux'
import FullService from "./FullService";
import { getService } from '../../../../redux/servicesReduser'
import { withRouter } from 'react-router'

class CContainer extends React.Component {

    componentDidMount() {

        let id = this.props.match.params._id
        this.props.getService(id)
        
    }

    render() {
        return (
            <>
                <FullService
                    {...this.props}
                    sService={this.props.sService}
                    sServices={this.sServices}
                />
            </>
        )
    }
}

let mapStateToProps = (state) => ({
    sService: state.servicesPage.sService,
})

let WithUrlDataContainerComponent = withRouter(CContainer)

export default connect(mapStateToProps, { getService })(WithUrlDataContainerComponent)
