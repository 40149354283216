
import OthersPage from './OthersPage'
import { connect } from "react-redux"
import { miscellaneousAC } from "../../../../redux/subItemsReduser"

let mapStateToProps = (state) => {

    return {
        miscellaneousItem: state.subItemsPage.miscellaneousItem,
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        miscellaneousHot(miscellaneousItem) {
            dispatch(miscellaneousAC(miscellaneousItem))
        }
    }
}

export const OthersPContainer = connect(mapStateToProps, mapDispatchToProps)(OthersPage);
export default OthersPContainer;