import { hotelApi } from "../api/api"

const BASEMENT = 'BASEMENT'
const SET_BASEMENT = 'SET-BASEMENT'


let initionState = {
    basementItems: [
        {
            "title":"что-то",
            "about":"что-то но больше",
            "imgs":[
                {
                    "id":1,
                    "pic":"картинка.jpg",
                    "svg":"картинка.svg"
                }
            ],
            "thrash":[
                {
                    "id":1,
                    "imgs":[
                        {
                            "id":1,
                            "pic":"картинка.jpg",
                            "svg":"картинка.svg"
                        }
                    ],
                }
            ] 
        }
        // {
        //     "id": "1",
        //     "soc": [
        //         {
        //         "id": "1",
        //         "socName": "Vk",
        //         "img": "https://dovilleresort.ru/local/templates/main/assets/img/ico-vk.svg",
        //         "link": "vk.com"
        //     }, 
        //     {
        //         "id": "2",
        //         "socName": "fb",
        //         "img": "https://dovilleresort.ru/local/templates/main/assets/img/ico-fb.svg",
        //         "link": "fb.com"
        //     }, 
        //     {
        //         "id": "3",
        //         "socName": "instargam",
        //         "img": "https://dovilleresort.ru/local/templates/main/assets/img/ico-instargam.svg",
        //         "link": "instargam.com"
        //     }, 
        //     {
        //         "id": "4",
        //         "socName": "youtube",
        //         "img": "https://dovilleresort.ru/local/templates/main/assets/img/ico-youtube.svg",
        //         "link": "youtube.com"
        //     }, 
        //     {
        //         "id": "5",
        //         "socName": "Tripadvisor",
        //         "img": "https://dovilleresort.ru/local/templates/main/assets/img/ico-tripadvisor.svg",
        //         "link": "Tripadvisor.com"
        //     }, 
        //     {
        //         "id": "6",
        //         "socName": "Booking",
        //         "img": "https://dovilleresort.ru/upload/main/assets/ico_booking.svg",
        //         "link": "booking.com"
        //     }, 
            
        // ],
        //     "mail": "example@mail.ru",
        //     "phone": [
        //         {
        //         "id": "1",
        //         "link": "tel:+79181234567",
        //         "text": "+7 (918) 123-45-67"
        //     }
        // ],
        //     "logHotel": [
        //         {
        //         "id": "1",
        //         "logoImg": "https://trip-advance.ru/images/wallp/log.ico",
        //         "logoName": "«КОСМОС»",
        //         "hotelName": "Демо ОТЕЛЬ «Космос»",
        //         "firstLetter": "D",
        //         "secondLetter": "O"
        //     }
        // ]
        // }
    ],
     sBasement: null,
}

const basementReducer = (state = initionState, action) => {

    switch (action.type) {
        case BASEMENT: {
            return { ...state, basementItems: [/*...state.basementItems,*/ ...action.basementItems]}
        }
        case SET_BASEMENT: {
            return {
                ...state,
                sBasement: action.sBasement
            }
        }
        default:
            return state;
    }
}

export const aboutAC = (basementItems) => ({
    type: BASEMENT,
    basementItems
})
const setBasement = (sBasement) => ({
    type: SET_BASEMENT,
    sBasement
})
export const getBasement = ()  => (dispatch) => {
    hotelApi.getBasement().then(response => {
        dispatch(setBasement(response.data))
    })
}
export default basementReducer;