import {
    hotelApi
} from "../api/api"
const BOOKING = 'BOOKING'
const SET_BOOKING = 'SET-BOOKING'

let initionState = {
    bookingId: [],
    sBooking:null,

}

const bookingReduser = (state = initionState, action) => {

    switch (action.type) {
        case BOOKING: {
            return {
                ...state,
                bookingItems: [ /*...state.bookingItems,*/ ...action.bookingItems]
            }
        }
        case SET_BOOKING: {
            return {
                ...state,
                sBooking: action.sBooking
            }
        }
        default:
            return state;
    }
}
export const bookingAC = (bookingItems) => ({
    type: BOOKING,
    bookingItems
})
const setBooking = (sBooking) => ({
    type: SET_BOOKING,
    sBooking
})
export const getBooking = () => (dispatch) => {
    hotelApi.getBooking().then(response => {
        dispatch(setBooking(response.data))
    })
}


export default bookingReduser;