import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import MetalcuttingsPContainer from './page/MetalcuttingsPContainer'

class Metalcuttings extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Металлорежущие инструменты
                </h1>
                <BreadCrumbs>Металлорежущие инструменты</BreadCrumbs>
                <MetalcuttingsPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Metalcuttings
