import React from 'react'
import BreadCrumbs from '../../elements/breadСrumbs/BreadCrumbs'
import BtnPrev from '../../elements/btnPrev/BtnPrev'
import BulgariansPContainer from './page/BulgariansPContainer'

class Bulgarians extends React.Component {

    componentDidMount() {

    }
    render() {

        return (
            <>
                <h1 className='p1'>
                Болгарки
                </h1>
                <BreadCrumbs>Болгарки</BreadCrumbs>
                <BulgariansPContainer/>
                <BtnPrev/>
            </>
        )
    }
}



export default Bulgarians
